@import '../../Theme/CommonStyles/variables.scss';

.ui.segment.Footer {
  border: none;
  padding: 50px 60px;
  @media only screen and (max-width: 1260px) and (min-width: 1000px) {
    .ui.secondary.pointing.menu .item {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 659px) {
    .ui.secondary.pointing.menu .item {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
.Footer {
  .FooterLogoContainer {
    height: 72.63px;
    width: 191px;
    margin-bottom: 10px;
    .ui.medium.image{
      width: 160px;
    }
  }
  .ui.secondary.pointing.menu.FooterMenu {
    border: none;
    .menu {
      flex-wrap: wrap;
      white-space: nowrap;
      .item {
        @media only screen and (max-width: 767px){
          width: 50%;  
        }
        
      }
    }
  }
  .ui.vertical.menu .menu .item {
    color: $black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: -0.07px;
    line-height: 17px;
  }
  .ui.secondary.pointing.menu .item:hover {
    color: $dark-pink;
    text-decoration: underline;
  }
  .ui.secondary.pointing.menu .active.item:hover,
  .ui.secondary.pointing.menu .active.item {
    color: $dark-pink;
    background-color: transparent;
    box-shadow: none;
    border: none;
    font-weight: 700;
    text-decoration: underline;
  }

.ui.icon.button.IconButton {
  box-sizing: border-box;
  color: $black;
  background-color: $white;
  border-radius: 0px;
  margin: 10px;
  padding:0 !important;
}
.SocialIcons{
  display:flex !important;
  flex-direction: row !important;
  justify-content: center;
}
}
@media only screen and (max-width: 800px) {
  .ui.segment.Footer {
    border: none;
    padding: 50px 8px;
  }
}

@media (max-width: 480px) {
  .FooterLogoContainer {
    margin: 4rem auto 0 auto;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    width: 100%;
    height: auto;
    .ui.medium.image{
      width: 200px;
      margin: 0 auto 0 auto;
    }
  }
  .ui.medium.image{
    width: 200px;
    margin: 0 auto 0 auto;
   }
  .ui.grid > .row.FooterMenuContent .right.aligned.mobile {
    text-align: left !important;
    padding: 0 3rem;
  }
  .ui.grid > .row.FooterMenuContent .ui.secondary.pointing.menu.FooterMenu .right.menu {
    display: block;
    margin: 2rem 3rem !important;
    width: 350px;
  }
  .ui.grid > .row.FooterMenuContent .right.menu a {
    font-size: 1.2rem;
  }
  .ui.grid > .row.FooterMenuContent .SocialIcons .ui.icon.button.IconButton {
    font-size: 2rem;
  }
  .SocialIcons {
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 480px) {
  .ui.segment.Footer {
    border: none;
    padding: 0;
  }
  .ui.grid > .row.FooterMenuContent {
    padding: 20px;
  }
  .ui.icon.button.IconButton{
    margin: 17px;
  }
}
@media only screen and (max-width: 390px){
  .ui.icon.button.IconButton{
    margin: 14px;
  }
}
