@import '../../../Theme/CommonStyles/variables.scss';

.ReportsSearchResults {
  padding-top: 61px;
  h4.header.Header {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 40px;
    padding-top: 15px;
  }
  .ui.item.Export {
    height: 50px;
    font-family: $font-family;
    font-weight: bold;
    width: 225px;
    padding: 15px 50px;
    border-radius: 30px;
    color: $white;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    margin-right: 18px;
    background-color: $blue !important;
    text-align: center;
    .chevron {
      margin-left: 7px;
    }
    .menu{
      margin-left: 25px;
      .item{
        width: 130px;
        height: 40px;
      }
    }
  }
  .ui.button.Export.disabled {
    opacity: 0.4 !important;
  }
  .RegularText {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.2px;
  }
  .Results {
    margin-top: 36px;
    background-color: $white;
  }
  .ResultsTableContainer {
    padding: 36px 49px 10px 49px;
    border-bottom: 1.4px solid #dddddd;
    .ui.table thead th {
      text-transform: uppercase;
      color: $gray;
      font-family: $font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.09px;
      line-height: 21px;
    }
    .ui.table td {
      color: $gray;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25.2px;
    }
  }
  .NoResult{
    padding-bottom: 26px;
    font-size: 16px;
  }
  .PaginationContainer {
    padding: 28px 49px 30px 49px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .PageText {
      color: $dark-blue;
      padding-right: 30px;
      font-weight: $font-bold;
    }
    .ui.pagination.menu {
      text-align: center;
      min-height: 30px;
    }
    .ui.pagination.menu .item:last-child {
      display: none;
    }
    .ui.menu > .item:first-child {
      display: none;
    }
    .ui.pagination.menu .active.item {
      background-color: transparent;
      color: $dark-blue;
      font-weight: bold;
      border: none;
    }
    .ui.secondary.menu .item {
      outline: none;
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0.62px;
      line-height: 21px;
      min-width: 30px;
      padding: 0;
      margin: 0;
    }
    .ui.secondary.menu .dropdown.item:hover,
    .ui.secondary.menu .link.item:hover,
    .ui.secondary.menu a.item:hover {
      background-color: transparent;
    }
  }
}
