@import "../../../../Theme/CommonStyles/variables.scss";

.BlogCardContainer {
    .BlogCard{
        width: auto;
        min-height: 480px;
        height: 500px;
        text-align: left;
        margin: 0;
        img{
            border-radius: 5px 5px 0 0;
        }
        .meta{
            color: $dim-gray;
            padding: 2.5px 14px;
            p{
                margin: 0;
                font-size: 12px;
                img{
                    display: inline;
                    margin-bottom: 5px;
                }
            }
            .label{
                width: fit-content;
                background-color: $new-gray;
                font-size: 10px;
                margin-right: 5px;
                border-radius: 8px;
            }
        }
        .content{
            border: 0 !important;
            .header{
                color: $dark-blue !important; 
                font-size: 20px !important;
            }
            .description{
                p{
                    font-size: 14px;
                    line-height: normal;
                }
            }
        }
        .Link{
            padding: 0 14px;
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
}
