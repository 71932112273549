@import '../../Theme/CommonStyles/variables.scss';

.MarketingBlocks {
  background-color: $white;
  .MarketingTextColumnResponsive {
    width: 100%;
  }
  .MarketingBlockTop{
    padding-top:50px;
    background-image: url('../../../assets/media/images/MarketingBlockTop.png');
    background-size: cover;
  }
  .MarketingBlockBottom{
    padding-top:60px;
    background-image: url('../../../assets/media/images/MarketingBlockBottom.png');
    background-size: cover;
  }
  .HealthTextColumn,
  .MarketingTextColumn {
    .HealthTextBlock,
    .MarketingTextBlock {
      width: 500px;
      text-align: center;
      margin: 20% auto auto auto;
      h2 {
        color: #1f237a !important;
        font-family: 'trade-gothic-next' !important;
        font-size: 38px !important;
        font-weight: bold !important;
        letter-spacing: 1.06px !important;
        line-height: 48px !important;
        text-align: left;
        margin-bottom: 20px !important;
      }
      p {
        color: $dark-gray !important;
        font-family: 'trade-gothic-next' !important;
        font-size: 1.25em !important;
        letter-spacing: 0 !important;
        line-height: 30px !important;
        text-align: left;
        margin-bottom: 40px;
      }
      p:empty {
        display: none;
      }
    }
    .MarketingTextBlock {
      margin: auto;
    }
  }
}

@media only screen and (max-width: 992px) {
  .MarketingBlocks {
    background-color:$bone-white !important ;
    .MarketingBlockBottom{
      background-image: none ;
    }
    .MarketingTextBlock,.HealthTextBlock{
      padding-left:0 !important ;
      h2,p{
        text-align: center !important;
      }
    }
    .MarketingTextColumn {
      .MarketingTextBlock {
        margin: 10% auto;
      }
    }
    .hideInMobileTab{
      background-color: palegreen;
      display: none !important;
    }
    .HealthTextColumn,
    .MarketingTextColumn {
      .HealthTextBlock {
        margin: 10% auto;
      }
    }
    .ImageContainer {
      > img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .MarketingBlocks {
    .MarketingTextColumn {
      .MarketingTextBlock {
        margin: 10% auto;
        width: 90%;
      }
    }
    
    .ImageContainer {
      > img {
        max-width: 100%;
      }
    }
    .HealthTextColumn,
    .MarketingTextColumn {
      .HealthTextBlock {
        width: 90%;
      }
    }
  }
}
@media only screen and (max-width: 1430px) and (min-width:992px){
  .MarketingBlockBottom{
    margin-bottom:-80px;
    }
  }

@media only screen and (min-width: 992px) {
  .MarketingBlocks {
    .HideInDesktop{
      display: none !important;
    }
  }
}
@media only screen and (max-width:480px){
  .MarketingBlocks{
    margin-top:-60px;
  }
  .MarketingBlockBottom{
    .ui.grid>.row>.column{
      padding-left: 0 !important;
      padding-right:0 !important;
    }
  }
}

