@import '../../../Theme/CommonStyles/variables.scss';

.account-failure-form__content {
  text-align: center;
  h1.header.MainHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
  }
  .page-container_pageinfo {
    p {
      line-height: 25px;
      &.mb-40 {
        margin-bottom: 40px;
      }
    }
  }
}
