@import '../../../Theme/CommonStyles/variables.scss';

.DonorData {
  color: $gray;
  font-family: $font-family;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 36px;
  padding-bottom: 26px;
  padding-left: 35px;
  background-color: $white;
  border-bottom: 1px solid #dddddd;
  .SubTitle {
    line-height: 30px;
    padding-bottom: 0px;
    font-size: 12px;
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
  h4.ui.header.DonorName {
    padding-top: 0px;
    line-height: 30px;
    font-size: 38px;
    font-weight: bold;
    color: $dark-blue;
    margin-top: 0px;
    margin-bottom: 45px;
  }
  .DataRow {
    margin-bottom: 10px;
  }
}
