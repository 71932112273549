@import '../../../Theme/CommonStyles/variables.scss';

.ui.modal.CancelAppointmentModal {
  margin-top: 40px;
  width: 703px;
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
  .HeaderContainer {
    padding: 20.5px 25px 29.5px 25px;
  }
  h2.header.ModalHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }
  .MyAppointment {
    text-align: center;
    padding-top: 30px;
    h3.header.HeaderText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-bottom: 0px;
    }
    .RegularText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-bottom: 0px;
    }
    .ModalActions {
      padding-bottom: 54px;
      padding-top: 40px;
      .ConfirmContainer {
        padding-bottom: 32px;
      }
    }
  }
  img.ui.image {
    float: right;
    margin-top: 4px;
    cursor: pointer;
  }
}
.ui.page.dimmer {
  z-index: 9999;
}
