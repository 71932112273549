@import '../../../Theme/CommonStyles/variables.scss';

.SubmitAppointment {
  padding-left: 40px;
  h4.header.SelectedAppointmentHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
  }
  .SelectedDate {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 29px;
  }
  .ButtonContainer {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 480px) {
  .SubmitAppointmentScheduleButton {
  width: inherit !important;
  }
  .SubmitAppointment {
    h4.header.SelectedAppointmentHeader {
      margin-bottom: 0px;
    }
  }
}