@import '../../../Theme/CommonStyles/variables.scss';

%TimeCellShared {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25.2px;
  padding-left: 32px;
  vertical-align: baseline;
}
.DaySchedule {
  padding-left: 6%;
  padding-right: 6%;
  font-family: $font-family;
  .ui.table {
    border: none;
    .TableHeader {
      text-transform: uppercase;
      th {
        padding: 12px 32px;
        background-color: $dark-blue;
        color: $white;
        border: 1px solid $dark-blue !important;
      }
    }
    td.no-border {
      border-top: 0px;
    }
    tr.past-slot {
      .TimeCellFuture,
      .ScheduleDayActions .AllowedAppointments,
      .CellPlayroomData .AllowedPlayroom,
      .ModifyPerson .Donor {
        color: #a0a0a0;
      }
      .ScheduleDayActions .ui.button,
      .ModifyPerson .ui.button {
        display: none;
      }
      .ModifyPerson .speOnly {
        color: #a0a0a0;
      }
    }
  }
  td.TimeCell {
    color: $gray;
    @extend %TimeCellShared;
  }
  td.TimeCellFuture {
    color: $dark-blue;
    @extend %TimeCellShared;
  }
}
.AppointmentHours {
  padding-right: 66px;
  padding-top: 30px;
  .NoResults {
    font-size: 16px;
    color: $gray;
    line-height: 20px;
    font-weight: bold;
    color: $dark-pink;
  }
} 
@media only screen and (max-width: 1280px) and (min-width: 768px) {
  .DaySchedule {
    .ui.celled.table {
      tr {
        td {
          &:first-child {
            padding: 10px 1px 10px 5px;
          }
        }
        
      }
    }
  }
}
