@import "../../../../Theme/CommonStyles/variables.scss";
@import "../../../../Theme/CommonStyles/globalStyles.scss";

$inputbox-color: #F8F8F8;

.AccessManagementUserDetails {
  /* Layout Properties */
  top: 203px;
  left: 487px;
  width: 819px;
  height: 820px;
  padding: 1.5rem;
  /* UI Properties */
  background: $white 0% 0% no-repeat padding-box;
  .HeaderPrimary {
    color: $dark-blue;
  }
  .Margin {
    &__bottom {
      &--sm {
        margin-bottom: 2rem;
      }
      &--lg {
        margin-bottom: 4rem;
      }
    }
  }
  .Float {
    &--right {
      float: right;
    }
  }
  .Input {
    &__background {
      &--color {
        background-color: $inputbox-color;
      }
    }
  }
  .Input{
    &__position{
      top: 8px;
      position: relative;
    }
  }

  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    color: $white;
    background-color: $blue;
  }

  .ui.icon.button.IconButtonMenu {
    color: $gray;
  }

  tbody tr:hover {
    background: whitesmoke;
  }
  tbody{
    background-color: white;
  }
  /* Set a fixed scrollable wrapper */
  .tableWrap {
    height: 215px;
    overflow: auto;
  }
  /* Set header to stick to the top of the container. */
  thead tr th {
    position: sticky;
    top: 0;
    background: white !important;
    z-index: 1;
  }
  tr{
    height: 60px;
  }
  .deleteaccess{
    font-size: 16px !important;
    border: none;
    background-color: white !important;
    color: $color-orange !important;
    text-decoration-line: underline;
    text-underline-offset: 3px;
  }
  .Inverted:hover {
    background-color: $blue !important;
    color: white !important;
    border: 1px solid $blue !important;
  }
}
