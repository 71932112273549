@import "../../../Theme/CommonStyles/variables.scss";
.RedeemRewardsGrid {
  padding-left: 3px !important;
  .RedeemRewardsSubtext {
    margin-top: 40px;
    font-size: 20px;
    text-align: left;
    color: $gray;
  }
  .RedeemRewardsButton {
    margin-top: 28px;
    margin-bottom: 20px;
  }
  .ButtonContainer {
    padding-top: 0 !important;
    padding-bottom: 40px !important;
  }
  .RedeemOptionContainer {
    margin-bottom: 40px;
    .RedeemOptionContainerColumn {
      text-align: left;
      padding-left: 0px !important;
      padding-right: 150px !important;
      font-size: 18px;
      color: $gray;
    }
    .RedeemOptionHeader {
      margin-top: 10px;
      color: $gray;
    }
    .RedeemOptionText p {
      margin-bottom: 0 !important;
    }
    .RedeemOptionText a {
      color: $dark-pink !important;
    }
    .SecondRedeemOptionContainerColumn {
      padding-left: 20px !important;
      padding-right: 60px !important;
    }

    .SecondImageContainer {
      padding-left: 0px !important;
    }
    .RedeemOptionImage{
      width:430px;
       height:300px;
    }
    .SecondRedeemOptionImage{
      width:450px;
      height:350px;
    }
  }
  .LastRedeemOptionContainer {
    margin-bottom: 0 !important;
  }
}
.RedeemPausedModal .ui.modal.LoyaltyModalContainer .header .ModalTitle {
  line-height: 40px;
}
.redeemPausedRow{
  padding-top: 0px !important;
}
.scheduleNow {
  margin-left: 15px;
}
.SchedulenowButton{
  margin-left: 5px;
}
@media (max-width: 480px) {
  .scheduleNow{
   width: 200px;
   margin-left: 0px;
  }
  .RedeemPausedModal .ui.modal.LoyaltyModalContainer .header .ModalTitle  {
    line-height: 25px;
  }
}
.RedeemPausedModal .ui.modal.LoyaltyModalContainer .header .ModalTitle{
  line-height: 40px;
}
// modal styles start here

.ui.modal.Redeem-points-modal {
  border-radius: 30px;
  >:first-child:not(.icon){
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
  }
  >:last-child {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
  }

  .closebtnRow {
      padding: 0;
  }
  .ui.one.column.grid .closebtnRow {
      justify-content: end;
      margin-right: 20px;
      margin-top: 10px;
  }
  .ModalHeader {
      &.mobile.column {
          padding-top: 0;
      }
      .ui.header {
          font-size: 50px;
          color: #484848;
      }
  }

  .column.available-point-container {
      padding-right: 60px !important;
      padding-top: 0px !important;
      text-align: right;
  }
  .availabel-points-txt {
      font-size: 15px;
      color: #484848;
      margin-bottom: 12px;
  }
  .availabel-points-count {
      font-size: 50px;
      color: $loyaltyDarkGreen;
  }
  .header.redeemHeaderContainer {
      padding-left: 40px;
      border-bottom: 5px solid $loyaltyLightGreen;
      .closebtnRow {
          padding-bottom: 0;
      }
  }

  .Redeem-points-modal-content {
      padding: 25px;
      font-size: 18px;
      color: #484848;
      font-family: "trade-gothic-next";
      .redemption-options-container {
          margin-top: 10px;
      }
      .redeem-intro-txt {
          padding: 10px 0px;
      }
      .radio-btn-group {
          display: flex;
          color: #484848;
      }
      .redeem-text {
          font-size: 20px;
          font-weight: 600;
      }
      .radio-input {
          accent-color: $loyaltyDarkGreen;
          -ms-transform: scale(1.5); /* IE 9  - for increasing the size of radio btn*/
          -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
          transform: scale(1.5);
          display: flex;
          align-self: flex-start;
          margin-top: 2%;
      }
      .redeem-description {
          font-size: 16px;
      }
      .redeem-text-container {
          padding: 10px 20px;
          width: 100%;
      }
      .vender-redeem-off-error{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: -15px 0 5px 0;
        color: $dark-pink;
        .InfoIcon{
          margin: 2px 10px 0 0;
        }
      }

      // redeem points selector styles
      .redeem-points-selector-container {
          .no-of-ponts-txt {
              font-size: 20px;
              color: #484848;

          }
          .redeem-btn-container{
              display: inline-block;
              margin-top: 40px;
          }
          .redeem-control-btn {
              font-size: 40px;
              font-weight: 900;
              color: #ababab;
              width: 50px;
              height: 50px;
              border: 2px solid #ababab;
              padding: 15px 6px;
              line-height: 0;
          }
          .redeem-pnt-input-container{
              display: inline-block;
              margin:20px

          }
          .redeem-pnts-input{

              border: none;
              border-bottom: 1px solid #222222;

              &>input[type=number] {
                  border: none;
                  color: $loyaltyDarkGreen;
                  -webkit-text-fill-color: $loyaltyDarkGreen; //For safari
                  font-size: 50px;
                  padding: 0;
                  width: 300px;
                  opacity: 1;
              }
              &.ui.disabled.input {
                opacity: 1;
             }
              input[type=number]::-webkit-inner-spin-button, 
              input[type=number]::-webkit-outer-spin-button { 
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  margin: 0; 
              }
          }
          .available-pnts{
              font-size: 16px;
              color: #6d6d6d;
          }
      }

      // cash value container style
      .points-selector-container {
          padding-top: 15px;

          .your-cash-value-container {
              text-align: center;
              padding: 20px 10px;
              background-color: $loyaltyLightGreen;
              border-radius: 30px;
          }
          .your-cash-value-text {
              color: #484848;
              font-size: 20px;
          }
          .cash {
              font-size: 50px;
              color: #484848;
          }
      }
      .submit-btn-container{
          padding-top: 20px;
      }
      .reedeem-processing-txt{
        color: #6d6d6d;
        font-size: 16px;
        font-weight: 600;
        padding-top: 12px;
      }
  }

  //  media queries
  @media (max-width: 540px) {
    .Redeem-points-modal-content .radio-input {
      margin-top: 5%;
    }
    .Redeem-points-modal-content .redeem-points-selector-container{
      .redeem-pnts-input>input[type=number] {
        width: 280px;
      }
    } 

  } 
  @media (max-width: 480px) {
    .ui.modal.Redeem-points-modal{
      margin-top: 0px;
    }
      .header.redeemHeaderContainer {
          padding-left: 20px;
          .ui.image.close-btn{
            margin-top: 20px;
            margin-right: 10px;
            z-index: 20;
          }
      }
      .ModalHeader{ 
        margin-top: -20px;
        .ui.header {
          font-size: 30px;
      }
    }
      // redeem points selector styles
      .Redeem-points-modal-content {
        .radio-input {
          margin-top: 8%;
        } 
      .redeem-points-selector-container {
        .redeem-btn-container{
          z-index: 20;
        }
        .left-btn{
          margin-top: 120px;
          margin-right: -20px;
        }
        .redeem-pnt-input-container{
          margin-left: -30px;
        }
        .redeem-pnts-input>input[type=number] {
          width: 250px;
        }
        .right-btn{
          margin-top: 120px;
          margin-left:-80px;
        }
      }
    }
      .mobile.column.available-point-container {
          text-align: left;
          padding-top: 0;
      }
      .submit-btn-container{
        text-align: center;
        .ui.button.PrimaryButton.Primary.loading{
          background-color: #484848;
        }
      }
  }

  @media (max-width: 400px) {
    .Redeem-points-modal-content .redeem-points-selector-container{
      .redeem-pnts-input>input[type=number] {
        width: 250px;
      }
    } 
    .Redeem-points-modal-content .radio-input {
      margin-top: 7%;
    }
  }
}

.ui.modal.LoyaltyModalContainer{
.ModalContent .navigateWaysToEarn {
  text-decoration: underline;
  cursor: pointer;
  }
}
// modal style end here

@media (min-width: 768px) and (max-width:1450px){
  .RedeemRewardsGrid {
    .RedeemOptionContainer{
  .RedeemOptionContainerColumn {
    padding-right: 15px !important;
  }
  .SecondRedeemOptionContainerColumn {
    padding-left: 20px !important;
    padding-right: 0px !important;
  }
  .RedeemOptionImage{
    width:380px;
     height:270px;
  }
  .SecondImageContainer{
    padding-top:20px;
  .SecondRedeemOptionImage{
    width:400px;
    height:350px;
  }
}
}
  }

}

@media (max-width: 480px) {
  .RedeemRewardsGrid {
    text-align: center;
    .RedeemRewardsSubtext {
      margin-top: 0 !important;
      flex-direction: column-reverse !important;
      text-align: center !important;
      align-items: center !important;
      .RedeemRewardsButton {
        margin-top: 5px;
        margin-bottom: 40px;
      }
    }
    .RedeemOptionContainer {
      flex-direction: column-reverse !important;
      .RedeemOptionContainerColumn {
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center;
        padding-bottom: 10px !important;
      }
      .RedeemOptionHeader {
        margin-top: 18px;
        margin-bottom: 18px;
      }
    }
    .SecondContainer {
      flex-direction: column !important;
    }

    .ButtonContainer {
      flex-direction: column !important;
      align-items: center !important;
    }
    .RedeemOptionImage{
      height:210px;
    }
    .SecondRedeemOptionImage{
      height:250px;
    }
  }
  .ui.modal.LoyaltyModalContainer .content .ModalContent{
    text-align: left !important;
    padding-top: 0 !important;
    padding-left: 0px !important;
  }
}
