@import '../../../Theme/CommonStyles/variables.scss';

.ModifyPerson {
  
  .Donor {
    color: $gray;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.2px;
    // margin-bottom: 16px;
    padding-left: 23px;
  }
  .HideDonor{
    display: none;
  }
  .ui.button.ModifyButton {
    background-color: $dark-pink !important;
    padding: 0px;
    line-height: 14px !important;
  }
  .ui.button.HideModifyButton {
    display: none;
  }
  .QuickPE{
    color: $blue;
    font-weight: bold;
  }
  .speOnly {
    font-weight: bold;
    color:$blue;
  }
}
.ui.image.spanish-logo{
  display: inline-block ;
}

@media only screen and (max-width: 1330px) and (min-width: 1280px) {
  .ModifyPerson {
    margin-top: 20px;
    min-height: 45px;
  }
}
