
.ImageContainer {
  position: relative;
}
.IconGridContainerTop {
  position: absolute;
  top: -32px;
  left: -32px;
}
.IconGridContainerBottomTop {
  position: absolute;
  bottom: -23px;
  right: 6px;
  width: 60px;
}
.IconGridContainerBottomRight{
  position: absolute;
  bottom: -23px;
  width: 60px;
  right: -30px;
}
@media only screen and (max-width: 800px) {
  .IconGridContainerTopRight{
    bottom: -25px;
    width: 90px;
  }
  .IconGridContainerBottomTop{
    width: 60px;
  }
  .IconGridContainerTop{
    bottom: 0;
    width: 90px;
    left: -25px;
  }
}
@media only screen and (max-width: 640px) {
  .MarketingBlocks {
    .MarketingTextColumn {
      padding: 0 !important;
    }
    .MarketingTextBlock {
      width: 370px;
      margin: 18% auto;
    }
  }
  .IconGridContainerTop {
    position: absolute;
    top: -46px;
    left: 0px;
  }
  .IconGridContainerBottomTop {
    right: 0;
  }
  .IconGridContainerBottomRight{
    bottom: -30px;
    right: 0px;
  }
}
