@import '../../../Theme/CommonStyles/variables.scss';

.MessagesSection {
  margin-top: 20px;
  padding: 35px 35px 54px;
  font-family: $font-family;
  background-color: $white;
  margin-bottom: 50px;
  h4.ui.MessagesHeader {
    color: $dark-blue;
    font-size: 24px;
  }

  .MessageText {
    color: $gray;
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .ui.container.MessagesSection {
    padding-left: 0;
    margin-left: 0 !important;
  }
}
