@import '../../../../Theme/CommonStyles/variables.scss';

.AccessManagementSearch
{
    top: 203px;
    left: 91px;
    width: 377px;
    height: 100%;
    /* UI Properties */
    background-color: white;
    opacity: 1;

    .input{
        /* Layout Properties */
        top: 10px;
        left: 8px;
        width: 97%;
        height: 50px;
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
    }
    .item{
        left: 8px;
    }
    .active{
        color: #1F237A;
    }
    .searchContent{
        width: 377px;
        height: 766px;
        /* UI Properties */
        left: 60px;
        letter-spacing: 1px;
        color: #1F237A;
        opacity: 1;
        position: inherit;
        top: 120px;
    }
    .ui.segment{
        padding: 0px;
        border: none;
    }
    .ui.text.menu{
        border-bottom: 1px solid #DDDDDD;
        margin: 1em 0.5em;
    }
    .center{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50px;
        margin-top: 50px;
        left: 110px;
        display: block;
        position: absolute;
    }

    .userLists{
        /* Layout Properties */
        left: 5px;
        height: 71px;
        /* UI Properties */
        background: white 0% 0% no-repeat padding-box;
        opacity: 1;
        border-bottom: 1px #DDDDDD dotted;
        position: relative;
    }
    .userLists:hover{
        background-color: whitesmoke;
      }
    .userListsSelected{
        /* Layout Properties */
        left: 5px;
        height: 71px;
        /* UI Properties */
        background:  #EDF7F6 0% 0% no-repeat padding-box;
        opacity: 1;
        border-bottom: 1px #DDDDDD dotted;
        position: relative;
    }
    .userShortName{
        /* Layout Properties */
        top: 15px;
        left: 10px;
        width: 40px;
        height: 40px;
        /* UI Properties */
        background-color: #1F237A;
        opacity: 1;
        border-radius: 50%;
        color: white;
        text-align: center;
        position: relative;
    }
    .userShortNameSpan{
        position: relative;
        top: 10px;
    }
    .userShortName :hover{
        background-color: #1F237A !important;
      }

    .userName{
        /* Layout Properties */
        top: 321px;
        left: 177px;
        width: 200px;
        height: 22px;
        /* UI Properties */
        text-align: left;
        font: normal normal bold 16px/25px Trade Gothic Next LT Pro;
        letter-spacing: 0px;
        color: $dark-pink;
        opacity: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .userEmail{
        /* Layout Properties */
        top: 345px;
        left: 177px;
        width: 227px;
        height: 20px;
        /* UI Properties */
        text-align: left;
        letter-spacing: 0px;
        color: #484848;
        opacity: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .userlistContent{
        position: relative;
        left: 60px;
        top: -25px;
    }
    .ui.segment.active.tab{
        overflow-y: scroll;
        max-height: 700px;
        overflow-x: hidden;
    }
    .ui.segment.active.tab:last-child {
        border: none;
      }
    .ui.icon.button{
        background-color: white;
        border: 1px solid rgba(0,36,38,.15);
        border-left: none;
    }
    .defaultUser{
        position: inherit;
    }
    .searchErrorText {
        color: $color-validation-red;
        height: 11px;
        text-align: left;
        font: normal normal 600 10px Barlow;
        letter-spacing: 0.5px;
        opacity: 1;
        position: absolute;
        top: 3px;
    }
    .searchError{
        top: 10px;
        position: relative;
        left: 10px;
    }
}