.userDefatulDetails{
    top: 400px;
    left: 400px;
    width: 212px;
    height: 18px;
    text-align: left;
    letter-spacing: 1px;
    color: #1F237A;
    opacity: 1;
    position: relative;
    .center{
      left: 75px;
      position: relative;
    }
  }
  .active.dimmer{
    z-index: 0;
  }