@import '../../../Theme/CommonStyles/variables.scss';

.ScheduleAppointment {
    margin-top: 10px !important;
    font-family: $font-family;
    margin-bottom: 600px !important;
    margin-left: 6% !important;

    h4.ui.header {
        font-size: 38px; 
        color: #1F237A;
        font-weight: bold;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .Content {
        margin-top: 40px;
    }

    .TestingPurposesButton {
        margin-top: 200px;
    }

    .ui.fluid.dropdown {
        width: 33% !important;
        border: none !important;
        background: inherit !important;
        font-size: 20px;
        font-weight: bold;
        line-height: 34px;
    }

    .NoCentersContent {
        color: #1F237A;
        font-weight: bold;
        font-size: 20px;
        line-height: 34px;
    }

    .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
        color:#1F237A !important;
    }

    .ui.active.selection.dropdown>.dropdown.icon, .ui.visible.selection.dropdown>.dropdown.icon {
        color:#1F237A !important;
    }

    .ui.dropdown>.dropdown.icon:before {
        color:#1F237A !important;
    }

    .ui.search.dropdown>.text {
        color:#1F237A !important;
    }
}