@import '../../../Theme/CommonStyles/variables.scss';

.SearchResultsCalendar {
  h4.header.ResultsHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .TableContainer {
    padding: 20px 30px 27px 30px;
  }
  .ui.table {
    border-bottom: 1.4px solid #dddddd;
  }
  .ui.table thead th {
    text-transform: uppercase;
    color: $gray;
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.09px;
    line-height: 21px;
  }
  .ui.table td {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.2px;
  }
  .ui.table td.FirstNameCell {
    color: $dark-blue;
    font-weight: bold;
  }
  i.icon {
    color: $dark-pink;
  }
  .PlayRoomContainer {
    padding: 24px 30px 26px 30px;
    border-bottom: 1px solid #d4d4d4;
  }
  .ui.radio.checkbox {
    margin-top: 4px;
    margin-right: 50px;
  }

  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    box-sizing: border-box;
    height: 26px;
    width: 26px;
    border: 2px solid $blue;
    background: $white;
  }

  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    background-color: $white;
    border: 7px solid $blue;
  }
  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    opacity: 0;
  }
  .ui.radio.checkbox .box,
  .ui.radio.checkbox label {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.2px;
  }
  .ResultsActions {
    padding: 23px 30px 26px 30px;
  }
}
