@import '../../Theme/CommonStyles/variables.scss';
%Header-shared {
  color: $gray;
  font-family: $font-family;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -0.24px;
  line-height: 74px;
  text-align: center;
}
.EmailCofirmationContainer {
  background-color: $new-gray;
  .MainContent {
    padding-top: 116px;
    padding-bottom: 146px;
    h2.header.MainContentHeader {
      @extend %Header-shared;
      padding-bottom: 58px;
    }
    .SectionContainer {
      width: 573px;
      margin: auto;
      p {
        color: $gray;
        font-family: $font-family;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }
    .SecondaryHeader {
      @extend %Header-shared;
      padding-bottom: 44px;
      padding-top: 52px;
    }
    @media only screen and (max-width: 800px) {
    }
    @media only screen and (max-width: 480px) {
      .SectionContainer {
        width: auto;
      }
      h2.header.MainContentHeader {
        font-size: 30px;
        line-height: 40px;
      }
      .SecondaryHeader {
        font-size: 25px;
        line-height: 35px;
      }
    }
  }
}
