@import '../../Theme/CommonStyles/variables.scss';

.BiolifeMobileAppContainer{
    transform: translate(0, -35px);
    text-align: center;

    .PromotionHeaderImage{
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 700px;

          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
    }

    .ImageContainer{
        width: 700px;
        .column.grid{
            img{
                margin: auto;

                @media only screen and (min-width: 481px) and (max-width: 767px) {
                  margin-left: 110px;
                }
            }
        }
        .TextContainer{
            width: 275px;
            text-align: left;
            .PrimaryText{
                color: $dark-blue;
            }
            .SecondaryText{ 
                color: #4A4A4A
            }
        }
    }
    .DownloadAppContainer{
        width: 350px;
        margin: auto;
        padding: 25px 0;
        .DownloadAppText{
            margin: 0;
            padding: 10px 0;
            width: 100%;
            border-radius: 15px;
            text-align: center;
            color: $dark-blue
        }
        .DownloadAppImage{
            padding: 0;
            .column{
                padding: 0;
            }
            img{
                height: 50px;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .BiolifeMobileAppContainer{
        .ImageContainer{
            width: 100%;
            .TextContainer{
                .PrimaryText{
                    line-height: 24px;
                    font-size: 20px;
                }
                .SecondaryText{
                    line-height: 20px;
                    font-size: 16px;
                }
            }
        }
        .DownloadAppContainer{
            width: auto;
        }
    }
}