@import '../../../Theme/CommonStyles/variables.scss';

.CenterSelection {
  padding-top: 30px;
  max-width: inherit;
  margin: auto;
    .CenterChoosenIcon {
     filter: invert(50%) sepia(76%) saturate(5407%) hue-rotate(1deg) brightness(100%) contrast(96%);
     padding-right: 0px !important;
     padding-top: 17px !important;
     padding-bottom: 0px !important;
  }
  .ChooseCenter {
    color: $gray;
    font-family: $font-family;
    font-size: 23px;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0;
    text-align: left;
    background-color: transparent;
    font-weight: normal;
    padding: 0;
  }
  .SelectedCenter {
    color: $green;
    font-weight: bold;
  }
  .Notification {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: left;
  }
  .ui.inline.image{
    height: 26px;
  }
  @media only screen and (max-width: 620px) {
    width: auto;
    .SelectSearch {
      height: auto;
      margin-left: 0;
      padding: 0;
      margin-bottom: 10px;
      width: inherit;
    }
  }
  @media only screen and (max-width: 480px) {
    width: auto;
    .ChooseCenter {
      font-size: 16px;
      line-height: 16px;
    }
    .Notification {
      font-size: 14px;
    }
    .SelectSearch .ui.dropdown.Base {
      font-size: 16px;
      line-height: 22px;
    }
    .SelectSearch .ui.dropdown.Base i.icon {
      line-height: 22px;
    }
  }
  @media only screen and (min-width: 993px){
    .ui.inline.image{
      padding-top: 3px;
    }
  }
}
