@import "../../../../Theme/CommonStyles/variables.scss";

.MyRewardsContainer{
    margin: 40px 0;
    .MyAchievementsCard{
        border-radius: 15px;
        padding: 25px 20px 5px 20px;
        .achievementsCtaMobile{
            display: none;
        }
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            h2{
                color: $loyaltyDarkGreen;
                margin: 0;
            }
            .achievementsCtaWeb{
                color: $dark-pink;
                text-decoration: underline;
                word-spacing: -1px;
                font-weight: 700;
                cursor: pointer;
            }
        }
        .meta{
            color: $gray;
            padding-bottom: 20px;
            border-bottom: 1px solid $disable-gray;
        }
        .SliderContainer{
            padding: 25px;
            .slick-dots li{
                margin: 0;
            }
            .slick-list{
                padding: 10px 0;
            }
            .slick-arrow{
                &::before{
                    color: $dark-blue;
                    border-radius: 5px;
                }
            }
            .badgeContainer{
                text-align: center;
                .badgeCheck{
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    z-index: 1;
                    margin-left: 7.7%;
                }
                .badgeImage{
                    margin: auto;
                }
                .badgeName{
                    font-weight: 500;
                    color: $gray;
                    margin-top: 10px;
                }
            }
        }
    }
    
}

.ui.modal.LoyaltyModalContainer{
    .ModalDescription{
        .badgeDetailsContainer{
            padding: 20px 10px;
            .badgeTitle{
                color: $loyaltyDarkGreen;
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }
}
.sections{
display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
}

.OptOutLink{
    color: $dim-gray ;
    font-size: 18px;
    text-decoration: underline;
}

@media only screen and (max-width: 480px) {
    .ui.modal.LoyaltyModalContainer{
        .ModalDescription{
            .badgeDetailsContainer{
                img{
                    margin: auto;
                }
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .MyRewardsContainer{
        .MyAchievementsCard{
            .achievementsCtaMobile{
                display: block;
                text-align: center;
                color: $dark-pink;
                text-decoration: underline;
                word-spacing: -1px;
                font-weight: 700;
                padding: 20px 0;
            }
            .header{
                h2{
                    margin: auto;
                }
                .achievementsCtaWeb{
                    display: none;
                }
            }
        }
    }
    .sections{
      display: block;
          text-align: center;
          gap: 30px;
      }
      .TestimonialsSection{
        text-align:center;
        .OptOutLink{
            font-size: 15px;
        }
      }
    
}

@media only screen and (max-width: 380px) {
    .MyRewardsContainer{
        .MyAchievementsCard{
            .SliderContainer{
                .slick-dots{
                    bottom: -35px;
                }
            }
        }
    }
}