@import '../../../Theme/CommonStyles/variables.scss';

.AccountSetting {
  h1.header.BigSuccessMessage {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 66px;
    margin-top: 15px;
  }
}
