@import '../../../Theme/CommonStyles/variables.scss';

.PaymentCards {
  font-family: $font-family;
  margin-bottom: 30px !important;

  .HeaderText {
    font-size: 24px !important;
    line-height: 30px;
    color: $dark-blue;
    padding-bottom: 20px;
    margin-left: 30px !important;
    padding-top: 10px;
  }

  .HeaderBar {
    justify-content: space-between !important;
  }

  .PaymentCardsColumn {
    display: flex;
    flex-direction: column;
    flex: 1.5;
    margin-right: 20px;
    margin-Left: 30px;
  }



  .PaymentCardsContent {
    margin-top: 0px;
    display: flex;
  }

}