@import '../../../Theme/CommonStyles/variables.scss';

.ui.modal.QuickPhysicalModal {
  margin-top: 40px;
  width: 998px;
  .HeaderContainer {
    padding: 20.5px 25px 29.5px 25px;
  }
  .content {
    padding: 0;
  }
  h2.header.ModalHeader, h3.header.ModalHeader  {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }
  h2.header.ModalHeaderSuccess {
    color: #1F237A;
    line-height: 40px;
    text-align: center;
    margin-top: 18px;
  }
  .RegularText {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 0px;
  }
  .SelectedDate {
    padding-bottom: 48px;
  }
  .HeaderText {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 0px;
  }
  .MyAppointment {
    .SelectedTimeHeader {
      background-color: $dark-blue;
      padding: 27px 30px;
      font-family: $font-family;
      color: $white;
      h1.header.HeaderText {
        color: $white;
        font-size: 24px;
        text-align: left;
        span {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1.09px;
          line-height: 21px;
          text-transform: uppercase;
          margin-left: 27px;
        }
      }
    }
    .ModalActions {
      padding: 41px 6%;
      .ConfirmContainer {
        padding-bottom: 32px;
        padding-top: 32px;
      }
    }
    .CancelAppointment {
      background-color: $color-orange !important;
    }
  }
  img.ui.image {
    float: right;
    margin-top: 4px;
    cursor: pointer;
  }
  .HorizontalCalendar .CalendarSlider {
    padding-left: 0px;
    padding-right: 0px;
  }
  .CenterSelection {
    width: 938px;
  }
  .HoursContainer {
    width: 850px;
    margin: auto;
  }
  .Playroom {
    padding-top: 4.51px;
    padding-bottom: 31.34px;
    text-align: center;
  }
  .PlayroomContainer {
    width: 500px;
    margin: auto;
  }
  .LabelForSelect {
    color: $dark-gray;
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 3px;
  }
  .HugeSelect {
    .ui.selection.dropdown.Base {
      margin: auto;
    }
  }
}
.ui.page.dimmer {
  z-index: 9999;
}
