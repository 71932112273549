@import '../../Theme/CommonStyles/variables.scss';

.EmbeddedVideos {
    font-family: $font-family;
    padding-top: 50px;
    padding-bottom: 50px;

    .Video {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    h4.ui.header {
        font-size: 24px; 
        color: #1F237A;
        line-height: 30px;
        font-family: $font-family;
    }
}