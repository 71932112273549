@import '../../../Theme/CommonStyles/variables.scss';

.ScheduleDayActions {
  text-align: center;
  .AllowedAppointments {
    color: $gray;
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.09px;
    line-height: 21px;
    text-transform: uppercase;
  }
  .hideAppointment{
    display: none;
  }
  .ui.button.AddButton {
    background-color: $green !important;
    line-height: 1em !important;
    padding: 0px;
  }
  .ui.button.AddButtonYellow {
    background-color: $yellow !important;
    line-height: 1em !important;
    padding: 0px;
  }
  .ui.button.AddButtonOverbook {
    background-color: $dark-pink !important;
    line-height: 1em !important;
    padding: 0px;
  }
  .ui.button.HideAddButton {
    display: none;
  }
}
