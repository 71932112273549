@import "../../../Theme/CommonStyles/variables.scss";
.MyLoyaltyTabs,
.SpanishMyLoyaltyTabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  width: fit-content;
  border-bottom: 1px solid #dddddd;
  padding-top: 0;
  list-style: none;
  padding-left: 0;
  .tabs,
  .spanishTabs {
    height: 100%;
    color: #ababab;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    margin-right: 40px;
    cursor: pointer;
  }
  .active {
    border-bottom: 6px solid $dark-pink;
    color: #484848;
  }
  .last {
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .SpanishMyLoyaltyTabs {
    height: 70px;
    width: 100%;
  }
  .spanishTabs {
    font-size: 16px !important;
    max-width: 90px;
    margin-left: 12px;
    margin-right: 12px !important;
    text-align: center;
  }

  .MyLoyaltyTabs {
    height: 70px;
    .tabs {
      height: 70px;
      margin-left: 22px;
      margin-right: 22px;
      text-align: center;
    }
    .last {
      margin-right: 22px !important;
    }
    .second {
      margin-left: 16px;
      margin-right: 12px;
      max-width: 66px;
    }
  }
}
@media (max-width: 400px) {
  .spanishTabs {
    font-size: 15px !important;
    max-width: 90px;
    margin-left: 9px;
    margin-right: 9px !important;
    text-align: center;
  }
  .MyLoyaltyTabs {
    .tabs {
      margin-left: 18px;
      margin-right: 18px;
      max-width: 85px;
    }
    .first {
      margin-left: 10px !important;
    }
    .last {
      margin-right: 10px !important;
    }
    .second {
      margin-left: 12px;
      margin-right: 9px;
      max-width: 70px;
    }
  }
}
