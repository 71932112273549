@import '../../../Theme/CommonStyles/variables.scss';

.CenterOpsDonorInformation {
  h4.ui.header.SectionHeader {
    font-size: 24px;
    line-height: 40px;
    color: $dark-blue;
    padding-bottom: 20px;
    margin: 0;
  }
}
