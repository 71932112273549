@import '../../../Theme/CommonStyles/variables.scss';

.MessagesColumn {
  background-color: #fff;
  margin-top: 30px;
  .Messages {
    padding: 30px;
    h4.ui.header {
      font-size: 24px;
      line-height: 30px;
      color: #1f237a;
      padding-bottom: 10px;
    }
    .MessagesText {
      font-family: $font-family;
      font-size: 20px;
      color: #484848;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 30px;
    }
    br {
      display: none;
    }
  }
}
