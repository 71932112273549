@import "../../Theme/CommonStyles/variables.scss";

.card {
  padding-top: 40px;
  padding-right: 15px;
}
.LoyaltyCard {
  font-family: $font-family;
  padding: 20px;
  border-radius: 16px;
}

h2.ui.header.LoyaltyHeader {
  font-size: 34px;
  color: $loyaltyDarkGreen;
  font-family: $font-family;
}
.LoyaltyText {
  padding-right: 20px;
  line-height: 30px;
  font-size: 16px;
}
@media only screen and (max-width: 480px) {
    .card{
        padding-left: 0px;
        width: 325px;
    }
    h2.ui.header.LoyaltyHeader {
      font-size: 27px;
      padding-right: 10px;
    }
    .LoyaltyCard {
      padding-left: 15px;
    }
}