@import '../../Theme/CommonStyles/variables.scss';

.ui.message.Message {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 50px;
  border-radius: 0;
  color: $white;
}

.ui.message.AlertMessage {
  background-color: $yellow;
  & .ui.header.MessageHeader,.MessageText,.MessageLink,.MessageLink:hover{
    color: $color-rich-black;
  }
  & .MainContentLeftBorder {
    border-color: $color-rich-black;
  }
  & .ui.image.MessageIcon,.ui.image.CloseContainer {
    filter: invert(100%);
  }
}
.ui.message.PromoMessage {
  background-color: $dark-pink;
}
.ui.message.AnnounceMessage {
  background-color: $color-light-blue;
  & .ui.header.MessageHeader,.MessageText,.MessageLink,.MessageLink:hover{
    color: $color-rich-black;
  }
  & .MainContentLeftBorder {
    border-color: $color-rich-black;
  }
  & .ui.image.MessageIcon,.ui.image.CloseContainer {
    filter: invert(100%);
  }
}
i.icon.MessageIcon {
  opacity: 0.7;
}
.ui.header.MessageHeader {
  color: $white;
  font-family: $font-family;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.MessageText {
  color: $white;
  font-family: $font-family;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  max-width: 80%;
  padding-bottom: 15px;
}
.MessageLink {
  color: $white;
  font-family: $font-family;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;

  // &::after {
  //   content: '';
  //   flex: 1 0;
  //   border-bottom: 2px solid $white;
  //   margin: 10px;
  //   margin-left: auto 10px;
  //   max-width: 17px;
  // }
}
.MessageLink:hover {
  color: $white;
}
.MessageIconContainer {
  margin: 0 auto;
  width: 60px;
}
.CloseContainer {
  width: 20px;
  cursor: pointer;
}
.MainContentLeftBorder {
  border-left: 1px solid $white;
}

@media only screen and (max-width: 415px) {
  .ui.message.Message {
    padding: 25px 0;
  }
  .ui.message.Message.PromoMessage {
    padding-bottom: 50px;
  }
  .MessageIconContainer {
    width: 40px;
    .MessageIcon {
      height: 40px;
      width: 40px;
    }
  }
  .MessageText {
    max-width: 100%;
  }
}
.smartbanner {
  left:0;top:0;
  width:100%;
  height:78px;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  background:#fff;
  overflow:hidden;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0px;
  -webkit-font-smoothing:antialiased;
  -webkit-text-size-adjust:none;
}
.smartbanner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.smartbanner-close {
  display:block;
  font-family:'ArialRoundedMTBold',Arial;
  font-size:15px;
  text-align:center;
  text-decoration:none;
  // border-radius:14px;
  -webkit-font-smoothing:subpixel-antialiased;
  border:0;
  width:17px;
  height:17px;
  line-height:17px;
  color:#b1b1b3;
  // background:#efefef;
}
.smartbanner-close:active,.smartbanner-close:hover {
  color:#333;
}
.smartbanner-icon{
  display:block;
  width:57px;
  height:57px;
  // background-color: #fff;
  background-size:cover;
  background-image: url("../../../assets/media/brand/bannerIcon.png");
  border: 0;
  border-radius: 7px;
}
.smartbanner-info{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 275px;
  .smartbanner-title{
    font-size:12px;
    line-height:1.3em;
    color:#999;
    p{
      font-size:15px;
      line-height:20px;
      color:#000;
      font-weight:medium;
      margin: 0;
    }
  }
}
.smartbanner-button{
  // border-bottom:3px solid #b3c833;
  padding:0 10px;
  min-width:12%;
  height:24px;
  font-size:20px;
  line-height:24px;
  text-align:center;
  // font-weight:bold;
  color: $blue;
  // background-color:#b3c833;
  text-decoration:none;
  border-radius:5px;
}
.smartbanner-button-text {
  padding:0 5px;
}

@media only screen and (min-width: 1280px) and (max-width: 1330px) {
  .alertContent{
    padding-left: 20px !important;
  }
  .MainContentLeftBorder{
    padding: 15px 0;
  }
}