@import '../../../Theme/CommonStyles/variables.scss';

.AppointmentTabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #dddddd;
  padding-top: 0;
  margin-bottom: 25px;
  list-style: none;
  padding-left: 0;
  padding-top: 15px;

  .tabs {
    height: 100%;
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    margin-right: 40px;
    cursor: pointer;
  }

  .save {
    background-color: $blue !important;
  }
  .save.es-MX {
    width: 200px !important;
  }

  .active {
    border-bottom: 4px solid $color-orange
  }
}
