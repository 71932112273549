@import '../../Theme/CommonStyles/variables.scss';

.sideMenu-Container{
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-top: 7px;

.details-container{
  text-overflow: ellipsis;
  padding-left: 5px;
}
.user-name{
  font-size: 20px;
  height: 21px;
  font-weight: 600;
  text-transform: capitalize;
  color: rgb(31, 35, 122);
  width: 57vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
}
.center-name{
  font-size: 16px;
  text-decoration: none;
  color: #484848;
  padding-top: 2px;
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
}
.user.circular.user-icon{
  color: rgb(31, 35, 122);
  margin:4px 4px 4px 4px;
}
}