@import '../../../../Theme/CommonStyles/variables.scss';

.AccessManagementHeader{
    .ui.header.FormHeader {
        color: $dark-blue;
        font-family: $font-family;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        top: 140px;
        left: 91px;
        width: 272px;
        height: 58px;
        text-align: left;
        opacity: 1;
      };
    .ui.PrimaryButton.button{
        /* Layout Properties */
        top: 135px;
        left: 1146px;
        width: 160px;
        height: 50px;
        /* UI Properties */
        text-align: center;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    };
    .header{
        flex-Direction : row;
        display: flex;
        justify-Content: space-between;
    }
}
