@import '../../Theme/CommonStyles/variables.scss';

.LocationsList {
  padding-top: 80px;
  padding-left: 100px;
  padding-bottom: 50px;
  h2.ui.header.MainHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 63px;
  }
  .LocationsListContent {
    padding-top: 71px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 250em;
  }
  .CitiesContent{
    list-style: none;
    padding: 0;
    margin: 0;
    }
  .SecondaryHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 7px;
    padding-top: 26px;
    text-decoration: underline;
  }
  .Facility {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: underline;
  }
  @media only screen and (max-width: 768px) {
    .LocationsListContent {
      height: 300em;
    }
  }
  @media (max-width: 800px) {
  }
  @media (max-width: 480px) {
    padding-left: 0px;
    padding-bottom: 0px;
    .LocationsListContent {
      padding-top: 20px;
    }
    h2.ui.header.MainHeader {
      font-size: 30px;
      line-height: 40px;
    }
    .SecondaryHeader {
      font-size: 20px;
      line-height: 40px;
    }
    .Facility {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
