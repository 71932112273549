@import '../../../Theme/CommonStyles/variables.scss';
 
.DonorDetailAccordion {
    font-family: $font-family;
    width:470px;
    h1 {
        color: $dark-blue;
        font-family: $font-family;
        font-size: 38px;
        font-weight: $font-bold;
        letter-spacing: 0;
        line-height: 40px;
        display: inline;
        font-size: 24px;
        padding: 0;
        padding-left: 10px;
      }
}
