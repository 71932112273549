@import '../../Theme/CommonStyles/variables.scss';

.FacilityCard {
  margin-bottom: 48px;
  .ImgContainer {
    width: 186px;
    // max-width: 186px;
    img {
      width: 100%;
    }
  }
  h3.ui.header.FacilityName {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 6px;
    padding-top: 20px;
    a {
      color: $dark-blue;
    }
  }
  .Address {
    opacity: 0.7;
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    margin: 0px;
    max-width: 203px;
  }
  .Phone {
    opacity: 0.7;
    color: $dark-pink;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    border-bottom: 1px solid $dark-pink;
  }
  @media (max-width: 800px) {
    .ImgContainer {
      width: 185px;
    }
    h3.ui.header.FacilityName {
      font-size: 20px;
      line-height: 30px;
      padding-top: 0px;
    }
    .Address {
      font-size: 20px;
      line-height: 30px;
    }
    .Phone {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media (max-width: 480px) {
    .ImgContainer {
      width: 113px;
    }
    h3.ui.header.FacilityName {
      font-size: 15px;
      line-height: 20px;
      padding-top: 0px;
    }
    .Address {
      font-size: 15px;
      line-height: 20px;
    }
    .Phone {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
