@import '../../../Theme/CommonStyles/variables.scss';

.ui.text.menu .item.VerticalMenuItem {
  color: $light-blue;
  font-family: $font-family;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 40px;
  margin: 0;
  padding: 1px 0;
}

.ui.text.menu .active.item.VerticalMenuItem:hover {
  color: $dark-blue;
  font-family: $font-family;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 40px;
}

.ui.menu .active.item.VerticalMenuItem,
.ui.vertical.menu .active.item.VerticalMenuItem:hover {
  color: $dark-blue;
  font-family: $font-family;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: bold;
}