@import "../../../Theme/CommonStyles/variables.scss";

.ui.modal.BiolifePaymentCardsModal {
  max-width: 648px;
  max-height: 416px;

  .HeaderContainer {
    padding: 20.5px 25px 20.5px 25px;
  }

  h2.header.ModalHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }

  .ModalContent {
    max-width: 600px;
    // height: 144px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 45px;
    background-color: #eeedef;
    border-radius: 6px;

    .NotificationContent {
      .NotificationText {
        margin-left: -10px;

        .HeaderText {
          color: $gray;
          font-family: $font-family;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 30px;
          text-align: left;
          margin-bottom: 5px;
        }

        .RegularText {
          width: 463px;
          color: $gray;
          font-family: $font-family;
          text-align: left;
          margin-bottom: 2px;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: 0.02em;
          color: #484848;
        }
      }
    }
  }

  .ModalActions {
    padding-bottom: 22px;

    .ui.grid {
      flex-direction: row-reverse;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        padding-bottom: 0;
        padding-left: 100px;
      }

      @media only screen and (max-width: 480px) {
        flex-direction: column;
        padding-bottom: 0;
        padding-left: 0px;
      }
    }
  }

  img.ui.image {
    float: right;
    margin-top: 4px;
    cursor: pointer;
    
    @media only screen and (max-width: 480px) {
      margin-top: 5px;
    } 
  }

  @media only screen and (max-width: 767px) {
    max-width: 500px;

    // height: 144px;
    h2.header.ModalHeader {
      font-size: 20px;
    }

    .ModalContent {
      max-width: 450px;
      margin-left: 10px;
      margin-right: 10px;

      .NotificationContent {
        margin-left: -50px;

        .NotificationText {
          text-align: left;
          margin-left: -15px;

          .HeaderText {
            font-size: 17px;
          }

          .RegularText {
            max-width: 300px;
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 343px;
    // height: 144px;
    margin-left: 5px;
    margin-right: 0px;

    h2.header.ModalHeader {
      font-size: 20px;
      line-height: 30px;
    }

    .ModalContent {
      max-width: 311px;
      // height: 144px;
      margin-left: 2px;
      margin-right: 0px;

      .NotificationContent {
        margin-left: -30px;

        .NotificationText {
          text-align: left;
          margin-left: -15px;

          .HeaderText {
            font-size: 16px;
          }

          .RegularText {
            width: 220px;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    .ModalActions{
      .column{
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.ui.page.dimmer {
  z-index: 9999;
}