@import '../../../Theme/CommonStyles/variables.scss';
.CenterOpsCenter {
  .GoBack {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 30px;
    text-decoration: underline;
    display: block;
  }
  .CenterOpsDonorCenter {
    font-family: $font-family;
    background-color: $white;
    .CenterInfoContainer {
      background-color: #EBF7FA;
      padding-left: 45px;
      padding-top: 36px;
    }
    .DonationCenterLabel {
      line-height: 30px;
      padding-bottom: 0px;
      font-size: 12px;
      font-weight: bold;
      color: $dark-blue;
      margin-bottom: 0px;
    }
    .SelectSearch {
      margin-left: 0;
      height: 60px;
    }
    .ui.dropdown.CenterDropdown {
      // width: 198px;
      background-color: transparent;
      color: $dark-blue;
      font-size: 23px;
      letter-spacing: 0;
      line-height: 34px;
      margin: 0;
      padding-bottom: 30px;
      font-weight: bold;
      letter-spacing: 0;
      border: none;
      i.icon {
        line-height: 38px;
        float: right;
        color: $dark-blue;
        font-size: 16px;
        margin-left: 10px;
      }
    }

    .DonationCenterName {
      padding-top: 0px;
      line-height: 30px;
      font-size: 38px;
      font-weight: bold;
      color: $dark-blue;
      margin-top: 0px;
      margin-bottom: 30px;
    }

    .DonationCenterInfo {
      padding-top: 0px;
      line-height: 30px;
      font-size: 20px;
      color: $dark-blue;

      max-width: 204px;
      a {
        color: $dark-blue;
        border-bottom: 1px solid $dark-blue;
      }
    }

    h4.ui.header.SectionHeader {
      font-weight: bold;
      line-height: 30px;
      padding-bottom: 30px;
      font-size: 24px;
      color: $dark-blue;
    }

    .OfficeHoursContainer {
      padding-left: 45px;
      font-size: 20px;
      padding-top: 32px;
      padding-bottom: 30px;
    }
    .HoursRow {
      margin-bottom: 10px;
    }
  }
}
