@import "../../Theme/CommonStyles/variables.scss";

.AppAdvertisementContainer {
  background-color: white;
  margin: 50px 0;
  align-items: center;
  .AppAdvertisementLeftSide {
    padding: 25px;
    margin: auto;
    .AppAdvertisementVerbiage {
      margin: 0 15px;
      font-size: 26px;
      color: $dark-blue;
    }
    .AppAdvertisementDownloadToday {
      font-size: 18px;
      color: $dark-blue;
      font-weight: 700;
      margin: 0 15px;
    }
    .AppAdvertisementDownloadImages {
      padding: 0;
      .ui.image img {
        height: 40px;
      }
    }
  }
  .AppAdvertisementRightSide {
    padding: 0;
    background-color: #d7daff;
    clip-path: circle(70.8% at 84% 48%);
    .ui.image {
      margin: 40px auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .AppAdvertisementContainer {
    margin: 75px 0 0 0;
    .AppAdvertisementLeftSide {
      padding: 10px 0;
    }
    .AppAdvertisementRightSide {
      clip-path: circle(100% at 50% 100%);
    }
  }
}