@import '../../../Theme/CommonStyles/variables.scss';

.MyAppointments {
  font-family: $font-family;
  padding-top: 90px;
  padding-bottom: 400px;

  .UpcomingAppointments {
    .UpcomingAppointmentEntry {
      padding-bottom: 20px;
      padding-top: 15px;
      border-bottom: 1px solid #dddddd;

      .UpcomingAppointmentDetails {
        width: 100%
      }

      .AppointmentButton {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 16px;
        gap: 10px;
      }
      .ModifyButton {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-right: 10px;
        padding-left: 16px;
      }
      .column {
        width: 25%!important;
      }
    }

  }

  .PastDonationsHeader {
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: 200;
  }

  .PastDonations {
    .DonationHeader {
      border-bottom: 1px solid #dddddd
    }
  }

  .ScheduleAppointments,
  .UpcomingAppointments,
  .PastDonations {
    padding: 30px;
    background: #fff;
  }

  .ScheduleAppointments {
    border-bottom: 1px solid #DDDDDD;
  }

  .AppointmentEntries {
    color: #484848;
    font-size: 20px;
    line-height: 24px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    border-bottom: 1px solid #dddddd;
  }

  .Disclaimer {
    color: #484848;
    font-size: 20px;
    line-height: 40px;
    padding-bottom: 40px !important;
    padding-top: 20px !important;
  }

  .LastNextPhysicalDateLabel {
    font-size: 12px;
    padding-left: 20%;
    color: #373b3f;
    line-height: 20px;
    font-weight: bold;
  }

  .LastNextPhysicalDateText {
    font-size: 20px;
    padding-left: 20%;
    color: #009e8f;
    line-height: 20px;
    font-weight: bold;
  }

  .DonationHeader {
    font-size: 14px;
    color: #484848;
    font-weight: bold;
    padding-bottom: 15px !important;
  }

  .AppointmentDate {
    font-size: 20px;
    font-weight: bold;
    color: #484848;
    line-height: 30px;
    margin-bottom: 0px !important;
  }

  .PageText {
    color: #1f237a;
    padding-right: 40px;
    font-weight: bold;
  }

  .AppointmentLocation {
    font-size: 20px;
    color: #484848;
    line-height: 30px;
    margin-top: 0px !important;
  }

  .ui.menu>.item:first-child {
    display: none !important;
  }

  .ui.pagination.menu .item:last-child {
    display: none !important;
  }

  .ui.secondary.menu .item {
    font-size: 14px;
    font-weight: bold;
    font-family: $font-family;
    color: #6d76a7 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ui.pagination.menu .item {
    min-width: 2em !important;
    background: none !important;
  }

  .ui.pagination.menu .item:focus {
    outline: none;
  }

  .ui.pagination.menu .active.item {
    min-width: 2em !important;
    padding-top: 0.8em !important;
  }

  .ui.secondary.menu .active.item {
    color: #1f237a !important;
    font-size: 14px;
    font-weight: bold;
    font-family: $font-family;
    background: none !important;
  }

  .ui.pagination.menu {
    vertical-align: unset !important;
  }

  h4.ui.header {
    font-size: 24px;
    color: #1f237a;
    line-height: 30px;
    font-family: $font-family;
  }

  @media (max-width: 990px) {
    .UpcomingAppointments {
      .UpcomingAppointmentEntry {
        .ModifyButton {
          padding-top: 24px;
        }
        .AppointmentButton {
          padding-top: 40px;
          flex-wrap: wrap;
          gap: 14px;
          padding-bottom: 20px;
          padding-left: 18px;
          padding-right: 18px;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .ResponsiveButton {
      text-align: center;
      padding-bottom: 30px;
    }

    .LastNextPhysicalDateText {
      padding-left: 0;
    }

    .LastNextPhysicalDateLabel {
      padding-left: 0;
    }

    .AppointmentDate {
      font-size: 15px;
      line-height: 20px;
    }

    .AppointmentLocation {
      font-size: 15px;
    }

    .AppointmentEntries {
      font-size: 15px;
    }

    .ScheduleAppointments,
    .UpcomingAppointments,
    .PastDonations {
      padding: 0px;
    }
  }
}