@import '../../../Theme/CommonStyles/variables.scss';

.ui.grid.DonationCenter {
  font-family: $font-family;
  padding-left: 0 !important;
  padding-bottom: 400px !important;
  margin-bottom: 200px !important;
  .DifferHours {
    font-size: 20px;
  }
  .ui.dropdown.CenterDropdown {
    // width: 198px;
    background-color: transparent;
    color: $dark-blue;
    font-size: 23px;
    letter-spacing: 0;
    line-height: 34px;
    margin: 0;
    padding: 0;
    font-weight: bold;
    letter-spacing: 0;
    border: none;
    i.icon {
      line-height: 38px;
      color: $dark-blue;
      font-size: 16px;
      margin-left: 10px;
    }
    &.es-MX{
      font-size: 20px;
    }
  }

  h4.ui.header {
    font-size: 24px;
    line-height: 40px;
    color: $dark-blue;
    padding-bottom: 20px;
  }

  .TableHeader {
    color: $gray;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.09px;
    line-height: 21px;
    text-transform: uppercase;
  }

  .OfficeAmenities {
    color: $gray;
    font-size: 20px;
    line-height: 30px;
    ul {
      padding-left: 25px;
      margin: 0;
    }
  }

  .OfficeHoursDay {
    color: $dark-blue;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25.2px;
  }

  .OfficeHoursTime {
    color: $gray;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25.2px;
  }

  .Disclaimer {
    color: $gray;
    font-size: 16px;
    line-height: 22px;
    padding-top: 24px;
  }

  .DonationCenterLabel {
    padding-left: 45px;
    padding-top: 45px;
    line-height: 30px;
    padding-bottom: 0px;
    font-size: 12px;
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 0px;
  }

  .DonationCenterName {
    padding-left: 45px;
    padding-top: 0px;
    line-height: 30px;
    font-size: 38px;
    font-weight: bold;
    color: $dark-blue;
    margin-top: 0px;
    margin-bottom: 17px;
  }

  .DonationCenterInfo {
    padding-left: 45px;
    padding-top: 0px;
    line-height: 30px;
    font-size: 20px;
    color: $dark-blue !important;
    padding-bottom: 30px;
  }
  @media only screen and (max-width: 1400px) {
    .ui.dropdown.CenterDropdown.es-MX{
      font-size: 18px;
    }
  } 
  @media only screen and (max-width: 1290px) {
    .ui.dropdown.CenterDropdown{
     &.en-US{
      font-size: 20px;
     }
      &.es-MX{
        font-size: 15px; 
      }
    }
  } 
  @media only screen and (max-width: 480px) {
    padding-left: 0px !important;
    .DonationCenterLabel {
      padding-left: 20px;
    }

    .DonationCenterName {
      padding-left: 20px;
      font-size: 30px !important;
    }

    .DonationCenterInfo {
      padding-left: 20px;
      padding-bottom: 0px;
    }
  }
  .CallToChangeCenter{
    color: #484848;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
