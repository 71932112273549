// Color Palette

$dark-gray: #373b3f;
$light-gray: #dcdcdc;
$dark-pink: #29398C;
$light-blue: #6d76a7;
$dark-blue: #29398C;
$gray: #484848;
$green: #A6CE39;
$loyaltyDarkGreen: #007065;
$loyaltyLightGreen: #cce4e2;
$loyaltyLightGray: #f0eff1;
$loyaltyBlack: #222222;
$loyaltyLightGray: #F0EFF1;
$white: #ffffff;
$yellow: #FED301;
$light-green: #9dceca;
$orange: #eb6f24;
$blue: #1A2563;
$disable-gray: #acacac;
$black: #000000;
$light-gray-opacity: rgba(180, 180, 180, 0.06);
$light-gray-hero: #ebedf0;
$light-blue-hero: #d8daff;
$red: #e88084;
$bone-white: #fbf9f7;
$white-gray: #fafafa;
$facility-light-blue: #d8e9e8;
$donor-welcome-blue: #20247a;
$light-red: #f2dedf;
$dim-gray :#6D6D6D;
$new-gray: #EEEDEF;
$neutral-gray:#2D2D2D;
$lightGreen: #C0DA69;
$lightRed: #E1242A;
$BlackShade:#2D2D2D;
$light-gray:#ABABAB;
$color-orange: #F96506;
$color-orange-hover:#C75105;
$color-validation-red: #C70000;
$color-dark-gray: #999999;
$color-light-blue: #AEE0EA;
$color-rich-black: #333333;


// Font
$font-family: 'trade-gothic-next';
$font-bold: 700;
$font-extraBold: 800;
