@import "../../../Theme/CommonStyles/variables.scss";

.AccountCreateForm {
  width: 650px;
  margin: auto;
  .FormField {
    position: relative;
    padding-top: 30px;
    padding-bottom:5px;
    small {
      position: absolute;
      top: 0;
      color: #e6c153;
      font-family: $font-family;
    }
    .TopLabel {
      position: absolute;
      color:$gray ;
      font-family: $font-family;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 23px;
      top: 0;
    }
    .TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 23px;
      top: 86%;
      white-space: nowrap;
    }
  }
  .AccountFormTextInput {
    color: $gray;
    font-family: $font-family;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    border-radius: 0px;
    margin-bottom: 8px;
    margin-right: 5px;
    position: relative;
  }
  .LargeFieldsWidths{
    width:665px;
  }
  .StandardFieldsWidths{
    width:323px;
  }
  .ui.disabled.input{
    background-color: $white;
    border-radius:4px;
    opacity:1;
  }
  .ui.selection.dropdown.Base.AccountFormLargeSelect {
    width: 323px;
    height: 46px;
    margin-right: 5px;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    font-family: $font-family;
    border: 1px solid #d8d8d8;
    border-radius:4px;
    box-sizing: border-box;
     .divider.text{
       white-space: nowrap; 
       overflow: hidden; 
       text-overflow: ellipsis; 
       width: 90%;
     }
  }
  .ui.selection.dropdown.Base.AccountFormLargeSelect.largerSelect{
    width: 541px;
  }
  .ui.selection.dropdown.Base.AccountFormNormalSelect {
    height: 46px;
    width: 263px;
    margin-right: 20px;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    font-family: $font-family;
    border: 1px solid #d8d8d8;
    border-radius:4px;
    box-sizing: border-box;
  }
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: $gray;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    font-family: $font-family;
  }
  .MarginBottom {
    margin-bottom: 20px !important;
  }
  .LoyaltyCheckboxContainer{
    padding-top: 20px !important; 
    animation: fadeIn 1s ease-in both;
  }
  .RadioLocation,.RadioLocationChecked{
    max-width:min-content;
  }
  .CheckBoxContainer {
    padding-left: 5px;
    margin : 20px 0px;
    .ui.checkbox .box:before,
    .ui.checkbox label:before {
      border-radius: 0;
      box-sizing: border-box;
      height: 21px;
      width: 21px;
      border: 2px solid $blue;
      background-color: $white;
    }
    .ui.checkbox .box,
    .ui.checkbox label {
      color: $dim-gray;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
      padding-left: 40px;
    }
  }
  .CentersNearYou {
    color: $gray;
    font-family: $font-family;
    font-size: 24px;
    letter-spacing: 0;
  }
  .RadioLocation {
    box-sizing: border-box;
    min-width: 326px;
    min-height: 280px;
    border: 1px solid #D4D4D4 !important;
    border-radius: 8px !important;
    background: $white-gray !important;
    padding: 17px !important;

    .ui.radio.checkbox {
      margin-top: 4px;
    }
    .ui.checkbox .box:before,
    .ui.checkbox label:before {
      box-sizing: border-box;
      height: 21px;
      width: 21px;
      border: 2px solid $new-gray;
      background: transparent;
    }
  }
  .ErrorMsgContainer{
    .InfoIcon{
      margin-top:3px;
    }
  }
  .PrimarySelect .ui.selection.dropdown.Base.Error .default.text {
    color: $gray;
}
.UsernameContainer{
  margin-left:0.5em;
  .Username{
    margin-bottom: 0 !important;
  }
}
.ui.form .field :disabled{
  opacity:1;
  color: #ababab;
}

.NonEditableTextContainer{
    display: flex;
    margin-top: -6px;
    .GrayInfoIcon {
      margin-right: 10px;
    }
    .NonEditableText{
      color:$gray;
      font-size:14px;
    }
}


  @media only screen and (max-width: 767px){
    .FormField{
      margin-bottom: 12px !important;
    }
    .NonEditableTextContainer{
      margin-top: -15px !important;
      margin-bottom:20px;
    }
  }
  @media only screen and (min-width: 767px) {
    .RadioLocation:active,
    .RadioLocation:hover {
      border: 1px solid $dark-pink !important;
      background-color: $dark-pink !important;
      cursor: pointer;
      .LocationName,.spanishCenter,.locationAddress{
        color:$white !important;
        }
      .LocationName,.NewDonorBonusInformationBlock{
        color:$white !important;
      }
      .distanceInfo {
        color: $white !important;
        svg path {
          stroke: $white;
        }
      }
    }
  }
  
  .RadioLocationChecked:active,
  .RadioLocationChecked {
    background-color: $dark-pink !important;
    border-radius: 8px !important;
    box-sizing: border-box;
    min-height: 280px;
    min-width: 326px;
    padding: 17px !important;
    .LocationName,.spanishCenter,.locationAddress{
    color:$white !important;
    }
    .LocationName,.NewDonorBonusInformationBlock,.ClockOutline{
      color:$white !important;
    } 

    .ui.radio.checkbox {
      margin-top: 4px;
    }

    .ui.checkbox .box:before,
    .ui.checkbox label:before {
      box-sizing: border-box;
      height: 21px;
      width: 21px;
      border: 2px solid $white;
      background: #f0f0f0;
    }

    .ui.checkbox input:checked ~ .box:before,
    .ui.checkbox input:checked ~ label:before {
      background-color: #f0f0f0;
      border: none;
    }
    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      opacity: 0;
    }
    .distanceInfo {
      color: $white !important;
      svg path {
        stroke: $white;
      }
    }
  }
  .LocationName {
    color: $gray;
    font-family: $font-family;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  .spanishCenter {
    opacity: 0.7;
    color: $dim-gray;
    font-family: $font-family;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  .locationAddress {
    opacity: 0.7;
    color: $dim-gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
  }
  .NewDonorBonusInformationBlock{
    opacity: 0.7;
    color: $dim-gray;
    font-family: $font-family;
    font-size: 14px;
    .ClockOutline{
      margin: 0;
    }
  }
  .distanceInfo {
    display: flex;
    align-items: center;
    margin-top: 12px;
    opacity: 0.7;
    color: $dim-gray;
    font-size: 14px;
    font-family: $font-family;
      svg {
        margin-right: 6px;
      }
  }
  .distanceIcon{
    margin-right: 4px;
  }
  .GetDirections {
    opacity: 0.7;
    color: $white;
    font-family: $font-family;
    text-decoration: underline;
  }
  .LoginCredentials {
    padding-bottom:12px;
  }
  .PasswordRequirements {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }
  .ui.list.RequirementsList {
    color: $white;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0.08px;
    line-height: 13px;
    max-width: 380px;
    margin-bottom: 20px;
    .RequirementNeeded {
      color: #e6c153;
    }
  }
  .ui.input.error > input::-webkit-input-placeholder {
    color: $dark-pink;
  }
  .ui.input.error > input {
   border: 1px solid $dark-pink;
  }
  .ui.input.error {
    box-sizing: border-box;
  }
  .ui.selection.dropdown.Base.AccountFormLargeSelect.Error {
    border: 1px solid $dark-pink;
  }
  .ui.selection.dropdown.Base.AccountFormNormalSelect.Error {
    border: 1px solid $dark-pink;
  }
  .CheckBoxContainer {
    padding-left: 7px;
    padding-top: 16px;

    input {
      border-radius: 0;
      box-sizing: border-box;
      height: 21px;
      width: 21px;
      margin-top: 5px;
      background-color: $white;
      @media not all and (min-resolution:.001dpcm) { 
        @supports (-webkit-appearance:none) {
          @media only screen and (max-width: 767px) {
            transform: scale(1.3, 1.3);
            outline: none;
          }
        }
      }
    }
    p,
    label {
      color: $dim-gray;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;

      a {
        font-weight: bold;
        text-decoration: underline;
        color: $dark-pink;
      }
    }
  }
  .SignUpButtonContainer {
    margin-top: 2.5em;
  }
  .ReceiveMessages {
    label {
      a {
        text-decoration: underline;
        color: $white;
      }
    }
  }
  .LocationError {
    color: #e6c153;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 35px;
  }
  .AccountFormTextInput.error > input::-webkit-input-placeholder {
    color: $dark-pink;
  }
  .AccountFormTextInput.error {
    input {
      box-sizing: border-box;
      border: 1px solid $dark-pink;
      color: $gray;
    }
  }
  .ShowAgeError {
    height: 23px;
    width: max-content;
    color: #e6c153;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
  }
  .DateOfBirthInput > input {
    border: 1px solid $dark-pink;
  }
  @media only screen and (max-width: 768px){
    width: auto;
    .RadioLocation,.RadioLocationChecked{
      min-width:280px;
      min-height: 280px;
      max-width:none;
    }
    .field,.fields{
      width:100% ;
    }
    .ui.compact.segment.RadioLocation, .ui.compact.segment.RadioLocationChecked{
      width:100% !important;
    }
    .LargeFieldsWidths,.StandardFieldsWidths,.ui.selection.dropdown.Base.AccountFormNormalSelect,.ui.selection.dropdown.Base.AccountFormLargeSelect,.ui.selection.dropdown.Base.AccountFormLargeSelect.largerSelect{
      width:auto;
      min-width:280px;
    }
    .ui.compact.segment.RadioLocation {
      margin-bottom: 20px;
      min-height: 256px;
    }
    .RadioLocationChecked {
      margin-bottom: 20px !important;
    }
    .UsernameContainer{
      width: 100%;
      margin-right: 8px;
    }
  }
  @media only screen and (max-width: 480px) {
    
    .ui.selection.dropdown.Base.AccountFormLargeSelect.largerSelect.NHOPICustomStyle{
      height: 75px;
      .divider.text{
        width: 280px;
      }
    }
    .submitBtnGroup{
      justify-content: center;
    }
    .CentersNearYou {
      font-size: 22px;
    }
  }
  .ListItemWithoutIcon {
    padding-left: 1.8em;
  }
}


@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -25%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}