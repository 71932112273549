@import "../../../Theme/CommonStyles/variables.scss";

.BlogContainer{
    .ui.grid{
        .SearchLabel{
            margin-bottom: 5px;
        }
        .column.SearchContainer {
            width: 72% !important;
        }
        .column.FilterContainer {
            width: 28% !important;
        }
    }
    .ui.search {
        .input {
            width: 55% !important;
            height: 3.5rem;
        }
        .input > input{
            width: 100% !important;
            padding: 15px;    
        }
        .prompt{
            border-radius: 0;
        }
    }
    .Blogs{
        padding: 42px 0;
    }
    .BlogViewContainer{
        .Link{
            img{
                display: inline;
            }
            font-weight: 700;
            font-size: 16px;
        }
        .BlogTitle{
            color: $dark-blue;
        }
        .BlogHeroImage{
            width: 100%;
        }
        .BlogStats{
            margin: 0;
            font-size: 12px;
            img{
                display: inline;
                margin-bottom: 5px;
            }
        }
        .BlogContent{
            padding: 25px 0 75px 0;
            .BlogImage{
                width: 100%;
            }
        }
    }
    .ExploreMoreHeading{
        color: $dark-blue;
        font-size: 26px;
    }
    .ExploreMoreBlogContainer{
        display: flex;
        flex-wrap: wrap;
        padding: 25px 0;
        row-gap: 25px;
    }

    /* The container */
    .OptionContainer {
        color: #484848;
        font-size: 16px;
        letter-spacing: 0.32px;
        position: relative;
        padding-left: 36px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .OptionContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border: 2px solid #2181BC;
        border-radius: 2px;
    }

    /* When the checkbox is checked, add a blue background */
    .OptionContainer input:checked ~ .checkmark {
        background-color: #2181BC;
        border-radius: 2px;
    }

    /* Create the checkmark/indicator (hidden when not checked)*/ 
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .OptionContainer input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .OptionContainer .checkmark:after {
        left: 5px;
        top: 2px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-radius: 1px;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .ui.button.ClearFilterBtn {
        padding-left: 34px !important;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.32px;
        background-color: transparent;
        color: $dark-pink;
        &:hover {
            background-color: transparent !important;
        }
        .close.icon {
            background-color: transparent;
            color: $dark-pink;
        }
    }

    .NoSearchResults {
        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 8px;
        p {
            margin-bottom: 0px;
            margin-left: 8px;
            color: #484848;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
        }
        img {
            width: 24px;
        }
    }

    .PaginationContainer {
        display: flex;
        align-items: center;
        margin-top: 3px;
        .PaginationLabel {
            color: $dark-blue;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
            margin-right: 20px;
        }
        .pagination.ui.secondary.menu{
            .item {
                background-color: transparent;
                &:not(:first-child):not(:last-child) {
                    font-size: 18px;
                    color: $light-blue;
                }

                .lookDisable {
                    color: #ABABAB
                }
                .lookActive {
                    color: #484848
                }
            }
            .active {
                padding-top: 14px;
                &:not(:first-child):not(:last-child) {
                    color: $dark-blue;
                }
            }
        } 
    }
    
}

@media only screen and (max-width: 448px) {
    .BlogContainer{
        .Blogs{
            flex-direction: column;
        }
        .ExploreMoreHeading{
            font-size: 18px
        }
        .ui.grid {
            .column.SearchContainer {
                width: 100% !important;
            }
            .column.FilterContainer {
                width: 60% !important;
                margin-top: 16px;
            }
        }
        .ui.search {
            .input {
                width: 100% !important;
            }
        }
        .NoSearchResults {
            img {
                display: flex;
                align-self: flex-start;
            }
        }
        .PaginationContainer {
            .PaginationLabel {
                font-size: 14px;
                margin-right: 5px;
            }
            .pagination.ui.secondary.menu{
                .item {
                    &:not(:first-child):not(:last-child) {
                        font-size: 14px;
                        min-width: none;
                    }
                    .lookDisable {
                        font-size: 14px;
                    }
                    .lookActive {
                        font-size: 14px;
                    }
                    &:first-child, &:last-child {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
                .active {
                    padding-top: 11px;
                }
            }
        }
    }
}

