@import '../../../../Theme/CommonStyles/variables.scss';

.FormField {
  .ErrorMsgContainer {
    display: flex;
    .InfoIcon {
      margin-right: 10px;
    }
    .ErrorMsg{
      padding-top: 2px;
    }
  }
  .asteriskStyle{
    color: $color-validation-red ;
    margin-left: 2px;
  }
}
