@import '../../../Theme/CommonStyles/variables.scss';
.DayLabel {
  color: $BlackShade;
  font-family: $font-family;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.67px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}
.ui.label.DayNumberLabel {
  color:$BlackShade;
  font-family: $font-family;
  font-size: 34px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  opacity: 1;
  font-weight: normal;
  background-color: transparent;
}
.ui.label.DayNumberLabelSelected {
  color: $white;
  font-family: $font-family;
  font-size: 34px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  opacity: 1;
  font-weight: normal;
  background-color: $dark-pink;
  padding: 18px 16px 18px 14px !important;
  width: 72px;
  height: 70px;
}
.ui.label.DayNumberLabelSelectedGrey {
  background-color: $disable-gray;
}
.ui.label:last-child{
 margin-left: 0;
}
@media only screen and (max-width: 480px) {
  .DayLabel {
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
  }
  .ui.label.DayNumberLabel {
    font-size: 22px;
    line-height: 15px;
    font-weight: 400;
  }
  .ui.label.DayNumberLabelSelected {
    font-size: 20px;
    line-height: 12px;
    font-weight: 700;
    width: 41px;
    height: 40px;
    padding: 12.5px 9px !important;

  }
}
