@import '../../../Theme/CommonStyles/variables.scss';

.NoCentersInArea {
  h2.header.MainHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
  }
  .CenterSelectionContainer {
    padding: 29px 45px 34px 45px;
    background-color: $white;
    margin-top: 22px;
  }
}
