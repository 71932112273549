@import '../../../Theme/CommonStyles/variables.scss';

.LastPhysicals {
  padding: 25px 44px 51px 44px;
  background-color: $white;
  border-bottom: 1px solid #dddddd;
  .PhysicalHeader {
    color: $dark-gray;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .PhysicalText {
    color: $green;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }
}
