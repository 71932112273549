@import '../../../Theme/CommonStyles/variables.scss';

.AppointmentFailure {
  text-align: center;
  padding: 20px;
  padding-top: 10px !important;
}
.AppointmentFailureMesssage {
  color: $color-validation-red;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
}

.AppointmentTypeAndPlayroom {
  width: 520px;
  margin: auto;
  padding: 50px 130px;
  .HugeSelectContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    .FirstTimeDonortext{
      padding-top: 8px;
   }
  }
  .LabelForSelect {
    color: $dark-gray;
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 12px;
    white-space: nowrap;
  }
  .ui.selection.dropdown.MarginLeft {
    margin-left: 15px;
  }
  .HugeSelectLarge {
    .ui.selection.dropdown.Base {
      height: 65px;
      line-height: 33px;
      padding-left: 25px;
      padding-right: 25px;
      border-radius: 32.5px;
      background-color: $dark-pink;
      color: $white;
      font-family: "trade-gothic-next";
      font-size: 16.8px;
      font-weight: bold;
      letter-spacing: 0;
      i.icon {
        line-height: 38px;
        float: right;
        color: $white;
        font-size: 16px;
      }
    }
    .ui.default.dropdown:not(.button) > .text,
    .ui.dropdown:not(.button) > .default.text {
      color: $white;
      font-family: $font-family;
      font-size: 20.8px;
      font-weight: bold;
      letter-spacing: 0;
    }
    .ui.selection.dropdown .menu {
      max-height: max-content;
    }
    .ui.selection.dropdown.Base.Error {
      border: 1px solid $dark-pink;
    }
    .ui.selection.dropdown.Base.es-MX {
      padding-left: 8px;
      padding-right: 8px;
    }
    .ui.floating.dropdown > .menu {
      box-shadow: none;
    }
    .ui.selection.active.dropdown .menu {
      border: $gray;
    }
    .ui.selection.dropdown .menu > .item {
      font-size: 18px;
    }
    .ui.active.selection.dropdown {
      border-bottom-left-radius: 32.5px !important;
      border-bottom-right-radius: 32.5px !important;
      border-top-left-radius: 32.5px !important;
      border-top-right-radius: 32.5px !important;
    }
    .ui.selection.dropdown .menu > .item {
      padding: 20px 15px !important;
      border-bottom: 1px solid #D4D4D4;
    }
    .ui.selection.visible.dropdown > .text:not(.default) {
      font-weight: bold;
      color: $white;
    }
  }
  @media only screen and (min-width: 480px) {
    .FirstTimeDonorMesssage{
      padding-left: 10px;
    }
  }
  @media only screen and (max-width: 360px) {
    .HugeSelectContainer {
      max-width: 100%;
    }
    .AppointmentFailureContainer {
      margin-left: -40px;
      width: 223px;
    }
    .AppointmentFailure {
      text-align: left;
      padding-top: 10px !important;
    }
    
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    .HugeSelectContainer {
      max-width: 100%;
      .FirstTimeDonorMesssage{
        width: 230px;
        text-align: left;
      }

    }
    .AppointmentFailureMesssage {
      font-size: 12px;
      width: 230px;
    }
  }

}
