@import '../../../Theme/CommonStyles/variables.scss';

.ui.vertical.menu.MemberDashboardMobileMenu {
  width: 100%;
  .item.MenuHeader {
    color: $light-blue;
    font-family: "trade-gothic-next";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
  }
  .item.MenuHeader.open {
    color: $dark-blue;
  }
  .MenuContent {
    padding: 25px;
  }
}

@media only screen and (max-width: 992px) {
  .DonorInformation,
  .DonationCenter,
  .UnavailableContainer,
  .MyAppointments,
  .UpdatePasswordForm,
  .AccountSetting .UpdateEmailForm,
  .DashboardAppointmentsSection,
  .ui.form.UpdatePreferencesForm {
    width: 95%;
    max-width: 100%;
    padding-bottom: 20px;
    margin: auto;
  }
  .DashboardPreferences,
  .ui.form.UpdateAccountForm {
    max-width: 100% !important;
  }
  .ui.grid.DonationCenter {
    width: 100%;
    padding-bottom: 20px !important;
    margin-bottom: 0 !important;
  }
}