.AddUserCenterAccessModal{
    /* Layout Properties */
    top: 0vh;
    left: 0vw;
    width: 960px !important;
    height: 20em;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 42px #00000044;
    opacity: 1;
    position: relative !important;

    .ModalContent{
        /* Layout Properties */
        left: 50px;
        width: 574px;
        height: 50px;
        text-align: center;
        letter-spacing: 0px;
        color: #484848;
        opacity: 1;
        position: relative;
        font-size: 20px;
    }
    .ModalHeader{
        /* Layout Properties */
        //width: 350px;
        height: 33px;
        /* UI Properties */
        letter-spacing: 0px;
        color: #1f237a !important;
        opacity: 1;
        position: relative;
    }
    .PrimaryButton{

        position: relative;
    }
    .close{
        left: 175px;
    }
    .CloseModal{
        cursor: pointer;
    }
}