@import '../../../Theme/CommonStyles/variables.scss';

.PrimaryInput {
  padding-bottom: 10px;
  position: relative;
  .ui.input > input {
    border-radius: 0;
  }
  .ui.input > input::-webkit-input-placeholder {
    color: $gray;
    opacity: 1;
    font-family: $font-family;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 50px;
  }
  .ui.input.error > input {
    border-color: $dark-pink;
    color: $dark-pink;
    box-shadow: none;
    font-size: 12px;
  }
}
