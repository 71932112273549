@import '../../../Theme/CommonStyles/variables.scss';

.SignUpForm {
  float: right;
  .ui.form {
    max-width: 400px;
  }
  .SignUpInput {
    .ui.input > input {
      border-radius: 5px;
      padding-left: 25px;
      font-size: 22px;
    }
    .ui.input > input::-webkit-input-placeholder {
      color: $gray;
      opacity: 0.5;
      font-family: $font-family;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 27px;
    }
  }
  .FormField {
    position: relative;
    padding-top: 28px;
    padding-bottom:8px;
    small {
      position: absolute;
      top: 0;
      color: $dark-pink;
      font-family: $font-family;
      font-size: 12px;
    }
    small.TopLabel {
      position: absolute;
      color: $neutral-gray;
      font-family: $font-family;
      font-size: 15px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 0%;
      margin-top:7px;
    }
    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top:96%;
    }
    small.TopLabelError.es-MX {
      white-space: nowrap;
    }
  }
  .DisclaimerText {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 10px;
    margin-top:35px;
    a {
      font-weight: bold;
      text-decoration: underline;
      color: $gray;
    }
  }
  .CheckBoxContainer {
    padding-left: 7px;
    padding-top: 16px;

    input {
      border-radius: 0;
      border: 1px solid $dark-blue;
      width: 17px;
      height: 17px;
      margin-top: 5px;
    }

    label {
      color: $gray;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;

      a {
        font-weight: bold;
        text-decoration: underline;
        color: $gray;
      }
    }
  }
  .SignUpButtonContainer {
    padding-top: 41px;
  }
  .contact-us-Container{
    padding-top: 1rem;
    color: $gray;
    font-size: 16px;
  }
  .linkStyle{
    color: $dark-pink;
    text-decoration: underline;
  }
  .ui.input.error > input {
    border: 1px solid $dark-pink;
  }
  .ErrorMsgContainer{
    .ErrorMsg{
      padding-top:2px;
    }
  }
  @media only screen and (max-width: 480px) {
    .ui.form {
      max-width: 350px;
    }
    .SignUpButtonContainer {
      width: 250px;
      margin: auto;
    }
  }
  @media (max-width: 992px) {
    float: none;
    justify-content: center;
    align-items: center;
    display: flex;
    .ui.form .fields {
      align-items: center;
      justify-content: center;
    }
   
  }
  @media (max-width: 768px){
    .FormField{
      padding-bottom:24px;
      small.TopLabelError {
      top:82%;
      }
    }
    .DisclaimerText{
      margin-top:40px;
    }
  }
}
