@import '../../../Theme/CommonStyles/variables.scss';

.centerSlotsFailure {
  background-color:$light-red;
  text-align: center;
  padding: 20px;
}
.centerSlotsFailureMesssage {
  color: $dark-pink;
 font-weight: 700;
 font-size: 18px;
}
.DateTimePicker {
  padding-bottom: 300px;
.withPadding {
  padding-right: 6%;
  padding-left: 6%;
}
  .SelectYourAppointment {
    color: $gray;
    font-family: $font-family;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: #484848;
    text-transform: uppercase;
    word-wrap: break-word;
    border-top: 1px solid #dddddd;
    padding-top:35px;
  }
  @media only screen and (max-width: 992px) {
    padding-bottom: 50px;
    .ui.divided.grid:not([class*='vertically divided']) > .column:not(.row) {
      box-shadow: none;
    }
    .SubmitAppointment {
      padding-left: 0;
      text-align: center;
      .ButtonContainer {
        padding-top: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .SubmitAppointment {
      padding-left: 0;
    }
    .SelectYourAppointment {
      font-size: 15px;
      padding-bottom: 0px;
    }
  }
}
@media only screen and (max-width: 480px) {
.centerSlotsFailureMesssage {
  font-size: 14px;
}
}