@import '../../../Theme/CommonStyles/variables.scss';

.LastDonations {
  padding: 25px 44px 51px 44px;
  background-color: $white;
  h4.ui.header.DonationHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    padding-bottom: 10px;
  }
  .ui.grid > .row.RowData {
    padding-bottom: 23px;
    padding-top: 13px;
    font-weight: bold;
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    .DonationLocation {
      font-weight: normal;
    }
  }
}
