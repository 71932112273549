@import '../../../Theme/CommonStyles/variables.scss';

.DonorSearchDonationCenterInformation {
  font-family: $font-family;

  h3.ui.header.CenterInformationHeader {
    font-size: 38px;
    color: $dark-blue;
    font-family: $font-family;
    padding-bottom: 30px;
  }

  .DonationCenterLabel {
    padding-left: 45px;
    padding-top: 45px;
    line-height: 30px;
    padding-bottom: 0px;
    font-size: 12px;
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 0px;
  }

  .ChangeCenterBlock{
    padding-bottom: 35px;
    margin-left: 35px;
  }

  .ui.dropdown.CenterDropdown {
    // width: 198px;
    background-color: transparent;
    color: $dark-blue;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 34px;
    font-weight: normal !important;
    letter-spacing: 0;
    border: none;
    i.icon {
      line-height: 38px;
      float: right;
      color: $dark-blue;
      font-size: 16px;
      margin-left: 10px;
    }
  }

  .DonationCenterName {
    padding-left: 45px;
    padding-top: 0px;
    line-height: 30px;
    font-size: 38px;
    font-weight: bold;
    color: $dark-blue;
    margin-top: 0px;
  }

  .DonationCenterInfo {
    padding-left: 45px;
    padding-top: 0px;
    line-height: 30px;
    font-size: 20px;
    color: $dark-blue;
    padding-bottom: 30px;
    opacity: 0.7;
    a {
      color: $dark-blue;
      border-bottom: 1px solid $dark-blue;
    }
  }

  .OfficeHoursHeader {
    margin: 0px !important;
    padding-left: 45px;
    padding-top: 45px;
    font-weight: bold;
    line-height: 30px;
    padding-bottom: 0px;
    font-size: 24px;
    color: $dark-blue;
  }

  .OfficeHoursContainer {
    &.ui.list {
      font-size: 1em;
      padding-top: 30px;
      padding-left: 18px;
    }
  }
  .CenterMoreDetailsContainer {
    background-color: $white;
    padding-bottom: 20px;
  }


  @media only screen and (max-width: 480px) {
    h3.ui.header.CenterInformationHeader {
      font-size: 30px;
      color: $dark-blue;
      line-height: 30px;
      font-family: $font-family;
      padding-bottom: 11px;
      padding-left: 15px;
      padding-top: 20px;
    }
    .OfficeHoursContainer {
      font-size: 16px;
      padding-top: 30px;
    }
  }
}
