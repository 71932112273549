@import '../../../Theme/CommonStyles/variables.scss';

.AppointmentHours {
  padding-right: 15px;
  padding-left: 30px;
  .ui.compact.segment {
    display: block;
  }
  .ui.segment.HourButton {
    height: 55px;
    width: 114px;
    border: 2px solid $color-dark-gray;
    border-radius: 32px;
    margin-top: 10px;
    text-align: center;
    .ui.radio.checkbox .box,
    .ui.radio.checkbox label {
      padding-left: 3px;
      color: $color-dark-gray;
      font-family: $font-family;
      font-size: 20.4px;
      font-weight: 500;
      letter-spacing: -1.12px;
      line-height: 25.8px;
      text-align: center;
    }
  }
  .ui.segment.HourButtonSelected {
    height: 55px;
    width: 114px;
    margin-top: 10px;
    background-color: $color-orange;
    border-radius: 32px;
    .ui.radio.checkbox .box,
    .ui.radio.checkbox label {
      padding-left: 3px;
      color: $white;
      font-family: $font-family;
      font-size: 20.4px;
      font-weight: 500;
      letter-spacing: -1.12px;
      line-height: 25.8px;
      text-align: center;
    }
  }
  .ui.radio.checkbox .box:before,
  .ui.radio.checkbox label:before {
    display: none;
  }
  .ui.radio.checkbox input:checked ~ .box:after,
  .ui.radio.checkbox input:checked ~ label:after {
    background-color: transparent;
    display: none;
  }
  .ui.grid > .row > .column {
    padding-bottom: 20px;
  }
  .NoResults {
    font-size: 16px;
    color: $gray;
    line-height: 20px;
    font-weight: bold;
    color: $dark-pink;
  }
  @media only screen and (max-width: 480px) {
  padding-left: 0;
    padding-right: 0;
    .ui.segment.HourButton .ui.radio.checkbox .box,
    .ui.segment.HourButton .ui.radio.checkbox label {
      font-size: 15.4px;
      line-height: 20.8px;
    }
    .ui.segment.HourButton {
      width: 100% !important;
    text-align: center;
    }
    .ui.segment.HourButtonSelected {
      width: 100% !important;
    text-align: center;
    background-color: #009E8F;
    }
    .ui.segment.HourButtonSelected .ui.radio.checkbox .box,
    .ui.segment.HourButtonSelected .ui.radio.checkbox label {
      font-size: 15.4px;
      line-height: 20.8px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .AppointmentHours {
    .AppointmentSlotGrid {
      &.ui.grid {
        margin-bottom: 0;
      }
    }
  }
}
