@import '../../../Theme/CommonStyles/variables.scss';

.MyLoyaltyEnrolmentContainer{
    font-family: 'trade-gothic-next';
    padding: 0 0 100px 0;
    .EnrolHeading{
        color: $loyaltyDarkGreen;
        font-size: 50px;
        margin-bottom: 25px;
    }
    .EnrolSuccessHeading{
        width:90%;
    }
    .EnrolTextPrimary{
        color: $gray;
        font-size: 18px;
        padding: 20px 0;
    }
    .EnrolTextSecondary{
        color: $dark-gray;
        font-size: 18px;
        font-weight: 600;
        padding: 15px 0;
    }
    .EnrolSuccessMessageContainer{
        width: 90%;
        margin:0px 0;
        h3{
            display: none;
        }
        p{
            padding-top:0px;
            color: $gray;
            font-size: 18px;
            margin-bottom:15px;
            margin-right:20px;
        }
        button{
            background-color: $loyaltyDarkGreen !important;
            margin: 20px 0;
        }
    }
    .CheckboxContainer{
        background-color: $loyaltyLightGreen;
        padding: 25px;
        margin: 25px 0;
        border-radius: 25px;
        input{
            height: 21px;
            width: 21px;
            margin-top: 5px;
        }
        .CheckboxLabel{
            font-size: 16px;
            color: $gray;
        }
        .EnrollNowButton{
            background-color: $loyaltyDarkGreen !important;
        }
    }
    .EnrolmentHeroImage{
        width:500px;
        height:600px;
    }
    .enrollmentUncheckError {
        color: $dark-pink;
    }
}

@media only screen and (max-width: 480px) {
    .MyLoyaltyContainer{
        .EnrolHeading{
            font-size: 26px;
        }
        .EnrolSuccessHeading{
            width:100%;
        }
        .EnrolSuccessMessageContainer{
            width:95%;
            h3{
                font-size: 26px;
                margin-top:15px;
            }
            p{
                margin-right:0 ;
                margin-top:20px;
            }
        }
        .EnrolmentHeroImage{
            width:350px;
            height:350px;
        }
    }
}