@import "../../../Theme/CommonStyles/variables.scss";

.DashboardAppointmentsSection {
  font-family: $font-family;

  h3.ui.header.AppointmentsHeader {
    font-size: 38px;
    color: #1f237a;
    line-height: 40px;
    font-family: $font-family;
    padding-bottom: 30px;
  }

  h4.ui.header.UpcomingAppointmentsHeader {
    font-size: 24px;
    color: #1f237a;
    line-height: 30px;
    font-family: $font-family;
  }

  .RowContainerWithBottomBorder {
    border-bottom: 1px solid #dddddd;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 35px;
  }

  .RowContainerWithoutBottomBorder {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 35px;
  }

  .LastNextPhysicalContainer {
    width: "auto" !important;
  }

  .LastNextPhysicalHeader {
    &.ui.header {
      color: #373b3f;
      font-family: $font-family;
      font-size: 12px !important;
      margin-bottom: 3px;
      padding-right: 35px;
    }
  }

  .LastNextPhysicalText {
    color: $color-orange;
    font-family: $font-family;
    font-size: 20px !important;
    margin-top: 0px;
    padding-right: 35px;
  }

  .UpcomingAppointmentsRow {
    padding-bottom: 15px;
    padding-top: 15px;
    
    .ModifyButton{
      margin-left: 10px;
      margin-right: 15px;
    }
    .ChangePaymentButton{
      margin-left: 10px;
    } 
    .AppointmentsButton {
      display: flex;
      flex-wrap: nowrap;
      justify-content: left;
      padding-top: 20px;
      padding-left: 4px;
      grid-gap: 5px;
      gap: 5px;

      @media only screen and (max-width: 480px) {
        h4.ui.header.UpcomingAppointmentsHeader {
        margin-left: 5px;
        }
        display: contents;
        .ModifyButton{
          margin-bottom: 10px;
        }
      }

      @media only screen and (max-width: 390px) {
        gap: 1px;
        margin-left: -5px;
        display: contents;
        .ModifyButton{
          margin-bottom: 10px;
          margin-right: 15px;
        }
        .ChangePaymentButton{
          margin-left: 10px;
        }
      }
      @media only screen and (max-width: 1300px) {
        display: grid;
        .ChangePaymentButton{
          padding-top: 8px;
        }
      }
    }
  }

  .UpcomingAppointmentsBorderBottom {
    border-bottom: 1px solid #dddddd;
  }
  

  .UpcomingAppointmentsInfoFirst {
    font-size: 20px;
    color: #484848;
    line-height: 30px;
  }

  .UpcomingAppointmentsInfoSecond{
    font-size: 20px;
      color: #484848;
      line-height: 30px;
  }

  .UpcomingAppointmentsInfoBold {
    font-size: 20px;
    color: #484848;
    line-height: 30px;
  }

  .AppointmentsContainer {
    background-color: $white;
  }

  .UpcomingAppointmentsContainer {
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .BuddyBonusSection {
    width: 96%;
    padding-top: 10px;
    padding-right: 15px;
    .BuddyBonusDescription {
      font-family: $font-bold;
      font-size: 16px;
      word-wrap: break-word;
      color: $gray;
      padding-top: 12px;
    }
  }

  @media only screen and (max-width: 390px) {
    width: 100%;
    .AppointmentsContainer {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media only screen and (max-width: 480px) {
    width: 100%;

    .AppointmentsContainer {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .UpcomingAppointmentsContainer {
      padding-right: 5px;
      padding-left: 5px;
    }
    h3.ui.header.AppointmentsHeader {
      font-size: 30px;
      color: $dark-blue;
      line-height: 30px;
      font-family: $font-family;
      padding-bottom: 11px;
      padding-left: 15px;
    }

    .UpcomingAppointmentsInfoBold {
      font-size: 15px;
    }

    .UpcomingAppointmentsInfoFirst {
      font-size: 15px; 
    }
    .UpcomingAppointmentsInfoSecond {
      font-size: 15px; 
    }

    .RowContainerWithBottomBorder {
      padding-left: 0px;
    }

    .LastNextPhysicalContainer {
      width: 120px;
    }

    .LastNextPhysicalHeader {
      &.ui.header {
        padding-right: 0px;
        width: 120px;
      }
    }

    .LastNextPhysicalText {
      padding-right: 0px;
    }

    .RowContainerWithoutBottomBorder {
      padding-left: 0;
    }

    .ui.fluid.container.DonationSection {
      padding-left: 0;
      margin:0 !important;
    }

    .card {
      width: auto;
      padding-right: 0px;
      margin: -1rem;
      margin-bottom: 20px;
    }
    .BuddyBonusSection {
      width: 100%;
      padding-right: 0;
      .BuddyBonusDescription {
        font-size: 14px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .Disclaimer {
      display: flex;
  }
  .ui.form.UpdateAccountForm .FormField {
    margin-bottom: 20px !important;
  }
   .Disclaimer img {
    width: 16px;
    height: 18px;
    margin-top: 6px;
  }
}
  
}