@import '../../Theme/CommonStyles/variables.scss';

.password-validation {
    &__title {
        color: $gray;
        font-family: $font-family;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
      }
      &__list {
        color: $gray;
        font-family: $font-family;
        font-size: 12px;
        letter-spacing: 0.08px;
        line-height: 13px;
        max-width: 600px;
        margin-bottom: 40px;
        .Requirement {
          color: $green;
          margin-right: 10px;
        }
        .RequirementError {
          color: $orange;
          margin-right: 10px;
        }
      }
    margin-bottom: 20px;
    
    &__title, &__list  {
      &.AccountSetup {
        color: $white;
      }
    }
}
.AccountSetup {
  .password-validation {
    &__list {
      .Requirement {
        color: #e6c153;
      }
      .RequirementError {
        color: $orange;
      }
    }
  }
}