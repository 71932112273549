@import '../../../Theme/CommonStyles/variables.scss';

.ui.modal.massUpdateModal {
  margin: 4rem auto;
  .HeaderContainer {
    padding: 20.5px 25px 29.5px 25px;
  }
  h2.header.ModalHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }
  .ModalActions {
    padding-top: 30px;
    padding-bottom: 20px;
    .AppMassUpdate{
      margin: 0 20px;
    }
  }
  .TopLabelError {
    color: $color-validation-red;
  }
  img.ui.image {
    float: right;
    margin-top: 4px;
    cursor: pointer;
  }
}
.ui.page.dimmer {
  z-index: 9999;
}

.dayColumn {
  flex-direction: column;
  display: flex !important;
  align-items: center;
}
