@import '../../../Theme/CommonStyles/variables.scss';

.HorizontalCalendar {
  border-top: 1px solid #dddddd;
  padding-top: 30px;
  padding-bottom: 40px;
  .ui.circular.button {
    border-radius: 10em;
    background-color: transparent;
    font-size: 40px;
  }
  h2.header.MonthHeader {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 1.21px;
    line-height: 34px;
    text-align: center;
    padding-bottom: 20px;
    text-transform: capitalize;
  }
  .CalendarSlider {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 21.6px;
  }
  @media only screen and (max-width: 1330px) and (min-width: 1280px) {
    .CalendarSlider {
      padding-top: 40px;
    }
  }
  @media only screen and (max-width: 480px) {
    h2.header.MonthHeader {
      font-size: 20px;
      line-height: 24px;
    }
    .CalendarSlider {
      padding-left: 0;
      padding-right: 0;
      padding: 11.6px;
      max-width: 330px;
    }
    .ui.circular.button {
      font-size: 25px;
    }
  }
}
