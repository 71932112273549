@import '../../../Theme/CommonStyles/variables.scss';

.EffectiveSlotsForm {
  h4.header.FormHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  .FieldTitle {
    margin-bottom: 2px;
    color: $gray;
    font-size: 12px;
    letter-spacing: 2;
  }
  .FormDivider {
    background-color: $light-gray;
    width: 1px;
    margin: 0 20px 10px 10px
  }
  .FieldSpacer {
    line-height: 1.4285em;
    height: 10px;
    align-self: center;
    margin: 0 10px;
  }
  .SearchInput {
    margin-right: 17px;
  }
  .PrimarySelect {
    width: 222px;
  }
  .FormField {
    position: relative;
    small {
      position: absolute;
      top: 0;
      color: $color-validation-red;
      font-family: $font-family;
    }
    .TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 23px;
      top: 0;
    }
    .TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 23px;
      top: 0;
    }
  }
  .ui.button {
    margin-top: 20px;
    margin-left: 20px;
  }
}
