@import '../../Theme/CommonStyles/variables.scss';

.PageContent {
  .ui.grid > .TestimonalsTabDesktop {
    width: 100% !important;
  }
  .ui.grid > .TestimonalsTabMobile {
    width: 100% !important;
    padding: 0 !important;
    .TestimonalsTab {
      min-width: 520px;
      .ui.secondary.menu {
        .active.item {
          border-right-width: 3px;
          border-right-style: solid;
          background: transparent;
        }
      }
    }
    .ui.fluid.accordion {
      width: 100%;
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        i.icon {
          font-size: 16px;
          margin-right: 10px;
          color: $dark-blue;
        }
        h1 {
          margin: 0;
          padding: 0;
          font-size: 30px;
          line-height: 44px;
        }
      }
    }
  }
  .TestimonalsTab {
    .ui.secondary.menu {
      border-color: $light-gray;
      .active.item {
        color: $dark-pink;
        border-bottom-width: 3px;
        border-color: $dark-pink;
      }
      .active.item:hover {
        text-decoration: none;
      }
      .item {
        font-family: $font-family;
        font-size: 20px;
        color: $light-blue;
        font-weight: $font-bold;
        letter-spacing: 0;
        line-height: 29px;
        text-decoration: none;
        border-color: $light-gray;
      }
    }
    .ui.attached.segment {
      background: transparent;
      border: none;
      padding: 10px 0;
    }
  }
}
