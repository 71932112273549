@import '../../Theme/CommonStyles/variables.scss';

.ui.breadcrumb.Breadcrumbs {
  color: $light-green;
  font-family: $font-family;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  .Homelink {
    color: $light-green;
    text-decoration: underline;
  }
  .CurrentPage {
    color: $white;
    text-decoration: underline;
  }
  .Divider {
    color: $light-green;
  }
  .DarkBlueCurrentPage {
    color: $dark-blue;
    text-decoration: underline;
  }
  .LightBlueParentLink {
    color: $light-blue;
    text-decoration: underline;
    font-weight: normal;
  }
  .BlueDivider {
    color: $light-blue;
  }
}
