@import '../../../Theme/CommonStyles/variables.scss';

.CenterNoSelected {
  h2.header.MainHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }
  .CenterSelectionContainer {
    padding: 29px 45px 34px 45px;
    background-color: $white;
    margin-top: 22px;
  }
  .ui.dropdown.CenterDropdown {
    // width: 198px;
    background-color: transparent;
    color: $dark-blue;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 34px;
    margin: 0;
    padding: 0;
    font-weight: bold;
    letter-spacing: 0;
    border: none;
    i.icon {
      line-height: 38px;
      float: right;
      color: $dark-blue;
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 520px) {
    // Reducing the padding for 'choose a Donation center' label in small screen
    .CenterSelectionContainer{
      padding-left: 10px;
      padding-right: 10px;
    }
  } 
}
