@import '../../../Theme/CommonStyles/variables.scss';

.PrimaryTextArea {
  position: relative;
}
textarea::-webkit-input-placeholder {
  color: $gray !important;
}
.Required {
  position: absolute;
  z-index: 9;
  right: 0;
  top: 6px;
  color: $dark-pink;
}
