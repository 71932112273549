@import '../../../Theme/CommonStyles/variables.scss';

.DashboardGiftCard {
  font-family: $font-family;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;

  .continueButton {
    background-color: $blue;
  }

  .filterType {
    i.icon {
      float: right;
    }
  }

  .PaginationContainer {
    padding: 28px 49px 30px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .PageText {
      color: $dark-blue;
      padding-right: 30px;
      font-weight: $font-bold;
    }

    .ui.pagination.menu {
      text-align: center;
      min-height: 30px;
    }

    .ui.pagination.menu .active.item {
      background-color: transparent;
      color: $dark-blue;
      font-weight: bold;
      border: none;
    }

    .ui.secondary.menu .item {
      outline: none;
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0.62px;
      line-height: 21px;
      min-width: 30px;
      padding: 0;
      margin: 0;
    }

    .ui.secondary.menu .dropdown.item:hover,
    .ui.secondary.menu .link.item:hover,
    .ui.secondary.menu a.item:hover {
      background-color: transparent;
    }
  }

  .GiftCardFilter {
    color: #484848;
    font-size: 14px;
    line-height: 21px;
    padding-top: 20px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 20px;
  }

  .TransactionEntries {
    color: #484848;
    font-size: 14px;
    line-height: 21px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 20px;
  }

  .EntriesHeader {
    font-size: 14px;
    font-weight: bold;
    color: #484848;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #dddddd;
  }

  .EntriesContent {
    font-size: 19.6px;
    line-height: 25.2px;
    padding-top: 15px !important;
    padding-bottom: 25px !important;
    border-bottom: 1px solid #dddddd;
  }

  .EntryAmount {
    color: #009e8f;
    font-weight: bold;
    font-size: 22.4px;
  }

  input[type='text'] {
    border: none !important;
    background-color: #ffffff !important;
  }

  .GridBorder {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px !important;
    padding-top: 10px !important;
    display: flex;
    justify-content: space-between;
  }

  .DateRangeFilter {
    display: flex;
    justify-content: space-between;
  }

  .FilterText {
    padding-bottom: 5px;
  }

  .EntriesContentNoBorder {
    font-size: 19.6px;
    line-height: 25.2px;
    padding-top: 15px !important;
    padding-bottom: 5px !important;
  }

  .ToText {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 3px;
  }

  .CalendarIcon {
    font-size: 2em;
    padding-right: 5px;
    padding-top: 3px;
    color: #009e8f;
  }

  .GiftCardPortalButton {
    padding-top: 22px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
  .NoDataGiftCardPortalButton {
    padding-top: 22px;
    padding-bottom: 10px;
    padding-left: 20px;
  }


  .ui.secondary.menu .item {
    font-size: 14px;
    font-weight: bold;
    font-family: $font-family;
    color: #6d76a7 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ui.pagination.menu .item {
    min-width: 2em !important;
    background: none !important;
  }

  .ui.pagination.menu .active.item {
    min-width: 2em !important;
  }

  .ui.pagination.menu .item:focus {
    outline: none;
  }

  .ui.secondary.menu .active.item {
    color: #1f237a !important;
    font-size: 14px;
    font-weight: bold;
    font-family: $font-family;
    background: none !important;
  }

  .ui.pagination.menu {
    vertical-align: unset !important;
  }

  .PageText {
    color: #1f237a;
    padding-right: 40px;
    font-weight: bold;
    padding-left: 30px !important;
  }

  .FilterByDateText {
    font-weight: bold;
    font-size: 16x;
    letter-spacing: 1px;
    padding-right: 7px;
  }

  .ClearFilterText {
    color: #009e8f;
    font-size: 14px;
    font-weight: normal;
    padding-top: 3px;
    padding-left: 15px;
    cursor: pointer;
  }

  .xIcon {
    font-size: 1em;
    padding-right: 5px;
    padding-top: 4px;
    padding-left: 30px;
  }

  .Pagination {
    padding-top: 15px !important;
    border-top: 1px solid #dddddd;
  }

  .NoData {
    border-top: none;
  }

  .PaginationText {
    color: #1f237a;
    font-weight: bold;
    padding-top: 10px !important;
    font-size: 14px;
    line-height: 21px;
  }


  .ui.menu .item {
    color: #1f237a !important;
  }

  input[type='text'] {
    height: 30px !important;
    text-align: left;
    border-radius: 5px;
    width: 100px;
    font-size: 18px;

    &::placeholder {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 800px) {
    .DateRangeFilter {
      flex-direction: column;
    }

    .GiftCardFilter {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    .GridBorder {
      flex-direction: column;
    }
    .PaginationContainer {
      margin-left: -11px;
    }
    .xIcon {
      padding-left: 3px;
      padding-bottom: 45px;
    }
  }

  @media only screen and (max-width: 480px) {
    h4.ui.header.LabelHeader {
      font-size: 9px !important;
    }

    h1.ui.header.LeftTextHeader {
      font-size: 20px !important;
    }

    h1.ui.header.RightTextHeader {
      font-size: 9px !important;
    }

    .EntriesHeader {
      font-size: 9px;
    }

    .EntriesContent {
      font-size: 12.6px;
    }

    .EntryAmount {
      font-size: 15.4px;
    }

    .EntriesContentNoBorder {
      font-size: 12.6px;
    }

    .FindLocations {
      .PrimaryButton {
        width: 90%;
      }
    }

    .GiftCardFilter {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    .GridBorder {
      flex-direction: column;
    }

    .TransactionEntries {
      padding-left: 0px !important;
    }

    .DateRangeFilter {
      flex-direction: column;
    }
    .GiftCardPortalButton {
      padding-left: 0;
      margin-left: -15px;
    }
    .NoDataGiftCardPortalButton {
      padding-left: 0;
    }
    .PaginationContainer {
      margin-left: -40px;
    }
    input[type='text'] {
      width: 140px;
    }
  }

  .WarningModal {
    max-width: 700px;
    margin-top: 40px;
  }

  .RegularText {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 0px;
  }

}