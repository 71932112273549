@import '../../Theme/CommonStyles/variables.scss';

.UserVerification {
  background-color: #ebedf1;
  // background-image: url('../../../assets/media/images/cta-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 144px;
  .HeroImageContainer {
    padding-bottom: 74px;
    img {
      width: 100%;
    }
  }
  .UserVerificationTextContainer {
    max-width: 680px;
    margin: auto;
    text-align: center;
    .ErrorContainer {
      padding-top: 100px;
      padding-bottom: 30px;
    }
    .Primary{
      min-width: 225px;
      width: fit-content;
      padding: 0 30px !important;
    }
   
  h1.ui.header.UserVerificationHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
  }
  .UserVerificationRegularText {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    padding-bottom: 10px;
    span {
      font-weight: bold;
    }
  }
  .ui.list > .item {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    padding-bottom: 15px;
  }
  .LinkTo {
    color: $dark-pink;
    text-decoration: underline;
  }
  .AppAdvertisement{
    padding: 50px;
  }
}

}