@import '../../../Theme/CommonStyles/variables.scss';

.OpsCalendar {
  border-bottom: 1px solid #dddddd;
  padding-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  .ui.circular.button {
    border-radius: 10em;
    background-color: transparent;
    font-size: 40px;
  }
  h2.header.MonthHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 1.21px;
    line-height: 34px;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  .CalendarSlider {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 21.6px;
  }
  .DayLabel {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.67px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
  }
  .ui.label.DayNumberLabel {
    color: $gray;
    font-family: $font-family;
    font-size: 34px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    opacity: 1;
    font-weight: normal;
    background-color: transparent;
  }
  .ui.label.DayNumberLabelSelected {
    color: $white;
    font-family: $font-family;
    font-size: 34px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    opacity: 1;
    font-weight: normal;
    background-color: $dark-blue;
  }
  @media only screen and (max-width: 1330px) and (min-width: 1280px) {
    .CalendarSlider {
      padding-top: 40px;
    }
  }
}
