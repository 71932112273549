@import '../../../Theme/CommonStyles/variables.scss';

.NoResultsCalendar {
  .MessageContainer {
    padding: 23px 30px 26px 30px;
    .RedArea {
      height: 258px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light-red;
      .ErrorMessage {
        color: $dark-pink;
        font-family: $font-family;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
  }

  .ResultsActions {
    border-top: 1px solid #d4d4d4;
    padding: 23px 30px 26px 30px;
  }
}
