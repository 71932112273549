@import '../../../Theme/CommonStyles/variables.scss';

.HeroAppointmentScheduling {
  min-height: 125px;
  background-color: $dark-blue;
  padding: 40px 6% 40px 6%;
  .ui.header.MainHeader {
    color: $white;
    font-family: $font-family;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0;
  }
  @media only screen and (max-width: 640px) {
    width: auto;
    min-height: 140px;
    .ui.header.MainHeader {
      line-height: 30px;
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 480px) {
    width: auto;
    .ui.header.MainHeader {
      line-height: 25px;
      font-size: 24px;
    }
  }
}
