@import '../../../Theme/CommonStyles/variables.scss';

.DonorsDetails {
  font-family: $font-family;
  margin-bottom: 30px !important;
  .HeaderText {
    font-size: 24px !important;
    line-height: 30px;
    color: $dark-blue;
    padding-bottom: 20px;
    margin-left: 30px !important;
    padding-top: 10px;
  }

  .HeaderBar {
    justify-content: space-between !important;
  }

  .BackToSearchButton {
    height: 30px;
    color: $color-orange;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }

  .BackToSearchButton:hover {
    color: $color-orange-hover;
  }
  .last-two-donations{
    padding-left: 35px;
  }

  .DonorsDetailsSection {
    border-bottom: 1px solid $light-gray;
    background-color: $white;
    table {
      tr {
        color: $gray;
        font-size: 16px;
        td {
          padding-bottom: 10px;
          &:first-child {
            width: 40%;
          }
        }
      }
    }
  }

  .DonorDetailsColumn {
    display: flex;
    flex-direction: column;
    flex: 1.5;
    margin-right: 20px;
    margin-Left: 30px;
  }

  .DonorCenterColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .DonorsDetailsPersonal {
    padding: 30px;
  }

  .DonorsDetailsCenter {
    background-color: #EBF7FA;
    padding: 30px;
  }

  .DonorsDetailsContent {
    margin-top: 0px;
    display: flex;
  }

  .DonationCenterTitle {
    line-height: 30px;
    letter-spacing: 0.9px;
    font-size: 12px;
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 0px;
    text-transform: uppercase;
  }

  .DonationCenterBigTitle {
    line-height: 40px;
    font-size: 38px;
    font-weight: $font-bold;
    color: $dark-blue;
    margin-bottom: 0px;
    padding-bottom: 30px;
  }
}
