@import '../../../Theme/CommonStyles/variables.scss';

.DonationCenterSearch {
  font-family: $font-family;

  .LeftContainer {
    background-color: #EBF7FA;
  }

  .LeftContent {
    padding-left: 30px;
    padding-top: 30px;
  }

  .DonationSearchImage {
    width: 100%;
    height: 100%;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .ui.container {
    .ui.grid {
      .column.DonationSearchImageContainer {
        padding: 0 !important;
        min-height: 220px;
      }
    }
  }

  h3.ui.header.CenterInformationHeader {
    font-size: 38px;
    color: $dark-blue;
    font-family: $font-family;
    padding-bottom: 30px;
  }

  .DonationCenterLabel {
    line-height: 30px;
    letter-spacing: 0.9px;
    font-size: 12px;
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 0px;
  }

  .DonationCenterName {
    padding-top: 0px;
    line-height: 30px;
    font-size: 38px;
    font-weight: bold;
    color: $dark-blue;
    margin-top: 0px;
  }

  .DonationCenterInfo {
    padding-top: 0px;
    line-height: 30px;
    font-size: 20px;
    color: $dark-blue;
    padding-bottom: 10px;
    opacity: 0.7;
    a {
      color: $dark-blue;
      border-bottom: 1px solid $dark-blue;
    }
  }

  .DonationHourTableHeader {
    margin-left: 50px !important;
    padding-top: 40px !important;
    padding-bottom: 12px !important;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #484848;
    border-bottom: 1px solid #DDDDDD;
  }

  .DonationHourTableRow {
    margin-left: 50px !important;
    font-size: 20px;
    line-height: 25.2px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    border-bottom: 1px solid #DDDDDD;
}

  .DayText {
    color: #1F237A;
  }

  h4.ui.header.OfficeHoursHeader {
    padding-top: 30px;
    padding-left: 30px;
    font-weight: bold;
    line-height: 30px;
    padding-bottom: 0px;
    font-size: 24px;
    color: $dark-blue;
  }

  .OfficeHoursContainer {
    padding-left: 30px;
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .CenterMoreDetailsContainer {
    background-color: $white;
    padding-bottom: 20px;
  }

  .ui.button {
    height: 52px;
    width: 200px;
    border-radius: 25px !important;
    color: #ffffff !important;
    font-family: 'trade-gothic-next' !important;
    font-size: 16px;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    text-align: center !important;
    border: none;
  }

  .ui.labeled.icon.button>.icon {
    background-color: inherit;
    right: 10px;
  }

  .Primary {
    background-color: $dark-pink !important;
  }
  
  .Teal {
    background-color: $color-orange !important;
  }

  .CenterDropdown {
    width: 150px;
    background-color: transparent;
    color: $dark-blue !important;
    font-size: 18px !important;;
    letter-spacing: 0;
    line-height: 34px;
    margin: 0;
    padding: 0;
    font-weight: bold;
    letter-spacing: 0;
    border: none;
    i.icon {
      line-height: 38px;
      float: right;
      color: $dark-blue !important;;
      font-size: 16px !important;;
      margin-left: 10px;
    }
  }


  @media only screen and (max-width: 480px) {
    h3.ui.header.CenterInformationHeader {
      font-size: 30px;
      color: $dark-blue;
      line-height: 30px;
      font-family: $font-family;
      padding-bottom: 11px;
      padding-left: 15px;
      padding-top: 20px;
    }
    .OfficeHoursContainer {
      font-size: 16px;
      padding-top: 30px;
    }
  }
}
