@import '../../Theme/CommonStyles/variables.scss';

.ui.grid > .row.SubFooter {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CopyRight {
  opacity: 0.5;
  color: $black;
  font-family: $font-family;
  font-size: 12px;
  letter-spacing: -0.07px;
  line-height: 15px;
}

.ui.image.GplayImgContainer {
  max-width: 98px;
  & img {
    max-height: 29.8px;
  }
}

.ui.image.AppStoreImgContainer {
  max-width: 98px;
  margin-left: 20px;
  & img {
    max-height: 29.8px;
  }
}

.ui.image.TakedaImgContainer {
  max-width: 83px;

  & img {
    max-height: 27.81px;
  }
}
.ui.image.biolife-logo{
  position: relative;
  top: -12px;
  max-width: 83px;
  & img {
    max-height: 40px;
  }
}
.ui.image.bandLogo {
  max-width: 83px;
  padding-bottom: 10px;
  & img {
    max-height: 40px;
  }
}

@media only screen and (max-width: 1330px) and (min-width: 1280px) {
  .ui.image.AppStoreImgContainer {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-icons {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
  }
  
}

@media only screen and (max-width: 415px) {
  .ui.image.GplayImgContainer {
    max-width: 176px;
    padding-top: 15px;
    display: block;
    margin: 0 auto;
    & img {
      width: 100%;
      max-height: 54px;
    }
  }

  .ui.image.AppStoreImgContainer {
    max-width: 176px;
    margin-left: 0px;
    padding-top: 15px;
    display: block;
    margin: 0 auto;
    // padding-bottom: 40px;
    & img {
      width: 100%;
      max-height: 53px;
    }
  }

  .ui.image.TakedaImgContainer {
    max-width: 176px;
    padding-bottom: 48px;
    & img {
      width: 100%;
      max-height: 59px;
    }
  }
  .ui.image.biolife-logo{
    top:-10px;
    max-width: 60px;
  }
  .ui.image.bandLogo {
    max-width: 55px;
    padding-bottom: 5px;
    & img {
      max-height: 59px;
    }
  }
  .CopyRight {
    padding-bottom: 20px;
    line-height: 16px;
  }
}

