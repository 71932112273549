@import "../../../Theme/CommonStyles/variables.scss";
.OptOutContainer {
  width: 500px;
  h4.ui.header.OptOutOfLoyaltyHeader {
    font-size: 24px;
    color: $dark-blue;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 30px;
  }
}
.HeaderContainer {
  padding: 20px !important;
  .OptOutHeading {
    font-weight: 100;
    font-size: 20px;
    color: #484848;
  }
  .CloseIcon {
    float: right;
    margin-right: 0 !important;
  }
}
.ModalContent {
  padding: 20px !important;
  .ModalText {
    font-size: 18px;
    color: $gray;
  }
  .OptOutGrid {
    margin: 0px;
    .CheckboxContainer {
      margin-top: 12px;
      display: inline-block;
      .CheckboxText {
        font-size: 18px;
        color: #484848;
        padding-left: 0;
        padding-right: 0;
        margin-left: -10px;
      }
      .CheckboxStyle {
        padding-top: 6px;
        padding-left: 2px;
        padding-right: 0;
      }
      .ui.checkbox :before {
        border-color: $blue;
        border-width: 2px;
      }
      .ui.checkbox input:checked ~ label:after {
        color: $bone-white;
        background-color: $blue;
      }
    }
    .ButtonsContainer {
      margin-top: 18px;
      margin-bottom: 12px;
      justify-content: space-between !important;
      padding-left: 30px;
      padding-right: 30px;
      margin:auto;
    }
    .OptOutErrorMessage{
      text-align: center;
      align-items:center;
      color:$dark-pink;
      margin: auto;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 992px) {
  .OptOutContainer {
    max-width: 100%;
    margin: auto;
  }
  .ButtonsContainer {
    flex-direction: column-reverse !important;
    align-items: center !important;
    height: 150px;
    margin-top: 20px !important;
    padding-left:0 !important;
    padding-right:0 !important;
    margin: auto;
    .PrimaryBtn {
      padding-top: 10px;
    }
  }
  .CheckboxText {
    padding-left: 4px !important;
    font-size: 18px !important;
  }
}
@media (max-width: 480px) {
  .ui.modal > .header {
    padding-right: 1rem !important;
  }
  .HeaderContainer {
    padding: 10px ;
    .CloseIcon {
      padding-bottom: 10px ;
    }
  }
  .ModalText {
    font-size: 16px;
    margin-top: 20px;
  }
  .CheckboxContainer {
    margin-top: 30px;
    .CheckboxStyle {
      padding-left: 0px ;
    }
    .CheckboxText {
      margin-left: 0px !important;
    }
  }
}
