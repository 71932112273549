@import '../../Theme/CommonStyles/variables.scss';

.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.ui.menu .active.item {
  background: transparent;
}
.LogoContainer{
  .ui.small.image{
    width: 100px;
  }
}

.mobileWebHamBurger {
  .contactUsIcon {
    padding: 0 5px 0 16px;

    img {
      width: 100%;
    }
  }
  .IconButtonMenu {
    padding-bottom: 15px !important;
  }
}

.ui.image.DonorIcon {
  width: 19px;
  margin-right: 10px;
  filter: invert(50%) sepia(76%) saturate(5407%) hue-rotate(1deg) brightness(100%) contrast(96%);
}
.ui.menu.NavBarMenu {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  height: 70px;
  .ui.active.PrimaryButton,
  .ui.PrimaryButton,
  .PrimaryButton {
    line-height: 40px !important;
    height: 40px;
  }
  .dropdown {
    .DropdownIcon {
      margin-left: 5px;
    }
    .visible.menu {
      top: 42px;
    }
  }
}
.ui.menu .item.NavBarMenuItem {
  color: $dark-gray;
  font-family: $font-family;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 50px;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}
.BorderLeft {
  border-left: 1px solid $light-gray;
  font-weight: normal !important;
}
.ui.menu .item > i.icon.DropdownIcon {
  margin-left: 10px;
}
.OpenAppModal{
   text-align: center !important;
  .OpenAppMsg{
    color: $dark-blue;
    font-family: $font-family;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 50px;
    font-weight: normal;
 }
  .OpenAppImage{
    display: flex;
    justify-content: center;
  }
  
  .app-open button{
  height: 50px;
  width: 216px;
  border-radius: 25px !important;
  color: $white;
  font-family: 'trade-gothic-next' !important;
  font-size: 14px;
  font-weight: bold !important;
  line-height: 50px !important;
  padding: 0 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: none;
  background-color: $dark-pink !important;
}

.app-open button:hover,
.app-open button:active,
.app-open button:focus{
  border-color: $blue !important;
  background-color: $blue !important;
  color: $white !important;
}
  
  .close-button{
    margin-top: 20px;
    color: $dark-blue;
    font-family: $font-family;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 50px;
    font-weight: normal;
    text-decoration: underline;
  }
}
.ui.page.modals.dimmer{
  background-color: rgba(0,0,0,0.50) !important;
}

// media query start here
// 1460px to 1390pc
@media only screen and (max-width: 1460px) and (min-width: 1390px) {
  .ui.menu .item.NavBarMenuItem.es-MX {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }

} 
//1390px to 1280px

@media only screen and (max-width: 1390px) and (min-width: 1280px) {
  .ui.menu .item.NavBarMenuItem {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ui.menu .item.NavBarMenuItem.es-MX {
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

//1279 to 1080px
@media only screen and (max-width: 1279px) and (min-width: 1080px) {
  .ui.menu .item.NavBarMenuItem {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ui.menu .item.NavBarMenuItem.es-MX {
    font-size: 12px;
    padding-left: 7px;
    padding-right: 7px;
  }
  .ui.menu.NavBarMenu {
    padding-left: 3%;
    padding-right: 3%;
  }

}

//less than 1160px
@media only screen and (max-width: 1160px) {
  .ui.menu .item.NavBarMenuItem {
    font-size: 12px;
    padding-left: 9px;
    padding-right: 9px;
  }
  .ui.menu .item.NavBarMenuItem.es-MX {
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
  }


  .ui.menu.NavBarMenu {
    padding-left: 2%;
    padding-right: 2%;
  }
  .ui.PrimaryButton.button.signUpBtn.active,
  .ui.PrimaryButton.button.signUpBtn{
    width: 125px;
  }
}
//less than 1180
@media only screen and (max-width: 1180px) {
  .ui.menu .item.NavBarMenuItem {
    padding-left: 6px;
    padding-right: 6px;
  }
  .ui.menu .item > i.icon.DropdownIcon{
    margin-left:5px
  }
}
//less than 1024
@media only screen and (max-width: 1024px) {
  .ui.menu .item.NavBarMenuItem {
    font-size: 11px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .ui.menu.NavBarMenu {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
}
//less than 640

@media only screen and (max-width: 640px) {
  .ui.menu.NavBarMenu {
    padding-left: 2%;
    padding-right: 0;
  }
  .mobileWebHamBurger{
    padding: 0 !important;
  }
  
}
