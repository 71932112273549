@import './variables.scss';
.MainContainer {
  padding-top: 35px;
  padding-left: 6%;
  padding-right: 6%;
  .VersionText {
    color: #555555;
    font-size: 11px;
    margin-top: 10px;
  }
  @media only screen and (max-width: 992px) {
    padding-top: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.MainContainer.CenterOpsContainer {
  padding-bottom: 200px;
}

@media only screen and (max-width: 640px) {
  .MainContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;

  @media only screen and (max-width: 767px) {
    padding: 0;
  }

  &__content {
    width: 585px;
    padding: 55px;
    background-color: $white;
  }
}

.MemberDashboard {
  @media only screen and (max-width: 992px) {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }
}
.pushable {
  overflow-x: inherit;
}

.pushable > .pusher {
  min-height: 100vh;
}

.InfoIcon {
  filter: invert(16%) sepia(79%) saturate(5936%) hue-rotate(12deg) brightness(84%) contrast(100%);
}
