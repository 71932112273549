@import '../../../Theme/CommonStyles/variables.scss';
.UnavailableContainer {
  padding-bottom: 200px;
  .UnavailableHeader {
    color: #1f237a;
    font-family: 'trade-gothic-next';
    font-size: 34px !important;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    padding-top: 5px;
    padding-bottom: 19px;
  }
  .UnavailableContent {
    background: #fff;
    padding: 30px;
  }
  .UnavailableContentText {
    line-height: 26px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .UnavailableContentLabel {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
  }
  .UnavailableContentLink {
    font-size: 20px;
    color: $dark-pink;
    text-decoration: underline;
  }
  .UnavailableContentSpace {
    margin-bottom: 20px;
  }
}

.MenuContent {
  .UnavailableContainer {
    .UnavailableContent {
      padding: 0;
      white-space: normal;
      overflow-wrap: anywhere;
    }
    .UnavailableContentLink {
      line-height: 26px;
    }
  }
}
