@import '../../../Theme/CommonStyles/variables.scss';

.ui.secondary.pointing.menu.SecondaryMenu {
  border: none;
}

.ui.secondary.pointing.menu {
  .item.SecondaryMenuItem {
    color: $dark-gray;
    font-family: $font-family;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &.vertical .item.SecondaryMenuItem {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.ui.secondary.pointing.menu .item.SecondaryMenuItem:hover {
  color: $dark-pink;
  text-decoration: underline;
}

.ui.secondary.pointing.menu .active.item.SecondaryMenuItem {
  color: $dark-pink;
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (max-width: 1390px) and (min-width: 1280px) {
  .ui.secondary.pointing.menu .item.SecondaryMenuItem {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .ui.secondary.pointing.menu .item.SecondaryMenuItem {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 1024px) {
  .ui.secondary.pointing.menu .item.SecondaryMenuItem {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1650px) {
  .ui.secondary.pointing.menu {
    .item.SecondaryMenuItem.es-MX.becomeDonor {
      max-width: 200px !important;
      line-height: 25px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}  

@media only screen and (max-width: 1450px) {
  .ui.secondary.pointing.menu {
    .item.SecondaryMenuItem.es-MX.becomeDonor {
      max-width: 180px !important;
      line-height: 25px;
      padding-left: 11px;
      padding-right: 11px;
    }
  }
}  

@media only screen and (max-width: 1350px) {
  .ui.secondary.pointing.menu {
    .item.SecondaryMenuItem.es-MX.becomeDonor {
      max-width: 180px !important;
      line-height: 25px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}  