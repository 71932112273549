@import '../../Theme/CommonStyles/variables.scss';

.NewsSection {
  background-image: url('./images/news-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-top: 200px;
  padding-bottom: 20px;
}
.NewsSectionHeader {
  color: $dark-blue !important;
  font-family: 'trade-gothic-next' !important;
  font-size: 36px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 45px !important;
  text-align: center;
  padding-bottom: 40px !important;
}
.NewsSectionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ui.grid.NewsSectionButtonContainer{
  padding-top: 30px;
  padding-bottom:40px;
}

@media only screen and (max-width: 992px) {
.NewsSection{
  padding-top: 0;
}
}
@media only screen and (max-width: 640px) {
  .NewsSection {
    background-size: 100% ;
    padding-top: 101px;
    .NewsSectionGrid{
      background-color: $yellow;
    }
    .NewsSectionButtonContainer{
      padding-bottom:20px;
      background-color: $yellow;
    }
  }
  .NewsColumn {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .ui.grid.NewsSectionButtonContainer{
    padding-bottom:40px;
  }
}
@media only screen and (max-width: 480px){
.NewsSection {
    background-size: 120%;
    .ui.grid+.grid {
      margin-top: 0rem; 
    }
}

}
