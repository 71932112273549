@import "../../../../Theme/CommonStyles/variables.scss";

.RewardsHistoryContainer{
    margin: 0;
    padding-bottom: 20px;
    .description{
        padding: 20px 5px;
        font-size: 22px;
        color: $gray;
    }
    .MonthlyDataContainer{
        .MonthTransationContainer{
            align-items: center;
            border-radius: 20px;
            margin: 20px 0;
            padding: 0 15px;
            background-color: $loyaltyLightGray;
            .RewardTypeDetails{
                display: flex;
                flex-direction: column;
                h2{
                    color: $loyaltyDarkGreen;
                    margin: 0 0 15px 0;
                }
            }
            .RewardPoints{
                h2{
                    color: $loyaltyDarkGreen;
                    margin: 0;
                }
                p{
                    display: none;
                }
            }
            .RewardIconStyles{
                background-color: $white;
                width: 80px; 
                height: 80px; 
                border-radius: 50%;
                display: flex;
                justify-content: center;
                padding: 10px;
              }
            .rewardIconImage{
                padding: 10px;
                object-fit: contain;
            }
        }
    }
    .norewardContainer {
        border-radius: 30px;
        background-color: $loyaltyLightGray;
        margin-bottom: 30px !important;
        padding: 25px;
      }
      .noRewardTitle {
        color:$loyaltyDarkGreen;
        font-size: 24px;
        font-weight: bold;
        padding-top: 10px;
        text-align: start;
    }
    .noRewardDesc {
        color: $gray;
        font-size: 16px;
        padding-top: 10px;
        text-align: start;
    }  
}


@media only screen and(min-width:1280px) and (max-width :1330px){
    .MonthlyDataContainer{
        margin-top:40px !important;
    .GridContainer{
        padding-bottom:20px;
    }
  .MonthTransationContainer{
    height:130px ;
    margin: 20px -6px !important;
     padding: 0 29px  !important;
    .RewardActivityDate {
        padding-left:13px;
        padding-top:6px;
    }
    .RewardIconContainer{
        margin-left:10px;
    }
    .RedemptionType{
    margin-top:-7px !important;
    }
  }
}
}

@media only screen and (max-width: 480px) {
    .RewardsHistoryContainer {
        .MonthlyDataContainer {
            .MonthTransationContainer{
                padding: 15px;
                align-items: flex-start;
                .column{
                    padding: 0;
                }
                .RewardTypeDetails{
                    p{
                        margin: 0;
                    }
                    .ui.stackable.grid {
                        margin-top: 1px;
                        .column {
                            padding: 0 14px !important;
                        }
                    }
                    .DateContainer{
                        padding-bottom: 15px;
                    }
                }
                .RewardPoints{
                    margin-top: 15px;
                    p{
                        display: block;
                    }
                }
            }   
        }
    }
.norewardContainer {
        margin: -1rem;
 }
}