@import '../../../Theme/CommonStyles/variables.scss';

.DonorDetailTabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #dddddd;
  padding-top: 0;
  list-style: none;
  padding-left: 0;

  .tabs {
    height: 100%;
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    margin-right: 40px;
    cursor: pointer;
  }

  .save {
    background-color: $blue !important;
  }

  .active {
    border-bottom: 4px solid $color-orange;
  }
}
