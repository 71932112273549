@import "../../../../Theme/CommonStyles/variables.scss";

.EditAccessManagementForm
{
    .EditInput{
      /* Layout Properties */
      top: 28px;
      left: 0px;
      width: 447px;
      /* UI Properties */
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 2px;
      opacity: 1;
      height: 50px;
      position: relative;
    }
    .rowSpaces:has(.field){
      top: 2.5em !important;
      position: relative;
    }
    .TopLabel{
        font-family: trade-gothic-next;
        line-height: 23px;
        font-size: 14px;
        top: 0px;
        left: 0px;
        width: 97px;
        height: 3px;
        /* UI Properties */
        text-align: left;
        letter-spacing: 0.6px;
        color: #484848;
        opacity: 1;
        position: relative;
        float: left;
    }

    .ui.selection.dropdown.Base.CreateFormSelect {
        width: 447px;
        height: 48px;
        margin-bottom: 10px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
        font-family: $font-family;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        .divider.default{
            color: rgba(191,191,191,.87) !important;
        }
        .divider{
          color: black !important;
        }
      }
      .ui.selection.dropdown.Base.CreateFormSelect.Error {
        border: 1px solid $color-validation-red;
        margin-bottom: 0px
      }
      .ui.input{
        height: 50px;
        font-size: 14px;
        input{
          border-radius: 0;
        }
      }
      .TopLabelError {
        color: $color-validation-red;
        height: 11px;
        text-align: left;
        font: normal normal 600 11px/7px Barlow;
        letter-spacing: 0.5px;
        opacity: 1;
        position: absolute;
        display: grid;
        top: 40% !important;
        left: 0px !important;
      }
      .ui.error.input{
        border: 1px solid $color-validation-red
      }
      .PrimarySelect{
        .ui.selection.dropdown.Base.Error .default.text {
          color: #EBCAC8 !important;
        }
        padding-bottom: 0px;
        top: 33px;
      }
      .ErrorIcon{
        top: 417px;
        left: 725px;
        width: 12px;
        height: 11px;
        opacity: 1;
      }
      .ui.icon.input>i.icon {
        width: 1.18em;
        font-size: .5em;
        top: 6px;
        opacity: 1;
        right: 3px;
        height: 1em;
      } 
      .save{
        top: 483px;
        left: 525px !important;
        width: 158px !important;
        height: 50px;
        /* UI Properties */
        background: #AC0079 0% 0% no-repeat padding-box;
        border-radius: 25px;
        opacity: 1;
        margin-top: 1.5%;
        font-size: 14px;
        margin-left: 2em;
        margin-top: 2em;
    }
      .cancel{
        margin-left: 20em;
        margin-top: 2em;
        width: 158px !important;
      }
      .errorMessage {
        width: 100%;
        display: block !important;
        top: 15px;
      }
      .ui.disabled.input{
        opacity: 1;
      }
      .submitBtnGroup{
        top: 3em;
        position: inherit;
      }
}