@import '../../../Theme/CommonStyles/variables.scss';

.DashboardPreferences {
    max-width: 95% !important;
}

@media only screen and (max-width: 1024px){
    .DashboardPreferences{
        margin: 0 auto;
    }
}