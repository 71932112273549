@import '../../../Theme/CommonStyles/variables.scss';

.HorizontalDonorSearchForm {
  padding: 29.5px 30px 17px 27px;
  background-color: $white-gray;
  h4.header.FormHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  .SearchInput {
    margin-right: 44px;
    margin-bottom: 5px;
  }
  .ui.button.FindDonor {
    margin-top: 20px;
  }
  .FormField {
    position: relative;
    padding-top: 23px;
    small {
      position: absolute;
      top: 0;
      color: #df49b2;
      font-family: $font-family;
    }
    .TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 23px;
      top: 0;
    }
    .TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 23px;
      top: 0;
    }
  }
}
