@import "../../../Theme/CommonStyles/variables.scss";

.BioLifeRewards {
  font-size: 48px !important;
  padding-bottom: 23px !important;
  color: #484848 !important;
  margin-left: -13px !important;
}
.LoyaltyGridWithButton,
.LoyaltyGridWithoutButton {
  border-radius: 30px;
  background-color: $loyaltyLightGreen;
  margin-bottom: 30px !important;
  margin-left: 10px;
  height: 200px;
  .LeftPart {
    padding: 20px 40px !important;
    display: inline-block;
    .RewardPointsText {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 10px;
      padding-left: 6px;
      color: #484848;
      min-height: 50px;
    }
    .RewardPoints {
      font-size: 90px;
      font-weight: bolder;
      color: #007065;
    }
  }
  .RightPart {
    padding: 25px 40px !important;
    text-align: right;
    .PrimaryBtn {
      margin-bottom: 10px !important;
      margin-top: 5px !important;
      margin-right: 0 !important;
    }
    .RedeemTextWithButton {
      margin-bottom: 3px;
      color: #222222;
      font-size: 16px;
    }
    .RedeemTextWithoutButton {
      padding-top: 37px;
      color: #222222;
      font-size: 16px;
    }
    .expirationDetailsNav {
      color: $dark-pink;
      text-decoration: underline;
      cursor: pointer;
    }
    .expirationText {
      font-size: 16px;
  }
  .expirationDetails {
    margin-bottom: 30px;
    padding: 3px;
  }
  }
}
@media (min-width:480px) and (max-width:768px){
  .LoyaltyGridWithButton, .LoyaltyGridWithoutButton{
    .LeftPart{
  .RewardPointsText{
    font-size:17px;
  }
    padding-right: 8px !important ;
}
   .RightPart{
    padding-left: 8px !important;
   }
 }
}
@media (max-width: 480px) {
  .eight.wide.computer.eight.wide.tablet.column.LeftPart{
     width:100% !important;
  }
  .eight.wide.computer.eight.wide.tablet.column.RightPart{
    width:100% !important;
  }
  .BioLifeRewards {
    margin-top: 30px !important;
    text-align: center;
    padding-bottom: 25px !important;
  }
  .LoyaltyGridWithButton,
  .LoyaltyGridWithoutButton {
    height: 360px;
    .GridRow{
      flex-direction: column ;
      text-align: center ;
      align-items: center;
    .LeftPart {
     box-sizing: border-box;
      padding-top: 28px !important;
      text-align: center;
     padding-left: 0 !important;
      padding-right: 0 !important;
      .RewardPointsText {
        padding-left: 0;
      }
    }
    .RightPart {
      text-align: center;
      padding-left: 0 !important;
     padding-right: 0 !important;
      padding-top: 10px !important ;
      .PrimaryBtn {
        margin-right: 0 !important;
      }
      .RedeemTextWithButton {
        margin: 0 !important;
        margin-top: 5px !important;
        font-size: medium;
        padding: 3px;
      }
      .RedeemTextWithoutButton {
        padding: 0 !important;
       text-align: center;
        margin: 0 !important;
        font-size: 17px;
      }
    }
    .LoyaltyTabsGrid {
      margin-left: 0px;
      padding-top: 0px !important;
    }
  }
  .LoyaltyGridWithoutButton {
    height: 250px !important;
  }
}
}
@media (max-width: 400px) {
  .BioLifeRewards {
    font-size: 40px !important;
  }
}
