@import '../../../Theme/CommonStyles/variables.scss';

.HorizontalCalendar {
  border-top: 1px solid #dddddd;
  padding-top: 30px;
  padding-bottom: 40px;
  .ui.circular.button {
    border-radius: 10em;
    background-color: transparent;
    font-size: 40px;
  }
  h2.header.MonthHeader {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 1.21px;
    line-height: 34px;
    text-align: center;
    padding-bottom: 20px;
    text-transform: capitalize;
  }
  .CalendarSlider {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 21.6px;
    display: inline;
    text-align: center;
    .CalendarDays{
      display: flex;
      justify-content: center;
    }
  }
  .CalendarSliderChevron {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .SelectYourDate {
    color: $gray;
    font-family: $font-family;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: #484848;
    text-transform: uppercase;
    word-wrap: break-word
  }
  @media only screen and (max-width: 1330px) and (min-width: 1280px) {
    .CalendarSlider {
      padding-top: 40px;
    }
  }
  @media only screen and (max-width: 768px) {
    .CalendarSlider {
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 21.6px;
    }
  }
  @media only screen and (max-width: 480px) {
    h2.header.MonthHeader {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 24px;
      margin-top:  0px;
      margin-bottom: 0px;
    }
    .SelectYourDate {
      font-size: 15px;
      padding-bottom: 8px;
      margin-bottom: 0px;
    }
    .CalendarSlider {
      padding-left: 0;
      padding-right: 0;
      padding: 11.6px;
      max-width: 100%;
    }
    .CalendarSlider > .ui.grid > .twelve.column {
      padding: 1rem 0;
      .ui.container > .ui.grid > .column {
        padding: 1rem 0;
        .ui.container > .ui.grid {
          margin: -1rem 0;
          .column {
            display: flex;
            justify-content: center;
          }
        }
      }
      .ui.container {
        .column > .ui.container {
          margin: 0 !important;
          button {
            margin: 0;
          }
        }
      }
    }
    .ui.circular.button {
      font-size: 25px;
      padding: 0;
      margin: 0;
    }
  }
}
