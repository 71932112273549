@import '../../../../Theme/CommonStyles/variables.scss';

.AccessManagementCreate{
    
        /* Layout Properties */
        top: 117px;
        left: 161px;
        width: 1078px !important;
        height: 493px;
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
}

