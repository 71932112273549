@import '../../../Theme/CommonStyles/variables.scss';

.DashboardTopBanner {
  height: auto;
  background-color: $dark-blue;
  padding: 72px 6% 80px 6%;
  .ui.header.SupHeader {
    color: $yellow;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .SupHeaderLoading {
    height: 50px;
    position: relative;
  }
  .SupHeaderLoading .loader {
    left: auto;
    right: 0;
  }
  .ui.header.SupHeaderUnavailable {
    color: $light-blue;
    font-family: $font-family;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 25px;
    margin-top: 0px;
    opacity: 0.5;
  }
  .ui.header.MainHeader {
    color: $white;
    font-family: $font-family;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0;
  }
  .rewardsPointsSection {
    .ui.grid {
      display:flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 111px;
      padding-top: 10px;
    }
  }
  @media only screen and (max-width: 415px) {
    .ui.header.MainHeader {
      font-size: 18px;
      line-height: 22px;
    }
    .ui.header.SupHeader {
      line-height: 16px;
    }
  }
  @media only screen and (max-width: 480px) {
    .ui.header.SupHeaderUnavailable {
    font-size: 13px;
    line-height: 15px;
    margin-top: 10px;
    }
    .DashboardTopBanner {
      padding: 50px 30px;
    }
    .ui.header.MainHeader {
      font-size: 18px;
    }
    .ui.header.SupHeader {
      font-size: 11px;
      line-height: 15px;
    }
    .rewardsPointsSection {
      .ui.grid {
    flex-direction: column-reverse;
        gap: 0px;
      }
    }
  }
}
