@import '../../../Theme/CommonStyles/variables.scss';

.UpdatePasswordForm {
  width: 470px;
  padding-bottom: 3%;

  h4.ui.header.SectionHeader {
    font-weight: bold;
    line-height: 30px;
    padding-bottom: 5px;
    font-size: 24px;
    color: $dark-blue;
  }
  .FormField {
    position: relative;
    padding-top: 18px;
    margin-bottom: 32px !important;
    small {
      position: absolute;
      top: 0;
      color: $dark-pink;
      font-family: $font-family;
      font-size: 12px;
    }
    small.TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0.6px;
      line-height: 6px;
      top: 0;
    }
    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 95% !important;
    }
    .ErrorMsgContainer .InfoIcon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  .ErrorMsgContainer .ErrorMsg {
      padding-top: 2px;
  }
  }
  .RequiredInfo {
    color: $gray !important;
  }
  .MessageButtonContainer {
    padding-top: 3px;
    padding-bottom: 24px;
  }
  .ui.form .fields.ButtonContainer {
    margin-bottom: 23px;
  }
  .PrimaryInput .ui.input > input {
    border-radius: 4px;
  }
  .PasswordRequirements {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }
  .ui.list.RequirementsList {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0.08px;
    line-height: 13px;
    max-width: 380px;
    margin-bottom: 37px;
    .Requirement {
      color: $green;
    }
    .RequirementError {
      color: $dark-pink;
    }
  }
  .ErrorText {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 0;
    margin-top: 3px;
  }
  .ErrorsContainer {
    padding-bottom: 32px;
  }
  @media only screen and (max-width: 480px)  {
    small.TopLabelError{
      top:-10% !important;
    }
    h4.ui.header.SectionHeader {
      font-size: 22px;
    }
  }
  
}

