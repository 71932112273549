@import '../../../Theme/CommonStyles/variables.scss';

.ui.modal.CenterOpsModifyAppointmentModal {
  margin-top: 40px;
  width: 1232px;
  .HeaderContainer {
    padding: 20.5px 25px 29.5px 25px;
  }
  .content {
    padding: 0;
  }
  h1.header.RescheduledHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-bottom: 60px;
  }
  h2.header.ModalHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }
  .MyAppointment {
    padding-top: 30px;
    h3.header.HeaderText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-bottom: 0px;
    }
    .RegularText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-bottom: 0px;
    }
    .ModalActions {
      padding: 41px 6%;
      .ConfirmContainer {
        padding-bottom: 32px;
        padding-top: 32px;
      }
    }
    .CancelAppointment {
      background-color: $color-orange !important;
    }
    .ui.loader {
      &:before {
        border: .2em solid rgba(0,0,0,.1)
      }
      &:after{
        border-color: #767676 transparent transparent;
      }
    }
  }
  img.ui.image {
    float: right;
    margin-top: 4px;
    cursor: pointer;
  }
  .HorizontalCalendar .CalendarSlider {
    padding-left: 0px;
    padding-right: 0px;
  }
  .CenterSelection {
    width: 938px;
  }
  .HoursContainer {
    width: 850px;
    margin: auto;
  }
  .SelectedDate {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-top: 48px;
  }

  .Playroom {
    padding-top: 4.51px;
    padding-bottom: 31.34px;
    text-align: center;
  }
  .PlayroomContainer {
    width: 500px;
    margin: auto;
  }
  .LabelForSelect {
    color: $dark-gray;
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 3px;
  }
  .HugeSelect {
    .ui.selection.dropdown.Base {
      margin: auto;
    }
  }
}
.ui.page.dimmer {
  z-index: 9999;
}
