@import '../../../Theme/CommonStyles/variables.scss';
.ui.form.UpdateAccountForm {
  max-width: 650px;
 .ui.selection.dropdown.Base {
  height: 50px;
  border-radius: 4px;
}
  .TopLabel {
    position: absolute;
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 13px;
    top: 0;
  }
  .customselectfield {
    margin-right: -5px;
    width: 0px;
    margin-left: 10px;
    font-size: 12px;
    margin-top: -4px;
    color:black;
}
  .TopLabelError {
    position: absolute;
    color: $color-validation-red;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 13px;
    top: 0;
  }
  .FormNote {
    font-size: 13px;
    margin-bottom: 24px;
  }
  .RequiredInfo {
    color: $color-validation-red;
  }
  .MessageButtonContainer {
    padding-left: 10px;
    padding-top: 3px;
  }
  .FormField {
    position: relative;
    padding-top: 18px;
    margin-bottom: 20px;
    small {
      position: absolute;
      top: 0;
      color: $dark-pink;
      font-family: $font-family;
      font-size: 12px;
    }
    small.TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 0;
    }
    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 93%;
    }
    .error {
      input {
        border-color: $color-validation-red;
        color: $color-validation-red;
        box-shadow: none;
        font-size: 12px;
        border-radius: 4px;
      }
    }
  }
  .SuccessContainer{
    margin-bottom: 15px;
    font-family: $font-family;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 30px;
    color: $dark-pink;
  }
  .FailureStateContainer{
    margin-bottom: 15px;
  }
  .AlertBox {
    box-sizing: border-box;
    max-width: 95vw;
    border: 1px solid $color-validation-red;
    background-color: #fff4fc;
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 18px 14px 27px 29px;
    margin-bottom: 17px;
    span {
      font-weight: bold;
    }
  }
  .NonEditableTextContainer {
    display: flex;
    margin-left: 51%;
    padding-bottom: 10px;
    margin-top: -25px;
  
    .GrayInfoIcon {
      margin-right: 8px;
    }
    .NonEditableText{
      color:$gray;
      font-size:13px;
    }
  }
  .Ethnicitycustomselectfield{
    margin-right: -5px;
    margin-bottom: -15px;
    font-size: 12px;
    margin-top: -4px;
    color:black;
  }
  .enthnicityNonEditableTextContainer{
    display: flex;
    margin-top: -25px;
    padding-bottom: 20px;
    .GrayInfoIcon {
      margin-right: 8px;
    }
    .NonEditableText{
      color:$gray;
      font-size:13px;
    }
  }
  .PrimaryInput .ui.input > input {
    border-radius: 4px;
  }
  @media only screen and (max-width: 480px) {
    .ui.selection.dropdown.Base.AccountFormLargeSelect.largerSelect{
      height: auto;
      .divider.text{
        width: 90%;
    }
  }
}
  @media only screen and (max-width: 480px) {
  .NonEditableTextContainer {
    display: flex;
    justify-content: initial;
    margin-top: -17px;
    margin-left: 0px !important;
    padding-bottom: 20px;
    .GrayInfoIcon {
    width: 18px;
    height: 18px;
    }
  }
  .enthnicityNonEditableTextContainer .GrayInfoIcon {
    width: 18px;
    height: 18px;
  }
 .customselectfield {
  margin-bottom: -5%;
 }
}
@media only screen and (max-width: 360px) {
  .NonEditableTextContainer {
    margin-top: -35px;
  }
  .enthnicityNonEditableTextContainer {
    margin-top: -35px;
  }
}
  
}

@media only screen and (max-width: 360px) {
  .DonorInformation .ui.form.UpdateAccountForm .FormField {
margin-bottom: 30px !important;
  }
}
