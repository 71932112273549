@import '../../Theme/CommonStyles/variables.scss';

.findCenterContainer,.spanishFindCenterContainer {
  background-color: #d8dbfc;
  text-align: center;
  padding: 0px 20px;

  .icon.big.markerIcon {
    color: $dark-blue;
    margin-bottom: 25px;
  }

  h1 {
    color: #1f247a;
    font-family: "trade-gothic-next";
    font-size: 3.125em;
    font-weight: 400;
    display: inline-block;
    font-weight: normal;
    letter-spacing: -0.19px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  p {
    color: #1f247a;
    font-family: "trade-gothic-next" ;
    font-size: 1.25em;
    letter-spacing: 0;
    line-height: 30px ;
    text-align: center ;
    margin-bottom: 25px;
  }
  p:empty {
    display: none;
  }
  .ui.form.HeroSearchForm {
    display: inline-block;
    margin-bottom: 20px;
  }
  .HeroSearchForm .equal.width.fields{
    justify-content: center;
  }
  .HeroSearchPrimaryButton {
    outline: 1px solid $dark-pink;
      background-color: white !important;
      color: $dark-pink !important;
    &:disabled {
      background-color: white !important;
      color: #AC0079!important;
      outline: 1px solid #AC0079 !important;
    }
  }
}
@media screen and (min-width: 375px)  {
  .findCenterContainer{
    padding: 0px 10px
  }
}
@media screen and (max-width: 540px) {
  .findCenterContainer{
    .icon.big.markerIcon {
      margin-top: 25px;
      margin-bottom: 8px;
    }
    h1{
      font-size: 40px;
      margin-top: 0px;
    }
   .HeroSearchPrimaryButton{
     width: 190px !important;
     font-size: 16px !important;
   }
  }
  .spanishFindCenterContainer{
    .icon.big.markerIcon {
      margin-top: 25px;
      margin-bottom: 8px;
    }
    h1{
       font-size: 33px;
       margin-top: 0px;
    }
    .HeroSearchPrimaryButton{
       width: 190px !important;
       font-size: 16px !important;
      }
    }
  }
@media screen and (max-width: 368px){
  .findCenterContainer{
    h1{
      font-size:33px;
    }
  }
  .spanishFindCenterContainer{
    h1{
      font-size: 27px;
    }
  }
}
