@import '../../../Theme/CommonStyles/variables.scss';

.DonorSearch {
  font-family: $font-family;

  .ui.grid > .row.ActionsRow {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  h4.ui.header {
    font-size: 24px;
    line-height: 40px;
    color: #1f237a;
  }
  .FormField {
    position: relative;
    width: 100%;
    .TopLabelError {
      color: $color-validation-red;
    }
  }
  .ActionsRow {
    margin-bottom: 20px;
  }
}
