@import '../../Theme/CommonStyles/variables.scss';

.FacilityDetails {
  padding-top: 61px;
  padding-bottom: 74px;
  .OurCenterImage {
      h1.ui.header.ImageTitle {
        color: #1F237A;
        padding-top: 40px;
        padding-bottom: 17px;
        font-size: 38px;
        line-height: 40px;
        font-family: "trade-gothic-next";
      }
        .UniqueImageContainer {
          .image {
             height: 95%;
             width: 95%;
           }
         }
  
      .ImageDescription {
        overflow-wrap: break-word;
        padding-top: 16px;
        width: 95%;
      }
    }
  .DifferHours {
    margin-top: 15px;
    padding-left: 15px;
    font-size: 16px;
  }
  h2.ui.header.SecondaryHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 5px;
  }
  .HoursContainer, .PlayroomContainer{
    max-width: 320px;
    .ui.grid .row {
      padding: 0
    }
  }
  .PlayroomContainer {
    margin-top: 60px;
  }
  .AmenitiesContainer {
    margin-top: 40px;
  }
  .WeekDay {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 40px;
  }
  .Hours {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 40px;
  }
  .MapContainer {
    width: 386px;
    .Map {
      i.map.marker {
        margin: -16px 0 0 -10px;
        vertical-align: top;
      }
    }
    img {
      width: 100%;
    }
    .ContactInfo {
      padding-top: 20px;
      .Address {
        color: $dark-blue;
        font-family: $font-family;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 0;
      }
      .Phone {
        color: $dark-pink;
        font-family: $font-family;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 30px;
        border-bottom: 1px solid $dark-pink;
      }
    }
  }
  .AmenitiesContainer {
    ul {
      padding-left: 25px;
      li {
        color: $gray;
        font-family: $font-family;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 40px;
      }
    }
    .Note {
      color: $gray;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
    @media screen and (max-width: 767px) {
      .OurCenterImage {
        h1.ui.header.ImageTitle {
          padding-top: 50px;
          padding-bottom: 0px;
        }
        .UniqueImageContainer {
          .image {
            height: 100%;
            width: 100%;
          }
        }
        .ImageDescription{
          width: 100%;
        }
      }
    }
}
