@import "../../../../../Theme/CommonStyles/variables.scss";
.ui.tiny.image {
  width: 110px !important;
}
.SliderContainer {
  position: relative;
  .TestimonialCard {
    border-radius: 20px;
    background-color: $dark-blue;
    color: $white;
    margin-bottom: 40px;
    margin-top: 40px;

    .Testimonial {
      width: 80% !important;
      display: flex !important;
      margin: 0px auto;
      padding: 20px 90px;
      align-items: center;
      justify-content: center;
      text-align: center;
      .TestimonialHeading {
        font-size: 26px;
        font-weight: bold;
        padding: 30px;
      }
      .PatientTestimonial {
        font-size: 20px;
        line-height: 32px;
      }
      .PatientName {
        font-size: 16px;
        padding-bottom: 3px;
        padding-top: 3px;
        font-weight: bold;
      }
      .PatientDescription {
        padding-top: 3px;
        font-size: 15px;
        padding-bottom: 25px;
      }
      .PatientImage {
        padding-top: 18px;
        padding-bottom: 8px;
      }
    }
  }
  .ArrowContainer {
    bottom: 30%;

    .SlickArrow {
      color: $white;
      background-color: transparent;
      border: 0px;
    }
    .LeftArrow {
      position: absolute;
      bottom: 32%;
      left: 10%;
    }
    .RightArrow {
      position: absolute;
      bottom: 32%;
      right: 10%;
    }
  }
}

@media (min-height: 600px) and (max-width: 480px) {
  .SliderContainer {
    .TestimonialCard {
      height: 745px;

      .Testimonial {
        width: 100% !important;
        padding: 20px;

        .TestimonialHeading {
          padding: 30px 0px;
          font-size: 22px;
        }
        .PatientName {
          padding-top: 8px;
        }
        .PatientDescription {
          font-size: 13px;
          padding: 0;
        }
      }
    }

    .ArrowContainer {
      .LeftArrow {
        bottom: 5%;
        left: 30%;
      }
      .RightArrow {
        bottom: 5%;
        right: 30%;
      }
    }
  }
}
@media (min-height: 844px) and (max-width: 480px) {
  .TestimonialCard {
    height: 690px;
  }
}
@media (min-height: 600px) and (max-width: 340px) {
  .TestimonialCard {
    height: 830px !important;
  }
}
