@import '../../../Theme/CommonStyles/variables.scss';
.ModifycenterSlotsFailureContainer {
  margin-bottom: 200px;
}

.ui.modal.ModifyAppointmentModal {
  max-width:95vw;
  margin: auto;
  margin-top: 40px;
  width: 1360px;
  .HeaderContainer {
    padding: 20.5px 25px 29.5px 25px;
  }
  .content {
    padding: 0;
  }
  h2.header.ModalHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }
  .MyAppointment {
    padding-top: 30px;
    .AppointmentHours {
      padding: 0px;
      margin: 0px 0px 0px 20%;
      .NoResults{
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: $dark-pink;
      }
    }
    h3.header.HeaderText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-bottom: 0px;
    }
    .RegularText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-bottom: 0px;
    }
    .ModalActions {
      padding-bottom: 54px;
      padding-top: 40px;
      text-align: center;
      .ConfirmContainer {
        padding-bottom: 32px;
        padding-top: 32px;
      }
    }
    .SelectYourAppointment {
      color: $gray;
      font-family: $font-family;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      color: #484848;
      text-transform: uppercase;
      word-wrap: break-word;
     
    }
    @media only screen and (max-width: 768px) {
      .ModifyContainer {
        min-width: 350px;
        margin:auto;
      }
      .AppointmentHours {
        margin:auto;
      }
    }
  }
  img.ui.image {
    float: right;
    margin-top: 4px;
    cursor: pointer;
  }
  .HorizontalCalendar .CalendarSlider {
    padding-left: 0px;
    padding-right: 0px;
  }
  .CenterSelection {
    width: 938px;
    max-width: 90vw;
    margin: auto;
  }
  .HoursContainer {
    width: auto;
    margin: auto;
  }
}
.ui.page.dimmer {
  z-index: 9999;
}

@media only screen and (max-width: 768px) {
  .ui.modal.ModifyAppointmentModal.MyAppointment {
    .AppointmentHours {
      .NoResults{
        width: 100%;
      }
    }
  }
  .SelectYourAppointment {
    font-size: 15px !important;
    padding-bottom: 0px;
  }
  .ModifycenterSlotsFailureContainer {
    margin-left: -14px;
    margin-right: -14px;
  }
}
