.EditAccessManagementUserDetails
{
    width: 950px !important;
    height: 370px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 42px #00000044;
    opacity: 1;

    .ModalHeader{
        color: #1f237a !important;
        position: absolute;
    }
    .HeaderContainer{
        text-align: center;
        letter-spacing: 0px;
    }
    .content{
        width: 574px;
        height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #484848;
        opacity: 1;
    }
    .CloseModal{
        cursor: pointer;
    }
    .ModalContent{
        font-size: 20px;
    }
    .PrimaryButton{
        margin-top: 100px;
        width: 228px !important;
    }
    .ErrorMsg{
        top: 5px;
        position: relative;
    }
}