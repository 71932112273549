@import '../../Theme/CommonStyles/variables.scss';

.VerticalBlockNewsCard {
  min-height: 749px !important;
  min-width: 400px;
  margin-bottom: 20px !important;
  height: auto !important;
  border-radius: 0px !important;
  border: none !important;
  box-shadow: none !important;
}
.VerticalBlockImage {
  & > img {
    width: 100%;
  }
}
.VerticalBlockCardContent {
  padding: 50px 20px 44.5px 20px !important;
}
.VerticalBlockCategory {
  color: $light-green !important;
  font-family: Avenir !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.VerticalBlockTitle {
  color: $dark-blue !important;
  font-family: 'trade-gothic-next' !important;
  font-size: 24px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 30px !important;
  padding-bottom: 30px !important;
}
.VerticalBlockDescription {
  color: $gray;
  font-family: $font-family;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 23px;
  padding-bottom: 78px;
}
.VerticalBlockReadMore {
  color: $dark-pink;
  font-family: $font-family;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  flex-direction: row;

  &::after {
    content: '';
    flex: 1 0;
    border-bottom: 2px solid $dark-pink;
    margin: 10px;
    margin-left: auto 10px;
    max-width: 17px;
  }
}

@media only screen and (max-width: 992px) {
  .VerticalBlockNewsCard {
    min-width: 690px;
  }
}

@media only screen and (max-width: 640px) {
  .VerticalBlockNewsCard {
    min-width: 100%;
  }
  .VerticalBlockCardContent {
    padding: 30px 20px 54px 20px !important;
  }
  .VerticalBlockTitle {
    padding-bottom: 19px !important;
  }
  .VerticalBlockDescription {
    padding-bottom: 15px;
  }
}
