@import "../../../Theme/CommonStyles/variables.scss";

.ui.header.SetupTitleHeader {
  color: $dark-blue;
  font-family: $font-family;
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 34px;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 40px;
}
