.AccessManagementSuccessModal
{
    opacity: 1;
    width: 620px !important;
    height: 250px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 42px #00000044;

    .ModalHeader{
        color: #1f237a !important;
        text-align: left;
    }
    .HeaderContainer{
        text-align: center;
        letter-spacing: 0px;
    }
    .content{
        width: 574px;
        height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #484848;
        opacity: 1;
        padding: 0px !important;
    }
    .CloseModal{
        cursor: pointer;
    }
    .ModalContent{
        font-size: 20px;
    }
    .PrimaryButton{
        width: 228px !important;
    }
    .closerow{
        top: 2vh;
        position: relative;
    }
}