@import '../../../Theme/CommonStyles/variables.scss';

.DonationCenterInformation {
  font-family: $font-family;

  h3.ui.header.CenterInformationHeader {
    font-size: 38px;
    color: $dark-blue;
    font-family: $font-family;
    padding-bottom: 30px;
  }

  .DonationCenterLabel {
    padding-left: 32px;
    padding-top: 24px;
    line-height: 30px;
    padding-bottom: 0px;
    font-size: 12px;
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 0px;
  }

  .DonationCenterName {
    padding-left: 32px;
    padding-top: 0px;
    line-height: 30px;
    font-size: 38px;
    font-weight: bold;
    color: $dark-blue;
    margin-top: 0px;
  }

  .DonationCenterInfo {
    padding-left: 32px;
    padding-top: 0px;
    line-height: 30px;
    font-size: 20px;
    color: $dark-blue;
    a {
      color: $dark-blue;
      border-bottom: 1px solid $dark-blue;
    }
  }

  .ExploreOtherLocations {
    display: block;
    font-size: 16px;
    color: $dark-pink;
    letter-spacing: 0.32px;
    font-weight: bold;
    width: max-content;
    border-bottom: 1px solid $dark-pink;
    margin: 12px 0 16px 32px;
    padding: 4px 3px 2px 3px;
  }

  h4.ui.header.OfficeHoursHeader {
    font-family: $font-family;
    margin: 0px !important;
    padding-top: 40px;
    padding-left: 32px;
    font-weight: bold;
    line-height: 30px;
    padding-bottom: 0px;
    font-size: 32px;
    color: $dark-blue;
  }

  .OfficeHoursContainer {
    &.ui.list {
      padding-left: 32px;
      font-size: 1em;
      padding-top: 20px;
    }
  }
  .CenterMoreDetailsContainer {
    background-color: $white;
    padding-bottom: 20px;
    .DifferHours {
      padding: 32px;
    }
    .ui.list.OfficeHoursContainer {
      .ui.grid {
        margin: 0;
        > .column {
          padding: 0px;
        }
      }
    }
  }
 
  .ScheduleOnMobileContainer {
    display: none;
  }

  @media only screen and (max-width: 480px) {
    .DashboardCenterContainer {
      margin-left: 0!important;
      margin-right: 0!important;
    }
    .CenterMoreDetailsContainer {
      .DifferHours {
        padding-left: 0;
        padding-top: 20px;
      }
    }
    .CenterInfoContainer {
      margin-left: -1rem;
      margin-right: -1rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .CenterImageContainer {
      margin-left: -1rem;
      margin-right: -1rem;
    }
    h3.ui.header.CenterInformationHeader {
      font-size: 30px;
      color: $dark-blue;
      line-height: 30px;
      font-family: $font-family;
      padding-bottom: 11px;
      padding-top: 20px;
    }
    .ui.list.OfficeHoursContainer {
      font-size: 16px;
      padding-top: 30px;
      padding-left: 0;
    }
    .DonationCenterName {
      font-size: 27px !important;
      padding-left: 16px;
    }
    .DonationCenterInfo {
      padding-left: 16px;
    }
    .ExploreOtherLocations {
      margin-top: 12px;
      margin-left: 16px;
    }
    .ScheduleOnMobileContainer {
      display: block;
    }
    .DonationCenterLabel {
      padding-left: 16px;
      padding-top: 24px;
    }
    h4.ui.header.OfficeHoursHeader {
      padding-left: 0;
    }
    .OfficeHoursContainer {
      .item {
        line-height: 25px !important;
      }
    }
    .CenterMoreDetailsContainer {
      .WorkingHoursColumn {
        padding-left: 56px !important;
      }
      .ViewPlayroomContainer {
        display: flex;
        justify-content: center;
      }
    }
  }
}
