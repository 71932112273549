@import "../../../Theme/CommonStyles/variables.scss";

.ui.modal.AddToCalendarModal {
  max-width: 703px;
  margin-top: 40px;

  .HeaderContainer {
    padding: 20.5px 25px 29.5px 25px;
  }
  .AppointmentAnchor {
    width: 391px;
    height: 60px;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.64px;
    text-align: center;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: $dark-pink;
    text-decoration: underline;
  }
  h2.header.ModalHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }

  .MyAppointment {
    text-align: center;
    padding-top: 30px;

    h3.header.HeaderText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-bottom: 0px;
    }
    .NewDonorBonusInformationBlock {
      background-color: #F7F8FF;
      font-family: $font-family;
      letter-spacing: 0;
      text-align: center;
      margin: 0 10px;
      padding: 10px 0;
      p:first-child{
        color: $dark-blue;
        font-size: 24px;
        font-weight: bold;
        margin: 0;
      }
      :nth-last-child(2){
        color: $dark-blue;
        font-weight: bold;
        font-size: 16px;
        padding: 5px 10px 0 10px;
      }
      span{
        font-size: 12px;
      }
    }

    .RegularText {
      color: $gray;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 20.16px;
      text-align: center;
      margin-bottom: 0px;
    }

    .ModalActions {
      padding-bottom: 54px;
      padding-top: 40px;

      .ConfirmContainer {
        padding-bottom: 32px;
      }

      .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    
        .ui.grid {
          flex-direction: row;
    
          @media only screen and (max-width: 480px) {
            flex-direction: column;
            padding-left: 0px;
          }
        }
      }
    
      .checkbox-input {
        display: none;
      }
    
      .checkbox-tile {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        transition: 0.25s ease;
        cursor: pointer;
        width: 196px;
        height: 133px;
        background: #ffffff;
        border: 1px solid #ababab;
        border-radius: 5px;
    
        .checkbox-icon {
          width: 70px;
          height: 41px;
          margin-bottom: 12px;
          margin-top: 26px;
        }
    
        .checkbox-label {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: $gray;
          font-family: $font-family;
          max-width: 187px;
        }
      }
    
      .checkbox-tile.es-MX {
        height: 150px;
      }
    
      .checkbox-tile::before {
        content: "";
        position: absolute;
        width: 26px;
        height: 26px;
        border: 1px solid #484848;
        border-radius: 50%;
        top: 10px;
        left: 10px;
        background-size: 30px;
        background-position: 35%;
      }
    
      .checkbox-tile:hover {
        border-color: #484848;
      }
    
    
      /* checked */
      .checkbox-input:checked+.checkbox-tile {
        border-color: $dark-pink;
        box-shadow: 0 5px 10px #00000022;
      }
    
      .checkbox-input:checked+.checkbox-tile>.checkbox-label {
        font-weight: 700;
      }
    
      .checkbox-input:checked+.checkbox-tile::before {
        border-color: $green;
        border: 1px solid #009e8e;
        background-image: url("../../../../assets/media/icons/green-check.svg");
        background-size: 120%;
      }
    
      .PaymentOptionText {
        text-align: center;
        padding-bottom: 20px;
        padding-top: 10px;
    
        .RegularText {
          font-size: 20px;
          font-weight: 700;
          color: $dark-blue;
        }
      }
    
      .SmartCardDescription {
        text-align: left;
        padding-top: 16px;
        max-width: 420px;
        margin: 0 auto;
        padding-bottom: 40px;
    
        .RegularText {
          font-size: 14px;
          font-weight: 420;
          color: $dim-gray;
          line-height: 17.64px;
        }
      }
    
      .ErrorText {
        font-weight: 700;
        font-size: 14px;
        color: #E1242A;
        font-family: $font-family;
        text-align: left;
        padding-top: 16px;
        max-width: 420px;
        margin: 0 auto;
      }
    
      .InboxNoticeContainer {
        max-width: 400px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 16px;
    
        .RegularText {
          font-size: 14px;
          line-height: 19.64px;
        }
      }
    }

    .react-add-to-calendar__button {
      height: 50px;
      width: 270px;
      border-radius: 25px;
      font-family: $font-family;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      text-align: center;
      background-color: $white;
      cursor: pointer;
      display: inline-block;
      min-height: 1em;
      vertical-align: baseline;
      color: $color-orange;
      border: 1px solid  $color-orange;
      padding: 0.78571429em 1.5em 0.78571429em;
    }
    .react-add-to-calendar__button_disabled{
      height: 50px;
      width: 270px;
      border-radius: 25px;
      font-family: $font-family;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      text-align: center;
      background-color: $white;
      cursor: pointer;
      pointer-events: none;
      display: inline-block;
      min-height: 1em; 
      vertical-align: baseline; 
      color:$disable-gray;
      border: 1px solid $disable-gray;
      padding: 0.78571429em 1.5em 0.78571429em;
    }

    .react-add-to-calendar__dropdown {
      padding: 5px 0 5px 8px;
      box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid #a8a8a8;
      background-color: #fff;
      text-align: left;
      width: 270px;
      margin: 0 auto;
      right: 31%;

      ul {
        font-family: $font-family;
        color: $gray;
        font-size: 16px;
        list-style: none;
        padding-left: 70px;

        li {
          margin-bottom: 5px;

          a {
            color: $gray;
          }
        }
      }
    }
  }

  img.ui.image {
    float: right;
    margin-top: 4px;
    cursor: pointer;
  }

  @media only screen and (max-width: 480px) {
    h2.header.ModalHeader {
      font-size: 20px;
    }
    .MyAppointment{
      .NewDonorBonusInformationBlock{
        margin: 0;
        p:nth-last-child(2){
          font-size: 14px;
        }
      }
  
      .ModalActions {
        .PaymentOptionText {
          .RegularText {
            font-size: 18px;
          }
        }
  
        .SmartCardDescription {
          max-width: 311px;
  
          .RegularText {
            font-size: 12px;
            line-height: 15.12px;
          }
        }
  
        .ErrorText {
          font-size: 12px;
          max-width: 311px;
        }
  
        .checkbox-tile {
          width: 311px;
          height: 120px;
  
          .checkbox-icon {
            margin-bottom: 10px;
            margin-top: 22px;
          }
        }
  
        .checkbox-tile.es-MX {
          height: 120px;
        }
  
        .checkbox-label.es-MX {
          max-width: 200px;
        }
  
        .InboxNoticeContainer {
          .RegularText {
            font-size: 13px;
            line-height: 18.12px;
  
            .AppointmentAnchor {
              font-size: 13px;
              line-height: 18.12px;
            }
          }
        }
      }
    }
  }
  
  
  @media only screen and (max-width: 359px) {
    .MyAppointment {
      .ModalActions {
        .checkbox-group {
          padding-left: 10px;
          padding-right: 20px;
          justify-content: space-between;
        }
      }
    }
  }
}

.ui.page.dimmer {
  z-index: 9999;
}