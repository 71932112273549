@import '../../../Theme/CommonStyles/variables.scss';

.ResendButtonWrap {
    width: 470px;
    padding-left: 2%;
    .SuccessContainer{
        margin-bottom: 15px;
        padding-left: 14px;
        color: $dark-blue;
        font-family: $font-family;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
      }
      .FailureStateContainer{
        margin-bottom: 15px;
        padding-left: 14px;
        
      }
       .ErrorMessage {
          margin-bottom: 15px;
          padding-left: 14px;
          color: $dark-pink;
          font-family: $font-family;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 30px;
        }
      
}