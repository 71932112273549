@import "../../../../Theme/CommonStyles/variables.scss";

.WaystoEarnCardContainer{

.titleForWaysTab{
    color:$gray;
    padding: 20px 30px;
}
.points{
  text-align: end;
 color: $gray;
padding: 20px 30px;
}
.desc{
    font-size: 22px;
    padding: 10px;
    color: $gray;
}
.Loyalty {
  display: flex;
  align-items: center;
  border-radius: 30px;
  background-color: $loyaltyLightGray;
  margin-bottom: 40px !important;
  margin-left: 10px;
  }
  .milestoneTitle{
      color:$loyaltyDarkGreen;
      font-size: 24px;
      font-weight: bold;
  }
  .milestoneDesc{
      color: $gray;
      font-size: 16px;
  }
    .RedeemTextPoints {
      margin-bottom: 3px;
      color: $loyaltyDarkGreen;
      font-size: 24px;
      font-weight: bold;
      padding-top: 10px;
      padding-left: 0px;
    }
    .loyaltyHead{
      border-radius: 30px;
      background-color: $loyaltyLightGray;
      margin-bottom: 30px !important;
      padding: 30px;
    }
    .webMilestoneTitle{
      color:$loyaltyDarkGreen;
      font-size: 24px;
      font-weight: bold;
      padding-top: 10px;
  }
  .webmilestoneDesc{
      color: $gray;
      font-size: 16px;
      padding-top: 10px;
  }
    .webRedeemTextPoints {
      margin-bottom: 3px;
      color: $loyaltyDarkGreen;
      font-size: 24px;
      font-weight: bold;
      padding-top: 10px;
      text-align: end;

    }
    .EarnImage{
      object-fit: contain;
      padding: 10px;
    }
.row2{
width: 20%;
padding: 20px;
}
.row3{
width: 55%;
}
.Accordion {
  font-family: $font-family;
  margin-top: 20px;
  .ui.container {
    margin-left: 0 !important;
  }
  h2 {
    display: inline;
    font-size: 24px;
    padding: 0;
    padding-left: 10px;
    color: $loyaltyDarkGreen
  }
  p b {
    color: $gray;
  }
}
.AccordionContent {
  padding-left: 30px;
  padding-bottom: 30px;
}
h1 {
  color: $loyaltyDarkGreen;
  font-family: $font-family;
  font-size: 38px;
  font-weight: $font-bold;
  letter-spacing: 0;
  line-height: 40px;
  padding-top: 5px;
  padding-bottom: 15px;
}
.rewardsfaqs{
  border-bottom: 1px solid $disable-gray;
}
}
.rewardsLink {
  display: grid;
  color: #ac0079;
  text-decoration: underline;
  font-family: $font-family;
  font-size: 19px;
  font-weight: bold;
  text-align: start;
  padding-top: 50px !important;
}
@media only screen and (max-width: 768px) {
  .WaystoEarnCardContainer{

    .desc{
      text-align: center;
    }
    .Loyalty{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      margin: -1rem;
    }
    .p{
        padding-left: 15%;
    }
    .row1{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .row2{
      width: 100% !important;
      padding-left: 0px;
    }
    .row3{
      text-align: start;
      width: 100% !important;
      padding-right: 30px;
    }
    .rewardsLink {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 16px;
    }
    .Accordion h2 {
      font-size: 20px;
    }
    .RedeemTextPoints {
      padding-left: 10px;
    }
}
}