@import '../../Theme/CommonStyles/variables.scss';

.Testimonials {
  height: 684px;
  background-color: $new-gray;
  padding-top: 5vh;
  text-align: center;
  h1.ui.header.TestimonialsHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 68px;
    font-weight: bold;
    letter-spacing: -0.32px;
    line-height: 74px;
    text-align: center;
    margin-bottom: 62px;
  }

  .TestimonialsImg {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100px;
  }
  
  .TestimonialsContent {
    height: 282px;
    width: 959px;
    background-color: $new-gray;
    margin: auto;
    padding: 60px 66px 60px 66px;

    p {
      color: $dim-gray;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
    }
    .Author {
      color: $gray;
      font-family: $font-family;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 800px) {
    h1.ui.header.TestimonialsHeader {
      font-size: 46px;
      line-height: 50px;
    }
    .TestimonialsContent {
      height: auto;
      width: 720px;
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 480px) {
    h1.ui.header.TestimonialsHeader {
      font-size: 46px;
      line-height: 50px;
    }
    .TestimonialsContent {
      height: auto;
      width: 350px;
      padding: 1rem;
    }
  }
}
