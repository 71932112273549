@import "../../Theme/CommonStyles/variables.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.DonorTestimonialsContainer {
  position: relative;
  .TestimonialHeading {
    font-size: 50px;
    font-weight: bold;
    line-height: 58px;
    padding-top: 48px;
    padding-bottom: 36px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $light-blue-hero;
    color: $dark-blue;
  }
  .TestimonialCard {
    background-color: $light-blue-hero;
    color: $dark-blue;
    padding-bottom: 85px;
    .Testimonial {
      width: 60% !important;
      display: flex !important;
      margin: 0px auto;
      padding: 34px 70px;
      align-items: center;
      justify-content: center;
      text-align: center;
      .ui.tiny.image {
        width: 100px !important;
      }
      .DonorTestimonial {
        font-size: 24px;
        line-height: 36px;
      }
      .DonorName {
        font-size: 24px;
        line-height: 30px;
        padding-top: 16px;
        font-weight: bold;
      }
      .DonorImage {
        padding-top: 10px;
        padding-bottom: 16px;
      }
    }
  }
  .ArrowContainer {
    .SlickArrow {
      color: $dark-blue;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }
    .LeftArrow {
      position: absolute;
      bottom: 42%;
      left: 12%;
    }
    .RightArrow {
      position: absolute;
      bottom: 42%;
      right: 12%;
    }
  }
}
@media (min-height: 600px) and (max-width: 480px) {
  .DonorTestimonialsContainer {
    .TestimonialHeading {
      padding: 59px 0px 24px 0;
      font-size: 32px;
      line-height: 58px;
    }
    .TestimonialCard {
        height: auto;
        margin-bottom: 56px;
        padding-bottom: 0;
      .Testimonial {
            width: auto !important;
            padding: 0 20px 20px 20px;
        .ui.tiny.image {
            width: 80px !important;
        }
        .DonorTestimonial {
            font-size: 18px;
            line-height: 24px;
            padding-top: 2px;
            padding-bottom: 18px;
            width: 270px !important;
        }
        .DonorName {
          padding-top: 0;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    .ArrowContainer {
      .LeftArrow {
        left: 4%;
        font-size: 11px;
      }
      .RightArrow {
        font-size: 11px;
        right: 4%;
      }
    }
  }
}
