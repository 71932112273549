@import '../../../Theme/CommonStyles/variables.scss';

.ScheduleAppointmentAction {
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
  background-color: $white;
  border-bottom: 1px solid #dddddd;
  .ui.button.PrimaryButton {
    line-height: 25px;
  }
}
