@import '../../../Theme/CommonStyles/variables.scss';
.YourStoryForm {
  .FormField {
    position: relative;
    padding-top: 18px;
  }
  .FormNote {
    font-size: 12px;
    margin-bottom: 24px;
  }
  .RequiredInfo {
    color: $gray;
  }
  .MessageButtonContainer {
    padding-left: 10px;
  }
  .FormField {
    position: relative;
    padding-top: 30px;
    small {
      position: absolute;
      top: 10px;
      color: $gray;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0.6px;
    }
    small.TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 12px;
    }
    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 92% !important;
      padding-top: 3px;
    }
    .ErrorMsgContainerMessage{
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 100% !important;
      padding-top: 4px;
      display: flex;
      .ErrorMsg{
        color: $color-validation-red;
        padding-top: 2px;
      }
      .InfoIcon{
        margin-right: 8px;
        width: 18px;
      height: 18px;
      }
    }
 .ErrorMsgContainer .InfoIcon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  .ErrorMsgContainer .ErrorMsg {
      padding-top: 2px;
  }
  }
  .ui.form .fields.emailCustomFeild {
    .field {
      margin-bottom: 10px; 
    }
  }
  .PrimarySelect .ui.dropdown:not(.button) > .default.text ,.PrimarySelect .ui.dropdown > .default.text , .PrimarySelect .ui.selection.dropdown.Base.Error .default.text{
    color: $light-gray;
  }
  .PrimaryInput .ui.input > input {
    border-radius: 4px;
  }
  .PrimarySelect .ui.selection.dropdown.Base {
    border-radius: 4px !important;
    height: 50px;
  }
  .ui.selection.dropdown.Base.Error .default.text {
    color: $gray;
}
.ui.selection.dropdown.Base.Error {
  border: 1px solid #ac0079;
}
}
.ui.form textarea.Error {
  border: 1px solid $dark-pink;
}
.HeaderContainer .ModalHeader.ui.header {
  color: #1f237a;
}
@media only screen and (max-width: 480px)  {
.PrimarySelect .ui.selection.dropdown.Base{
  display: block;
}
}