@import "../../../../../Theme/CommonStyles/variables.scss";

.RewardsCardContainer {
  a {
    color: #ac0079;
    text-decoration: underline;
    font-family: $font-family;
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 30px;
    padding-left: 30px;
  }

  h2 {
    color: $loyaltyDarkGreen;
    border: 0px;
    font-family: $font-family;
  }

  .ui.feed {
    .event {
      border-top: 1px solid #dddddd;
      padding-top: 15px;
      padding-bottom: 15px;
      justify-content: space-between;
      &:first-child {
        border-top: none;
      }
      .RewardIconContainer{
        background-color: #F3F3F3;
        width: 80px; 
        height: 80px; 
        border-radius: 50%;
        display: flex;
        justify-content: center;
        padding: 10px;
      }
      .RewardIcon{
        object-fit: contain;
        padding: 10px;
      }

      .content {
        display: contents;
        font-family: $font-family;
        font-size: 20px;
        color: $loyaltyDarkGreen;

        .summary {
          display: grid;
          font-family: $font-family;
          font-size: 20px;
          color: $loyaltyDarkGreen;
          padding-right: 15px;
          width: 50%;
          line-height: normal;
          padding-top: 15px;

          .date {
            color: $loyaltyBlack;
            font-size: 16px;
            line-height: 20px;
            padding-top: 10px;
          }
        }

        .date {
          padding-top: 15px;
          font-family: $font-family;
          font-size: 14px !important;
          width: 200px;
          margin: 0px;
        }

        .extra {
          color: $loyaltyDarkGreen;
          font-weight: bold;
          padding: 10px;
          padding-right: 10px;
          display: flex;
        }
      }
    }
  }

  .ui.card {
    border-radius: 20px;
    width: 100%;
    margin-bottom: 20px;

    .content {
      border-top: none;
      padding: 30px 30px 15px 30px;

      h4:last-child {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
      }
      a {
        text-align: center;
        
      }
    }
  }

  .RewardsCard {
    display: flex;
    gap: 40px;
  }
  .subText {
    font-size: 18px;
    color: $gray;
    font-weight: normal;
  }
  .noDataMessage {
    width: max-content;
    font-size: 16px;
  }
}
.noRecentrewardContainer {
  .ui.card {
    padding-bottom: 70%;
  }
}

@media only screen and (max-width: 480px) {
  .RewardsCardContainer {
    display: block;
    grid-gap: 40px;
    gap: 40px;
    h2 {
      display: block;
      gap: 30px;
      text-align: center;
      padding: 10px;
    }
    a{
      padding-left: 0px;
      padding-top: 0px;
      padding-left: 0px;
    }
    h6 {
      padding-left: 20px;
    }
    .desc {
      padding-left: 60px;
    }
    .ui.feed {
      .event {
        .RewardIconContainer{
          margin: auto;
          .RewardIcon{
            padding: 10px;  
            object-fit: contain;         
          }
        }
        .label {
          text-align: center;
          padding-bottom: 20px;
        }
        .content {
          display: grid;
          text-align: center;

          .summary {
            text-align: center;
            display: contents;
            padding-left: 10px;
            .date {
              padding-top: 10px;
              width: auto;
            }
          }
          .extra {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
    justify-content: center;
          }
        }
      }
    }
    .ui.card {
      .content {
        padding: 10px;
        img {
          padding-bottom: 20px;
          padding-left: 20px;
        }
        a {
          padding-left: 0px;
          text-align: center;
        }
      }
    }
  }
  .noDataMessage {
    width: auto !important;
    padding: 10px;
  }
  .noRecentrewardContainer {
    .ui.card {
      padding-bottom: 40%;
    }
  }
}