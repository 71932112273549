@import "../../../Theme/CommonStyles/variables.scss";

.ui.modal.ChangePaymentMethodModal {
  max-width: 623px;

  .HeaderContainer {
    padding: 20.5px 25px 20.5px 25px;
  }

  h2.header.ModalHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: normal;
  }

  .ModalContent {
    text-align: center;

    .DescriptionTopContainer {
      max-width: 450px;
      text-align: center;
      margin: 0 auto;
      padding-top: 6px;
      padding-bottom: 40px;

      .RegularText {
        font-size: 16px;
        color: $gray;
        font-family: $font-family;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .DescriptionBottomContainer {
      max-width: 420px;
      text-align: center;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 46px;

      .RegularText {
        font-size: 14px;
        color: $gray;
        font-family: $font-family;
        font-weight: 700;
        line-height: 24px;
      }
    }

    .ErrorText {
      font-weight: 700;
      font-size: 14px;
      color: #E1242A;
      font-family: $font-family;
      text-align: center;
      padding-bottom: 12px;

      @media only screen and (max-width: 480px) {
        text-align: center;
        font-size: 12px;
      }
    }

    .ActionsContainer {
      width: "100%";
      text-align: center;
      padding-bottom: 20px;

      .ButtonContainer {
        padding: 10px;
      }
    }

    .checkbox-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .ui.grid {
        flex-direction: row;

        @media only screen and (max-width: 480px) {
          flex-direction: column;
          padding-left: 0px;
          padding-right: 50px;
        }
      }
    }

  .checkbox-input {
    display: none;
  }

  .checkbox-tile {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    transition: 0.25s ease;
    cursor: pointer;
    width: 196px;
    height: 133px;
    background: #ffffff;
    border: 1px solid #ababab;
    border-radius: 5px;

    .checkbox-icon {
      width: 70px;
      height: 41px;
      margin-bottom: 12px;
      margin-top: 27px;
    }

    .checkbox-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $gray;
      font-family: $font-family;
      max-width: 187px;
    }
  }

  .checkbox-tile.es-MX {
    height: 150px;
  }

  .checkbox-tile::before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border: 1px solid #484848;
    border-radius: 50%;
    top: 10px;
    left: 10px;
    background-size: 30px;
    background-position: 35%;
  }

  .checkbox-tile:hover {
    border-color: #484848;
  }


  /* checked */
  .checkbox-input:checked+.checkbox-tile {
    border-color: $dark-pink;
    box-shadow: 0 5px 10px #00000022;
  }

  .checkbox-input:checked+.checkbox-tile>.checkbox-label {
    font-weight: 700;
  }

  .checkbox-input:checked+.checkbox-tile::before {
    border-color: $green;
    border: 1px solid #009e8e;
    background-image: url("../../../../assets/media/icons/green-check.svg");
    background-size: 120%;
  }
  }

  @media only screen and (max-width: 767px) {
    max-width: 500px;

    // height: 144px;
    h2.header.ModalHeader {
      font-size: 20px;
      // line-height: 25px;
    }

    .ModalContent>.checkbox-group {
      padding-left: 28px;
      padding-right: 28px;
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 343px;

    // height: 144px;
    .ModalContent {
      .IconTopContainer {
        max-width: 311px;

        .RegularText {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
      }

      .IconBottomContainer {
        max-width: 311px;

        .RegularText {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
      }

      .checkbox-group {
        padding-left: 26px;
        padding-right: 25px;
        justify-content: space-between;
      }

      .checkbox-tile {
        width: 263px;
        height: 120px;

        .checkbox-icon {
          margin-bottom: 8px;
          margin-top: 22px;
        }
      }

      .checkbox-tile.es-MX {
        height: 120px;
      }

      .checkbox-label.es-MX {
        max-width: 200px;
      }

      .ActionsContainer{
        display: flex;
        justify-content:space-evenly;
        .ButtonContainer {
          padding: 5px;
        }
      }

      .DescriptionBottomContainer{
        max-width: 275px;
        padding-bottom: 30px;
        .RegularText {
          font-size: 12px;
        }
      } 
    }
  }

  .ui.dimmer,
  .ui.loader {
    padding-bottom: 16px;
  }

  .ui.loader:before {
    border: 0.2em solid #fff !important;
  }

  .ui.loader:after {
    border-color: #4d9bd2 transparent transparent !important;
  }

  @media only screen and (max-width: 359px) {
    .ModalContent>.checkbox-group {
      padding-left: 10px;
      padding-right: 20px;
      justify-content: space-between;
    }
  }
}