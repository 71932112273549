@import '../../../Theme/CommonStyles/variables.scss';

.social-login-form__content {
  h1.header.MainHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 35px;
  }
  .page-container_pageinfo {
    p {
      line-height: 25px;
      &.mb-40 {
        margin-bottom: 40px;
      }
    }
  }
  .form {
    .field {
      width: 100%;
    }
  }
  .timeout-container{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    .ui.label{
      width: 100%;
      text-align: center;
    }
  }
  .FormField {
    position: relative;
    padding-top: 18px;
    small {
      position: absolute;
      top: 0;
      color: $dark-pink;
      font-family: $font-family;
      font-size: 12px;
    }
    small.TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 0;
    }
    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 0;
    }
  }
  .RequiredInfo {
    color: $color-validation-red;
  }
  .MessageButtonContainer {
    padding-left: 10px;
    padding-top: 3px;
    margin-bottom: 40px;
  }
  .ui.form .fields.ButtonContainer {
    justify-content: center;
    margin-bottom: 23px;
  }
}
