.ui.form.HeroSearchForm {
  max-width: 380px;
  margin-top: 15px;
  .ui.input > input {
    border-radius: 0px;
    .searchInput{
      width: 100%;
    }
  }
}

@media only screen and (max-width: 540px) {
  .ui.form.HeroSearchForm {
      padding: 0 0 20px 10px;
      .MessageButtonContainer{
        padding-left: 10px;
      }
    }
}

@media only screen and (max-width: 992px) and (min-width: 541px) {
  .ui.form.HeroSearchForm {
      .MessageButtonContainer{
        padding-left: 12px;
      }
    }
}