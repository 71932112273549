@import '../../../Theme/CommonStyles/variables.scss';

.SearchDonorsResult {
  font-family: $font-family;
  margin-bottom: 30px !important;
  h4.ui.header {
    font-size: 24px;
    line-height: 30px;
    color: $dark-blue;
    padding-bottom: 20px;
    margin-left: 40px !important;
    padding-top: 10px;
  }
  .ui.message{
    width: 100%
  }

  .ResultsTableContainer {
    &.ui.grid {
      width: 100%;
      background: #fff;
      margin-left: 40px;
      margin-top: 25px;
      padding: 0 30px;
    }
  }

  .ResultsTableHeader {
    margin-top: 20px;
    padding-top: 20px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid $light-gray;
  }

  .ResultsTableHeader > .column {
    font-size: 14px;
    font-weight: bold;
    color: $gray;
    line-height: 21px;
    letter-spacing: 1.1px;
  }

  .Pagination {
    padding-top: 15px !important;
    border-top: 2px solid $light-gray !important;
    margin-left: 50px !important;
  }

  .PaginationText {
    color: $dark-blue;
    font-weight: bold;
    padding-top: 10px !important;
    font-size: 14px;
    line-height: 21px;
  }

  .ResultsTableEntry {
    padding-top: 25px !important;
    padding-bottom: 30px !important;
    border-bottom: 1px solid $light-gray !important;
  }

  .ResultsTableEntry > .column {
    font-size: 16px;
    color: $gray;
    line-height: 25.2px;
  }

  .DonorFirstName > div {
    font-weight: bold;
    color: $dark-blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .DonorName, 
  .DonorEmail {
    overflow-wrap: break-word;
  }

  .NoResults {
    font-family: $font-family;
    font-size: 20px;
    padding-left: 50px;
  }

  .PaginationContainer {
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .PageText {
      color: $dark-blue;
      padding-right: 30px;
      font-weight: $font-bold;
    }
    .ui.pagination.menu {
      text-align: center;
      min-height: 30px;
    }
    .ui.pagination.menu .item:last-child {
      display: none;
    }
    .ui.menu > .item:first-child {
      display: none;
    }
    .ui.pagination.menu .active.item {
      background-color: transparent;
      color: $dark-blue;
      font-weight: bold;
      border: none;
    }
    .ui.secondary.menu .item {
      outline: none;
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0.62px;
      line-height: 21px;
      min-width: 30px;
      padding: 0;
      margin: 0;
    }
    .ui.secondary.menu .dropdown.item:hover,
    .ui.secondary.menu .link.item:hover,
    .ui.secondary.menu a.item:hover {
      background-color: transparent;
    }
  }
}
