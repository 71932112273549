@import '../../../Theme/CommonStyles/variables.scss';

.AppointmentReportFilter {
  h4.header.FormHeader {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  .ReportSearchForm {
    padding-top: 25px;
    .today-button{
      background-color: transparent;
      margin-left: 75%;
      padding: 10px 0px 25px 0px;
      color: $color-orange;
    }
    .SearchLabel {
      font-family: $font-family;
      color: $gray;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      margin-bottom: 0;
    }
    .SearchInput {
      margin-right: 17px;
    }
    .PrimarySelect {
      width: 222px;
      margin-right: 43px;
    }
    .FormField {
      position: relative;
      padding-top: 23px;
      small {
        position: absolute;
        top: 0;
        color: $color-orange;
        font-family: $font-family;
      }
      .TopLabel {
        position: absolute;
        color: $gray;
        font-family: $font-family;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 23px;
        top: 0;
      }
      .TopLabelError {
        position: absolute;
        color: $color-validation-red;
        font-family: $font-family;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 23px;
        top: 0;
      }
      .react-daterange-picker__wrapper{
        height: 50px;
        border: 1px solid rgba(34,36,38,.15);
        background-color: white;
        .react-date-picker__inputGroup__divider{
          display: inline-block;
          margin-top: 8px;
        }
        .react-daterange-picker__inputGroup{
          height: fit-content;
          .react-daterange-picker__inputGroup__leadingZero{
            margin-left: 5px;
          }
          .react-daterange-picker__inputGroup__day{
            padding: 9px 3px;
          }
          .react-daterange-picker__inputGroup__month{
            padding: 9px 3px;
          }
          .react-daterange-picker__inputGroup__year{
            padding: 9px 3px;
          }
          input{
            border: none;
            background: transparent;
          }
        }
      }
      .SelectorContainer{
        border-radius: 5px;
        margin-top: 10px;
        border: 1.5px solid $dark-pink;
        .react-calendar__navigation{
          margin-bottom: 5px;
        }
        .react-calendar__navigation__label{
          margin-top: 5px;
          color: $color-orange;
          font-weight: bold;
          border-radius: 20px;
          &:hover{
            background-color: #F965061A;
          }
        }
        .react-calendar__navigation__arrow{
          margin-top: 5px;
          color: $color-orange;
          border-radius: 20px;
          &:hover{
            background-color: #F965061A;
          }
          &:focus{
            background-color: #F965061A;
          }
        }
        .react-calendar__month-view__weekdays__weekday{
          color: #6D6D6D;
          abbr{
            text-decoration: none;
          }
        }
        .react-calendar__month-view{
          .react-calendar__month-view__days__day--neighboringMonth{
            visibility: hidden;
          }
          .react-calendar__tile--range{
            color: black;
            border-top: 1px $dark-pink dashed;
            border-bottom: 1px $dark-pink dashed;
            background-color: transparent;
            &:hover{
              border-radius: 0 !important;
            }
          }
          .react-calendar__tile--rangeStart{
            background-color: $dark-pink !important;
            border-radius: 50px !important;
            color: white !important;
            &:hover{
              border-radius: 50px !important;
            }
          }
          .react-calendar__tile--rangeEnd{
            color: white !important;
            border-radius: 50px !important;
            background-color: $dark-pink;
            &:hover{
              border-radius: 50px !important;
            }
          }
          .react-calendar__tile--hover{
            background-color: transparent;
            color: black;
            border-top: 1px $dark-pink dashed;
            border-bottom: 1px $dark-pink dashed;
          }
          .react-calendar__tile--hoverStart{
            background-color: #D8DAFF;
            border: none !important;
            border-radius: 50px !important;
          }
          .react-calendar__tile--hoverEnd{
            background-color: #D8DAFF;
            border: none !important;
            border-radius: 50px !important;
          }
          .react-calendar__month-view__days__day--weekend{
            color: black;
          }
          .react-calendar__tile{
            max-width: 13.5% !important;
            margin: 2.5px 1.25px;
            padding: 12px;
            &:hover{
              border-radius: 50px;
            }
          }
          .react-calendar__tile--now{
            background-color: #6D6D6D !important;
            color: white !important;
            border-radius: 50px !important;
            border: none !important;
          }
        }
        .react-calendar__year-view{
          margin-top: 15px;
          .react-calendar__tile{
            max-width: 30% !important;
            padding: 10px 0;
            margin: 10px 5px;
            border-radius: 20px;
            &:hover{
              background-color: #F965061A;
              color: $color-orange;
            }
          }
          .react-calendar__tile--hasActive{
            background-color: $dark-pink;
            color: white;
          }
          .react-calendar__tile--active{
            background-color: $dark-pink;
            color: white;
          }
          .react-calendar__tile--now{
            background-color: #6D6D6D !important;
            color: white !important;
          }
        }
        .react-calendar__decade-view{
          margin-top: 22px;
          .react-calendar__tile--hasActive{
            background-color: $dark-pink;
            color: white;
          }
          .react-calendar__tile--active{
            background-color: $dark-pink;
            color: white;
          }
          .react-calendar__tile{
            max-width: 30% !important;
            padding: 10px 0;
            margin: 10px 5px;
            border-radius: 20px;
            &:hover{
              background-color: #F965061A;
              color: $color-orange;
            }
          }
          .react-calendar__tile--now{
            background-color: #6D6D6D !important;
            color: white !important;
          }
        }
      }
    }
    .ui.button.SearchReport {
      margin-top: 20px;
      margin-right: 27px;
    }
    .ui.button.ClearForm {
      margin-top: 20px;
      // background-color: $blue !important;
    }
    .ui.button.ClearForm.disabled {
      opacity: 0.5 !important;
    }
    .ui.dropdown.PrimarySelect {
      .default.text {
        color: rgba(191,191,191,.87);
      }
    }
    .AppointmentTypeSelect > .PrimarySelect .Required{
      display: none;
    }
  }
}