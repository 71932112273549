@import "../../../../Theme/CommonStyles/variables.scss";

.CreateAccessManagementForm
{
    left: 66.5px;
    right: 66.5px;
    top: 100px;

    .CreateInput{
        /* Layout Properties */
        width: 449px;
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
        height: 50px;
        top: 8px;
        position: relative;
    }

    .secondRow{
      top: 25px!important;
      position: relative;
      .required.field.FormField{
        left:7px;
        position: relative;
      }
    }
    .TopLabel{
        font-family: trade-gothic-next;
        line-height: 23px;
        font-size: 14px;
        /* UI Properties */
        text-align: left;
        letter-spacing: 0.6px;
        color: #484848;
        opacity: 1;
    }
    .save{
        top: 483px;
        left: 525px;
        width: 158px;
        height: 50px;
        /* UI Properties */
        background: $color-validation-red 0% 0% no-repeat padding-box;
        border-radius: 25px;
        opacity: 1;
    }

    .ui.selection.dropdown.Base.CreateFormSelect {
        width: 449px;
        height: 50px;
        margin-bottom: 10px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
        font-family: $font-family;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        .divider.default{
            color: rgba(191,191,191,.87) !important;
        }
        .divider{
          color: black !important;
        }
      }
      .ui.selection.dropdown.Base.CreateFormSelect.Error {
        border: 1px solid $color-validation-red;
        margin-bottom: 0px
      }
      .ui.input{
        height: 50px;
        font-size: 14px;
        input{
          border-radius: 0;
        }
        
      }
      .TopLabelError {
        color: $color-validation-red;
        height: 11px;
        text-align: left;
        font: normal normal 600 11px/7px Barlow;
        letter-spacing: 0.5px;
        opacity: 1;
        position: absolute;
        top: 80px;
        display: grid;
      }
      .ui.error.input{
        border: 1px solid $color-validation-red
      }
      .PrimarySelect{
        .ui.selection.dropdown.Base.Error .default.text {
          color: #EBCAC8 !important;
        }
        padding-bottom: 0px;
        top: 8px;
      }
      .ErrorIcon{
        top: 417px;
        left: 725px;
        width: 18px;
        height: 18px;
        opacity: 1;
      }
      .ui.icon.input>i.icon {
        width: 1.18em;
        font-size: .5em;
        top: 6px;
        opacity: 1;
        right: 3px;
        height: 1em;
      } 

      .save{
        margin-top: 5%;
        font-size: 14px;
        top: 0px;
        left: 40px !important;
        width: 158px;
        height: 50px;
        margin-right: 40px;
        margin-left: 26%;
        position: relative;
      }
      .cancel{
        margin-top: 5%;
        left: 290px !important;
        position: relative;
      }
      .errorMessage {
        width: 85%;
        left: 6%;
        display: block !important;
        left: 0px;
        top: 10px;
      }
      .SelectSearch{
        width: 447px;
        height: 50px;
        margin-bottom: 10px;
        font-family: trade-gothic-next;
        line-height: 23px;
        font-size: 14px;
        letter-spacing: 0;
        font-family: $font-family;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: unset !important;
        color: #48485436 !important;
        top: 31px;
        left: -3px;
        .divider.default{
            color: rgba(191,191,191,.87) !important;
        }
        .divider{
          color: black !important;
        }
        .ui.dropdown.Base {
          line-height: 45px;
          left: 10px;
          font-size: 14px;
          letter-spacing: 0;
          font-family: $font-family;
          top: 0px;
        }
      }
      .SelectSearch.dropdown.Base.Error {
        border: 1px solid $color-validation-red;
        margin-bottom: 0px
      }
      .SelectSearch .ui.active.dropdown .menu {
        top: 2.5em;
      }
      .SelectSearch .ui.dropdown.Base i.icon {
        color: #484848;
        position: relative;
        right: 22px;
        float: right;
        top: 10px;
      }
      .searchDropDown{
        font-family: trade-gothic-next;
        line-height: 23px;
        font-size: 14px;
        top: -31px;
        left: 0px;
        height: 15px;
        text-align: left;
        letter-spacing: 0.6px;
        color: #484848;
        opacity: 1;
        position: absolute;
        width: 100%;
      }
      .centerSelected{
        color: #484848 !important;
      }
      .selectSearchErrorMessage{
        top: 25px;
        position: relative;
        left: 10px;
        .TopLabelError{
          top: 7px !important;
          left: 25px;
        }
      }
      .error{
        border-color: $color-validation-red !important;
      }
      .scrolling.menu.transition{
        height: 300px;
      }
      .ErrorMsg{
        padding-top: 5px;
        font-family: $font-family;
      }
      .ErrorMsgContainer{
        top: 5px;
        position: relative;
      }
}