@import "../../../Theme/CommonStyles/variables.scss";

.header.rewardHeader.ui {
    color: $lightGreen;
    font-family: $font-family;
    font-size: 12px !important;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 0;
}
.loyaltyPoints {
  color: $white;
  font-family: $font-family;
  font-size: 38px;
  letter-spacing: 0;
  line-height: 40px;
  margin: 0;
}
  
@media only screen and (max-width: 480px) {
  .header.rewardHeader.ui {
    padding-top: 20px;
    line-height: 20px;
    font-size: 11px !important;
  }
  .loyaltyPoints {
    font-size: 18px;
    line-height: 20px;
  }
}
  