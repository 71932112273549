@import '../../Theme/CommonStyles/variables.scss';

.HeroFacility {
  background-color: $facility-light-blue;
  .HeroMainTextContainer {
    padding-top: 65px;
    padding: 19px 7% 43px 7%;
    .BreadcrumbsContainer {
      padding-bottom: 65px;
    }
    h4.ui.header.SupHeader {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.86px;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    h1.ui.header.MainHeader {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 56px;
      margin-top: 12px;
      margin-bottom: 21px;
    }
    .HeroRegularText {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
    }
    .Info {
      opacity: 0.7;
    }
    .Address {
      margin: 0;
    }
    .Phone {
      display: inline;
      border-bottom: 1px solid $dark-blue;
      a {
        color: $dark-blue;
      }
    }
    .isSpanish{
      padding-top: 25px;
      font-weight: 600;
      font-style: italic;
    }
    .FacilityDescription {
      padding-top: 30px;
    }
  }
  .FacilityImageContainer {
    .ui.image {
      width: 100%;
      height: 60vh;
      object-fit: cover;
    }
  }
  @media (max-width: 800px) {
    .FacilityImageContainer {
      .ui.image {
        height: auto;
      }
    }
  }
  @media (max-width: 480px) {
    .FacilityImageContainer {
      .ui.image {
        height: auto;
      }
    }
  }
}

@media (max-width: 480px) {
  .FacilityDetails {
    .ui.grid > .column:not(.row) {
      width: 100% !important;
    }
  }
  .HeroMainTextContainer 
  h1.ui.header.MainHeader {
    font-size: 45px !important;
  }
}
