@import '../../../Theme/CommonStyles/variables.scss';

.DonorInformation {
  padding-bottom: 200px;
  .Disclaimer {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 35px;

    img {
      margin-right: 13px;
    }
  }
}
