@import '../../../Theme/CommonStyles/variables.scss';

.DonationSection {
  padding: 35px 18px 35px 0px;
  font-family: $font-family;
  background-color: $white;

  h4.ui.header.DonationHeader {
    font-family: $font-family;
    font-size: 24px;
    color: $dark-blue;
  }

  .AppointmentRow {
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .DonationDate {
    font-size: 20px;
    color: $gray;
    line-height: 30px;
    font-weight: bold;
  }

  .DonationLocation {
    font-size: 20px;
    color: $gray;
    line-height: 30px;
    text-align: end !important;
  }

  @media (max-width: 480px){
    padding-right:0px;
    .DonationDate{
      font-size: 15px;
      padding-left:13px !important;
    }
    .DonationLocation{
      font-size: 15px;
      padding-right:13px !important;
    }
  }
}
