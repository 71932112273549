@import '../../Theme/CommonStyles/variables.scss';

.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

.ui.sidebar.menu > .item:last-child,
.ui.sidebar.menu > .SidebarSubMenu:last-child {
  margin-bottom: 70px;
}

.ui.visible.left.overlay.sidebar.SideBar {

  .item.MenuHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
  }
  .dropdown {
    .down {
      margin-left: 5px;
    }
    .visible.menu {
      top: 26px
    }
  }

  .SidebarSubMenu {
    a {
      color: $gray;
      font-family: $font-family;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 390px) {
    width: 320px;
  }
  @media only screen and (max-width: 340px) {
    width: 300px;
  }
}
