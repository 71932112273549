@media only screen and (max-width: 415px) {
  .DonorDashboard {
    .ui.grid > .column:not(.row),
    .ui.grid > .row > .column.AppointmentContainer {
      padding-left: 0px;
      padding-right: 0px;
    }
    .ui.grid > .column:not(.row),
    .ui.grid > .row > .column.CenterDataContainer {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
