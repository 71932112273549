@import '../../../Theme/CommonStyles/variables.scss';

.AppointmentMassUpdate {
  h4.header.FormHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  .MassUpdateForm {
    padding-top: 25px;
    
    .SearchLabel {
      font-family: $font-family;
      color: $gray;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      margin-bottom: 0;
    }
    .SearchInput {
      margin-right: 17px;
    }
    .PrimarySelect {
      width: 222px;
      margin-right: 43px;
    }
    .FormField {
      position: relative;
      padding-top: 23px;
      small {
        position: absolute;
        top: 0;
        color: #df49b2;
        font-family: $font-family;
      }
      .TopLabel {
        position: absolute;
        color: $gray;
        font-family: $font-family;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 23px;
        top: 0;
      }
      .TopLabelError {
        position: absolute;
        color: $color-validation-red;
        font-family: $font-family;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 23px;
        top: 0;
      }
    }
    .ui.button.AppMassUpdate {
      margin-top: 20px;
      margin-right: 27px;
    }
    .ui.button.ClearForm {
      margin-top: 20px;
      // background-color: $blue !important;
    }
    .ui.button.ClearForm.disabled {
      opacity: 0.5 !important;
    }
    .ui.dropdown.PrimarySelect {
      .default.text {
        color: rgba(191,191,191,.87);
      }
    }
  }
}