@import '../../../Theme/CommonStyles/variables.scss';

.SignUpFields {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding-left:60px;

  .ui.form .fields {
    gap: 24px;
    margin: 0 -0.5em 2em;
  }

  .ui.form>p {
    margin: 0 0 1.3em;
  }

  .SignUpInput {
    .ui.input>input {
      border-radius: 0;
      padding-left: 15px;
      font-size: 18px;
    }
  }
  
  .RequiredFieldText{
    color: #6D6D6D;
    font-family: $font-family;
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 13px;
  }

  .FormField {
    position: relative;
    padding-top: 27px;
    padding-bottom: 10px;
    
    small {
      position: absolute;
      top: 0;
      color: $dark-pink;
      font-family: $font-family;
      font-size: 12px;
    }

    small.TopLabel {
      position: absolute;
      color: #6D6D6D;
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 4%;
    }

    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 96%;
    }
    .ErrorMsgContainer .InfoIcon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  .ErrorMsgContainer .ErrorMsg {
      padding-top: 2px;
  }
  }

  .SignUpButtonContainer {
    padding-top: 30px;
    text-align: center;
    padding-left:10px;
  }
  @media only screen and (max-width: 1200px){
      padding-left: 0px !important;
  }
  @media only screen and (max-width: 768px){
    .SignUpButtonContainer{
      padding-left: 0px;
    }
  }

  @media only screen and (max-width: 480px) {
    .ui.form {
      max-width: 350px;
    }

    .SignUpButtonContainer {
      width: 250px;
      margin: auto;
    }
  }

  @media (max-width: 992px) {
    float: none;
    justify-content: center;
    align-items: center;
    display: flex;

    .ui.form .fields {
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    .ui.form {
      max-width: 325px;
    }
    .FormField {
      padding-bottom: 25px;
      small.TopLabelError {
        top: 84%;
      }
    }
    .ui.form .fields {
      gap: 1em;
      margin: 0 -0.5em 1em;
    }
  }
}