@import "../../../../Theme/CommonStyles/variables.scss";

.AddUserCenterAccessForm
{
    .CreateInput{
      /* Layout Properties */
      top: 28px;
      left: 0px;
      width: 447px;
      /* UI Properties */
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 0px;
      opacity: 1;
      height: 50px;
      position: relative;
      input{
        border-radius: 0px !important;
      }
    }

    .TopLabel{
        font-family: trade-gothic-next;
        line-height: 23px;
        font-size: 14px;
        top: 0px;
        left: 0px;
        width: 97px;
        height: 3px;
        /* UI Properties */
        text-align: left;
        letter-spacing: 0.6px;
        color: #484848;
        opacity: 1;
        position: relative;
        float: left;
    }
    .save{
      top: 0px !important;
      left: 5% !important;
      position: relative;
      opacity: 1;
    }
    .submitBtnGroup{
      margin-top: 2em !important;
      margin-left: 21em !important;
    }
    .ui.selection.dropdown.Base.CreateFormSelect {
        width: 447px;
        height: 46px;
        margin-bottom: 10px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
        font-family: $font-family;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        .divider.default{
            color: rgba(191,191,191,.87) !important;
        }
        .divider{
          color: black !important;
        }
      }
      .ui.selection.dropdown.Base.CreateFormSelect.Error {
        border: 1px solid $color-validation-red;
        margin-bottom: 0px
      }
      .ui.input{
        height: 50px;
        font-size: 14px;
      }
      .TopLabelError {
        color: $color-validation-red;
        top: 417px;
        left: 743px;
        width: 179px;
        height: 11px;
        text-align: left;
        font: normal normal 600 11px/7px Barlow;
        letter-spacing: 0.5px;
        opacity: 1;
      }
      .ui.error.input{
        border: 1px solid $color-validation-red
      }
      .PrimarySelect{
        .ui.selection.dropdown.Base.Error .default.text {
          color: #EBCAC8 !important;
        }
        padding-bottom: 0px;
        top: 25px;
      }
      .ErrorIcon{
        width: 18px;
        height: 18px;
        opacity: 1;
      }
      .ui.icon.input>i.icon {
        width: 1.18em;
        font-size: .5em;
        top: 6px;
        opacity: 1;
        right: 3px;
        height: 1em;
      } 
      .cancel{
        opacity: 1;
      }
      .errorMessage {
        width: 85%;
        left: 6%;
        display: block !important;
        left: 0px;
        top: 10px;
      }
      .ui.disabled.input{
        opacity: 1;
      }
      .ui.checkbox{
        top: 20px;
        left: 10px;
      }
      .SelectSearch{
        width: 447px;
        height: 50px;
        margin-bottom: 10px;
        font-family: trade-gothic-next;
        line-height: 23px;
        font-size: 14px;
        letter-spacing: 0;
        font-family: $font-family;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: unset !important;
        color: #48485436 !important;
        top: 31px;
        .divider.default{
            color: rgba(191,191,191,.87) !important;
        }
        .divider{
          color: black !important;
        }
        .ui.dropdown.Base {
          line-height: 45px;
          left:-48%;
          font-size: 14px;
          letter-spacing: 0;
          font-family: $font-family;
          top: 0px;
        }
      }
      .SelectSearch.dropdown.Base.Error {
        border: 1px solid $color-validation-red;
        margin-bottom: 0px
      }
      .SelectSearch .ui.active.dropdown .menu {
        top: 2.5em;
      }
      .SelectSearch .ui.dropdown.Base i.icon {
        color: #484848;
        position: relative;
        right: 22px;
        float: right;
        top: 10px;  
        left:200px  
      }
      .searchDropDown{
        font-family: trade-gothic-next;
        line-height: 23px;
        font-size: 14px;
        left: 0px;
        top:-31px;
        height: 15px;
        text-align: left;
        letter-spacing: 0.6px;
        color: #484848;
        opacity: 1;
        position: relative;
        width: 100%;
      }
      .centerSelected{
        color: #484848 !important;
      }
      .selectSearchErrorMessage{
        top: 10px;
        position: relative;
        left: 10px;
        .TopLabelError{
          top: -4px !important;
          left: 5px;
          position: relative;
        }
      }
      .error{
        border-color: $color-validation-red !important;
      }

      .scrolling.menu.transition{
        height: 30vh;
      }
}