@import '../../../Theme/CommonStyles/variables.scss';

.MergeEmailForm.MergeAccount{
  width: 470px;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-top: 2%;

  h4.ui.header.SectionHeader {
    font-weight: bold;
    line-height: 30px;
    padding-bottom: 30px;
    font-size: 24px;
    color: $dark-blue;
  }
  .FormField {
    position: relative;
    padding-top: 18px;
    small {
      position: absolute;
      top: 0;
      color: $dark-pink;
      font-family: $font-family;
      font-size: 12px;
    }
    small.TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 0;
    }
    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 0;
    }
    .noBorderInputField {
      input {
        border: none;
        &:disabled {
          opacity: 1;
          color: #4b5764;
        }
      }
    }
  }
  .RequiredInfo {
    color: $color-validation-red;
  }
  .FailureStateContainer{
    margin-bottom: 15px;
  }
  .SuccessContainer{
    margin-bottom: 15px;
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    color: $dark-pink;
  }
  .MessageButtonContainer {
    padding-left: 10px;
    padding-top: 3px;
  }
  .ButtonContainer {
    display: flex;
    margin-bottom: 23px;
    justify-content: space-around;
  }
  .PasswordRequirements {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }
  .ui.list.RequirementsList {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0.08px;
    line-height: 13px;
    max-width: 380px;
    margin-bottom: 37px;
    .Requirement {
      color: $green;
    }
    .RequirementError {
      color: $dark-pink;
    }
  }
  .ErrorText {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 0;
    margin-top: 3px;
  }
  .ErrorsContainer {
    padding-bottom: 32px;
  }  
}
.MergeAccountModal .ModelButtonWrapper{
    display: flex;
    justify-content: center;
    width: 400px;
    margin: auto;
    justify-content: space-around;
  }
  .MergeAccountModal.ui.active.modal {
    width: max-content;
    text-align: center;
  }
  .MergeAccountModal .usernameUpdateMessage {
    padding: 15px;
  }
  .ui.page.dimmer {
    z-index: 9999;
  }
  .ui.loader {
    &:before {
      border: .2em solid rgba(0,0,0,.1) !important;
    }
    &:after{
      border-color: #767676 transparent transparent !important;
    }
  }

