@import '../../Theme/CommonStyles/variables.scss';

.PageContent {
  .VideoTitle {
    h3 {
      margin-bottom: 20px !important;
    }
  }
  .PageVideoContent {
    margin-top: 30px;
  }
}

.VideoContainer {
  margin-bottom: 5px;
  .VideoName {
    font-size: 16px;
  }
  .VideoDuration {
    font-size: 16px;
    color: $green;
  }
}

@media only screen and (max-width: 768px) {
  .VideoIframe {
    height: 250px !important;
  }
}

@media only screen and (max-width: 540px) {
  .VideoIframe {
    height: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  .VideoIframe {
    height: 210px !important;
  }
}

@media only screen and (min-width: 1092px) {
  .VideoIframe {
    height: 240px !important;
  }
}