@import '../../../Theme/CommonStyles/variables.scss';

.AppointmentSetupTable {
  .RegularText {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.2px;
  }
  .Results {
    margin-top: 15px;
    background-color: $white;
  }
  .ResultsTableContainer {
    padding: 36px 49px 10px 49px;
    border-bottom: 1.4px solid #dddddd;
    .ui.table thead th {
      text-transform: uppercase;
      color: $gray;
      font-family: $font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.09px;
      line-height: 21px;
    }
    .ui.table td {
      color: $gray;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25.2px;
    }
  }
  
  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    background-color: $blue;
    border: 3px solid $blue;
  }
  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    color: $white;
  }
  .ui.radio.checkbox .box,
  .ui.radio.checkbox label {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.2px;
  }

  .ui.table td {
    padding: 15px 0;
  }
  .ui.table td:nth-child(2){
    color: $dark-blue;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
}
