@import '../../Theme/CommonStyles/variables.scss';

.HeroEmailConfirmation {
  padding-top: 62px;
  background-color: $new-gray;
  background-image: url('../../../assets/media/images/cta-background2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:100% 100%;
  padding-bottom: 144px;
  .HeroImageContainer {
    padding-bottom: 74px;
    img {
      width: 100%;
    }
  }
  .HeroContent {
    padding: 72px 20px 0px 20px;
  }
  .EmailConfirmationTextContainer {
    max-width: 578px;
    margin: auto;
  }
  h1.ui.header.EmailConfirmationHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 68px;
    font-weight: bold;
    letter-spacing: -0.32px;
    line-height: 74px;
    padding-bottom: 50px;
    text-align: center;
    margin: auto;
    width: 928px;
  }
  .EmailConfirmationRegularText {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    padding-bottom: 10px;
    span {
      font-weight: bold;
    }
  }
  .ui.list > .item {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    padding-bottom: 15px;
  }
  .LinkTo {
    color: $dark-pink;
    text-decoration: underline;
  }
  @media only screen and (max-width: 768px){
    background-size: cover !important;
}
  @media only screen and (max-width: 910px) {
    h1.ui.header.EmailConfirmationHeader {
      width: auto;
    }
  }
  @media only screen and (max-width: 480px) {
    h1.ui.header.EmailConfirmationHeader {
      font-size: 30px;
      line-height: 40px;
    }
  }
}
