@import '../../Theme/CommonStyles/variables.scss';

.CallToAction {
  background-image: url('./images/CallToActionBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $light-blue-hero;
  .CallToActionContent {
    width: 520px;
    margin: 0 auto;
    text-align: center;

    h2 {
      color: #1f247a;
      font-family: 'trade-gothic-next';
      font-size: 58px;
      font-weight: normal;
      letter-spacing: -0.19px;
      line-height: 58px;
      text-align: center;
      margin-bottom: 40px;
      margin-top: -0.14285714em;
    }
    p {
      color: $dark-gray !important;
      font-family: 'trade-gothic-next' !important;
      font-size: 1.25em !important;
      letter-spacing: 0 !important;
      line-height: 30px !important;
      text-align: center !important;
      margin-bottom: 40px;
    }
    p:empty {
      display: none;
    }
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  .CallToAction{
    .CallToActionContent{
      margin: 90px auto 0 auto;
    }
  }
}

@media only screen and (max-width: 640px) {
  .CallToAction {
    background-image: url('./images/CallToActionBackgroundMobile.png');
    .CallToActionContent {
      width: 90%;
      h2 {
        font-size: 40px !important;
        margin-bottom: 21px !important;
      }
      p{
        margin:0;
      }
      .buttonContainer{
        margin-top:40px;
        display:flex;
        flex-direction: column;
        align-items:center
    }
    }
  }
}
