@import '../../Theme/CommonStyles/variables.scss';

.HeroSignUp {
  padding-top: 62px;
  background-image: url('../../../assets/media/images/cta-background2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 100px;
  background-color: $new-gray;
  .HeroContent {
    padding-top: 72px;
  }
  .SignUpTextContainer {
    max-width: 400px;
    margin: auto;
    p {
      color: $gray;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
  .SignUpTextContainerHeading{
    margin-bottom:55px;
    h3 {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 68px;
      font-weight: 500;
      letter-spacing: -0.32px;
      line-height: 74px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 480px) {
    h1.ui.header.SignUpHeader {
      font-size: 60px;
      line-height: 60px;
      padding-bottom: 30px;
    }
    .HeroSignUp{
      background-color: $dark-blue;
    }
    .SignUpTextContainerHeading{
      h3{
        font-size:46px;
        text-align:left;
        line-height:60px;
      }
    }
  }
  @media only screen and (min-width: 1800px){
    background-size: 100% 100%;
  }
}

