@import "../../Theme/CommonStyles/variables.scss";

.TabMenuContainer {
    .ui.secondary.pointing.menu {
        margin-left: 1em;
        margin-bottom: 32px;
        border-bottom-width: 1px;
        padding-bottom: 4px;
        .item {
            padding-left: 0;
            padding-right: 2px;
            margin-right: 50px;
            color: #ABABAB;
            font-family: $font-family;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-left: 5px;
            padding-right: 8px;
            &:active {
                border-color: transparent;
            }
        }
        .active.item{
            border-bottom: 4px solid $dark-pink;
            color: #484848;
            margin-bottom: -4px;
            padding-left: 5px;
            padding-right: 8px;
        }
    }
    .ui.tab {
      border: none;
      box-shadow: none;
      background-color: inherit;
      padding-right: 0;
      padding-top: 0;
    }

    @media only screen and (max-width: 480px) {
        .ui.secondary.pointing.menu {
            margin-left: -14px;
            padding-left: 28px;
            margin-right: -14px;
        }
        .ui.tab {
            padding-right: 16px;
        }
    }
}