@import '../../Theme/CommonStyles/variables.scss';
.ProductionContent {
  padding-top: 30px !important;
}
.ProductionContent h2 {
    color: $dark-blue !important;
    font-family: 'trade-gothic-next' !important;
    font-size: 24px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .ProductionContent p {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
    padding-bottom: 30px;
  }
  @media only screen and (max-width: 992px) {
    .ProductionContent {
      min-width: 100%;
    }
    .ProductionContent {
      padding: 30px 20px 54px 20px !important;
    }
  }
  
  @media only screen and (max-width: 640px) {
    .ProductionContent {
      min-width: 100%;
    }
    .ProductionContent {
      padding: 30px 20px 54px 20px !important;
    }
   
  }