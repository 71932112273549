@import '../../../Theme/CommonStyles/variables.scss';

.HugeSelect {
  position: relative;

  .Required {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 6px;
    color: $dark-pink;
  }
  .ui.selection.dropdown.Base {
    height: 65px;
    line-height: 33px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 32.5px;
    background-color: $dark-pink;
    color: $white;
    font-family: $font-family;
    font-size: 16.8px;
    font-weight: bold;
    letter-spacing: 0;
    i.icon {
      line-height: 38px;
      float: right;
      color: $white;
      font-size: 16px;
    }
  }
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: $white;
    font-family: $font-family;
    font-size: 20.8px;
    font-weight: bold;
    letter-spacing: 0;
  }
  .ui.selection.dropdown .menu {
    max-height: max-content;
  }
  .ui.selection.dropdown.Base.Error {
    border: 1px solid $dark-pink;
  }
  .ui.floating.dropdown > .menu {
    width: 300px;
    box-shadow: none;
  }
  .ui.selection.active.dropdown .menu {
    border: $gray;
  }
  .ui.selection.dropdown .menu > .item {
    font-size: 18px;
  }
  .ui.active.selection.dropdown {
    border-bottom-left-radius: 32.5px !important;
    border-bottom-right-radius: 32.5px !important;
    border-top-left-radius: 32.5px !important;
    border-top-right-radius: 32.5px !important;
  }
  .ui.selection.dropdown .menu > .item {
    padding: 20px 15px !important;
  }
  .ui.selection.visible.dropdown > .text:not(.default) {
    font-weight: bold;
    color: $white;
  }
}
