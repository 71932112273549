@import '../../../Theme/CommonStyles/variables.scss';

.DebitCardHeader {
  font-family: $font-family;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #dddddd;

  .LabelHeader {
    color: #1f237a;
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .LeftTextHeader {
    color: #1f237a;
    font-size: 35px !important;
    font-weight: normal !important;
    padding-top: 5px;
    letter-spacing: 0;
    line-height: 33.6px;
    margin: 0;
  }

  .RightTextHeader {
    color: #1f237a;
    font-size: 16px !important;
    letter-spacing: 0;
    padding-top: 5px;
    line-height: 16.8px;
    margin: 0;
    white-space: pre-wrap;
    font-weight: lighter;
  }
  @media only screen and (max-width: 480px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
