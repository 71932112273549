.sendNotificationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #4048cc;
    margin-top: 2rem;
    padding: 50px;

  .notificationHeading{
    color: white;
    font-size: 40px;
    padding: 30px 10px 30px 10px;
    line-height: 3rem;
  }  
  .sendButton{
    padding-bottom: 2rem;
    color: #4048cc !important;
    border-color: #4048cc !important;
  }
}