@import '../../Theme/CommonStyles/variables.scss';

.RegistrationWizardSteps {
  height: 46px;
  width: 659px;
  margin: 0 auto;
  .ui.table {
    border: none;
    max-height: 46px;
    background-color: transparent;
  }
  .ui.table td {
    padding: 0;
  }
  .ui.basic.button,
  .ui.basic.buttons .button.StepButton {
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ui.basic.button,
  .ui.basic.button:focus,
  .ui.basic.buttons .button:focus,
  .ui.basic.button:hover,
  .ui.basic.buttons .button:hover {
    background-color: transparent !important;
  }
  .StepNumber {
    height: 46px;
    width: 46px;
    background-color: $dark-blue;
    padding: 14.9px;
    padding-left: 12.9px;
    border-radius: 50px;
    color: $white;
    margin-right: 29px;
    font-family: $font-family;
    font-size: 18px;
    display: inline-block;
    font-weight: bold;
  }
  .StepNumberDisabled {
    height: 40px;
    width: 40px;
    background-color: $white;
    padding: 12px;
    padding-left: 10px;
    border-radius: 50px;
    color: $dark-blue;
    margin-right: 29px;
    font-family: $font-family;
    font-size: 18px;
    display: inline-block;
  }
  .StepText {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
  }
  .StepTextDisable {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    opacity: 0.4;
    white-space: nowrap;
  }
  .MiddleCell {
    border-left: 1px solid $dark-blue;
    border-right: 1px solid $dark-blue;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    height: auto !important;
    .ui.table {
      width: 100%;
      margin: 0 auto;
      tr > td {
        text-align: left;
      }
    }
  }
  .stepItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  
.ui.unstackable.table{
    table-layout: fixed;
    height: auto;
    width: 350px;
  }

  
  // Reducing the Steps button size by 40% to fit in the screen
  .RegistrationWizardSteps{
     margin: 0;
     margin-left: -11px;
     .StepText,.StepTextDisable {
      white-space: normal;
    }
    .StepText{
      padding-top: 0px;
      font-size: 15px;
    }
    .StepTextDisable{
      padding-top: 0px;
      font-size: 15px;
    }
    .StepNumber{
      height: 27.6px;
      width: 27.6px;
      padding: 8px;
      padding-top: 7px;
      border-radius: 50px;
      margin-right: 9px;
      font-size: 10px;
    }
    .StepNumberDisabled{
      height: 24px;
      width: 24px;
      padding: 6px;
      padding-top: 5px;
      border-radius: 50px;
      margin-right: 10px;
      font-size: 10px;
    }
    .ui.button{
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

// style for iphone 5 (Smaller screen size)
@media only screen and (max-width: 380px) {
  .ui.unstackable.table{
    width: 100%;
  }

  .RegistrationWizardSteps{
    margin-left: -7px;
    .StepText {
      font-size: 14px;
      padding-top: 0px;
      letter-spacing: 0;
      line-height: 16px;
    }
    .StepText.es-MX{
      font-size: 12px;
    }
    .StepNumberDisabled{
      margin-left: 4px;
    }

    .StepTextDisable{
      padding-top: 0px;
      font-size: 14px;
      line-height: 16px;
    }
    .StepTextDisable.es-MX{
      font-size: 12px;
    }
    .ui.button{
      padding-right: 0.4rem;
      padding-left: 0.4rem;
    }
    .StepNumber{
      margin-left: 2px;
    }

}
.RegistrationWizardSteps.es-MX{
  margin-left: -6px;
} 
}
