@import '../../Theme/CommonStyles/variables.scss';

.HeroAccountCreate {
  padding-top: 62px;
  background-color: $new-gray;
  background-image: url('../../../assets/media/images/cta-background2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:100% 100%;
  padding-bottom: 100px;
  .HeroTextContent{
    margin-top:50px !important;
  }
  .HeroTextContainer {
    max-width: 601px;
    margin: auto;
    h1.CreateAccountHeader {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 68px;
      padding: 0px 0 55px 0;
      letter-spacing: -0.32px;
      line-height: 74px;
      text-align: center;
    }
  }
  h3.ui.header.EligibilityHeader {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .AccountCreateRegularText {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  @media only screen and (max-width: 768px){
      background-size: cover !important;
  }

  @media only screen and (max-width: 480px){
    .HeroTextContainer{
    h1.CreateAccountHeader{
      font-size:46px;
      text-align:left;
      line-height:60px;
    }
  }
}
}
