@import '../../../Theme/CommonStyles/variables.scss';
.ContactForm {
  .FormNote {
    font-size: 12px;
    margin-bottom: 24px; 
    margin-left:-10px;
  }
  .HelpText{
    margin-top: -43px !important;
    margin-bottom: 22 !important;
    padding-bottom:10px;
    margin-left: 5px !important;
  }
  .RequiredInfo {
    color: $neutral-gray;
  }
  .MessageButtonContainer {
    padding-left: 0;
  }
  .FormField {
    position: relative;
    padding-top: 18px;
    margin-bottom:28px !important;
    small {
      position: absolute;
      top: -5%;
      color:$neutral-gray;
      font-size:14px;
      font-family: $font-family;
    }
    .TopLabelError{
      position:absolute;
      top:90%;
      font-size:12px;
      color: $color-validation-red;
    }
    .FieldLabelError{
      position:absolute;
      top:102%;
      font-size:12px;
      color: $color-validation-red;
    }
    .FieldLabel{
      top:-3%;
    }
  }
    .ui.selection.dropdown.Base {
      display: flex;
      justify-content: space-between;
    }
    .PrimarySelect.ui.selection.dropdown.Base{
      display: flex ;
    }
  
    .CenterLocationLabel {
      display: flex;
      gap: 6px;
  
      .LabelCenterName {
        flex: 50%;
        font-size: 14px;
        line-height: 1.5em;
      }
  
      .LabelCenterAddress {
        flex: 50%;
        font-size: 12px;
        color: #6D6D6D;
        line-height: 1.5em;
      }
  
      @media only screen and (max-width: 480px) {
  
        .LabelCenterName,
        .LabelCenterAddress {
          line-height: 1.2em;
        }
      }
  
      @media only screen and (max-width: 370px) {
  
        .LabelCenterName,
        .LabelCenterAddress {
          line-height: 1em;
        }
      }
    }
  .PrimarySelect .ui.selection.dropdown.Base.Error{
      border: 1px solid $color-validation-red;
  }
  .PrimarySelect .ui.selection.dropdown.Base {
    height:50px;
    color:$gray;
    border-radius:4px;
  }
  .PrimarySelect .ui.dropdown:not(.button) > .default.text ,.PrimarySelect .ui.dropdown > .default.text , .PrimarySelect .ui.selection.dropdown.Base.Error .default.text{
    color: $light-gray ;
  }
  .ui.form textarea.Error {
    border: 1px solid $color-validation-red;
  }
  .PrimaryInput .ui.input > input {
    border-radius: 4px;
  }
  .ui.dropdown>.text {
   color:$gray;
   width: 100%
  }
  .SelectStyle{
   color:$light-gray !important;
  }
  .InfoIcon{
    margin-top:1px;
    margin-right:8px !important;
  }
  .TypeSubject{
    padding-bottom:10px;
  }
  .equal.width.fields{
    margin-bottom:2px;
  }
  i.icon.asterisk:before{
    content:"";
  }
  .ui.input.error>input{
    border: 1px solid $color-validation-red;
    color:$gray !important;
  }

}
.HeaderContainer img.ui.image {
  display: inline-block;
}
.HeaderContainer .ModalHeader.ui.header {
  color: $dark-blue;
}
