@import '../../Theme/CommonStyles/variables.scss';

.ChangePasswordConfirm {
  width: 582px;
  margin: 76px auto 600px auto;
  padding: 54px 56px 58px 56px;
  background-color: $white;
  h1.header.MainHeader {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 32px;
  }
  .Message {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }
  .ActionButtonContainer {
    padding-top: 59.27px;
    text-align: center;
  }
  .SignInButton {
    color: $white;
    font-family: $font-family;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
  }
  @media only screen and (max-width: 480px) {
    width: 341px;
    margin: 50px auto;
    padding: 32px 30px 32px 30px;
  }
}
