@import '../../Theme/CommonStyles/variables.scss';

.LocationStateSearch {
  padding-left: 35px;
  min-height: 760px;
  padding-bottom: 140px;
  background: -webkit-repeating-linear-gradient(transparent, transparent 10.9%, $white-gray 89.1%, $white-gray 100%);
  background: -o-repeating-linear-gradient(transparent, transparent 10.9%, $white-gray 89.1%, $white-gray 100%);
  background: -moz-repeating-linear-gradient(transparent, transparent 10.9%, $white-gray 89.1%, $white-gray 100%);
  background: repeating-linear-gradient(transparent, transparent 10.9%, $white-gray 89.1%, $white-gray 100%);
  .ResultsContainer {
    background-color: $white;
    padding: 12px 20px;
    margin: -90px auto;
  }
  .Map {
    padding: 12px 10px;
    i.map.marker {
      margin: -16px 0 0 -10px;
      vertical-align: top;
    }
  }
  @media (max-width: 800px) {
    padding-left: 0px;
  }
  @media (max-width: 480px) {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 1330px) and (min-width: 1280px) {
  .Map {
    padding: 12px 10px;
    i.map.marker {
      margin: -15px 0 0 -10px;
    }
  }
}
