@import '../../../Theme/CommonStyles/variables.scss';

.PrimarySelect {
  position: relative;
  padding-bottom: 10px;
  .Required {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 6px;
    color: $dark-pink;
  }
  .ui.selection.dropdown.Base {
    height: 60px;
    line-height: 25px;
    padding-right: 10px;
    border-radius: 0;
    i.icon {
      line-height: 25px;
      float: right;
      color: $gray;
    }
  }
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: $gray;
  }
  .ui.dropdown .menu > .divider {
    margin: 0;
    border: none;
  }
  .ui.dropdown .menu .header{
   font-size: 14px;
  }
}
@media only screen and (max-width: 480px) {
  .PrimarySelect {
    .ui.selection.dropdown.Base {
      display:block;
      justify-content: space-between;
    }
  }
}
