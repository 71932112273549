@import '../../Theme/CommonStyles/variables.scss';

.ImageContainer {
  position: relative;
}
.IconGridContainerTop {
  position: absolute;
  top: -36px;
}
.IconGridContainerTopRight{
  position: absolute;
  top: -36px;
  right: -40px;
}
.IconGridContainerBottomTop {
  position: absolute;
  bottom: -30px;
  left: -30px;
  width: 60px;
}
.HealthTextBlock{
  padding-left:50px;
}
@media only screen and (max-width: 800px) {
  .ImageContainer .bannerImg {
    width: 100%;
    margin: 0 auto 60px auto;
  }
  .IconGridContainerTopRight{
    top: -30px;
    right: -22px;
  }
  
  .IconGridContainerBottomRight{
    right: -20px;
  }
  .IconGridContainerBottomTop{
    left: -14px;
  }
}
@media only screen and (max-width: 640px) {
  .MarketingBlocks {
    .MarketingTextColumn {
      padding: 0 !important;
    }
    .MarketingTextBlock {
      width: 370px;
      margin: 18% auto;
    }
    .ImageContainer > img {
      max-width: 100%;
      width: 100%;
      margin: 0px;
    }
    .HealthTextColumn {
      padding-left: 0px !important;
    }
  }
  .HealthTextBlock {
    width: 90%;
    margin: 18% auto;
  }
  .IconGridContainerBottom {
    left: 0px;
  }
  .IconGridContainerTopRight{
    right: 0;
  }
  .IconGridContainerBottomRight{
    right: 0;
  }
  .IconGridContainerBottomTop{
    left: 0;
  }
}
