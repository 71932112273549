@import '../../../../Theme/CommonStyles/variables.scss';

.CreateAccessManagementHeader{
    
        flex-Direction : row;
        justify-Content: space-between;
        top: 43px;
        position: relative;

    .FormHeaderText{
        /* Layout Properties */
        top: 160px;
        left: 571px;
        width: 100%;
        height: 33px;
        /* UI Properties */
        text-align: center;
        letter-spacing: 0px;
        color: #1F237A;
        opacity: 1;
        font-family: "trade-gothic-next";
        font-size: 24px !important;
        font-weight: bold;
        letter-spacing: 0 !important;
        line-height: 30px;
    }
}