@import '../../Theme/CommonStyles/variables.scss';

.ui.modal.alert-modal{
    p{
        text-align: center;
    }
    .ui.button{
        display: block;
        margin: auto;
        border-radius: 2rem;
        max-width: 20rem;
        width: 100%;
        background-color: darkkhaki;
    }
    .ui.button.primary{
            background-color: $dark-pink;
            border-width: 2px;
            border-style: solid;
            border-color: $dark-pink;
    }
    img.ui.image{
        display: inline-block;
    }

    .actions{
        justify-content: space-around;
    }

    .actionButtons{
        width: 150px !important;
    }
    .outlineButton{
        background-color: transparent !important;
        color: $dark-pink;
        border-width: 2px;
        border-style: solid;
        border-color: $dark-pink;
        &:hover{
            color: $dark-pink !important;
        }
    }
}