@import "../../Theme/CommonStyles/variables.scss";

.PageTopBanner {
  background-color: $dark-blue;
  padding: 1rem;

  @media only screen and (min-width: 992px) {
    height: 218px;
    padding: 20px 6% 40px;
  }

  .ui.header.SupHeader {
    color: $yellow;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .ui.header.MainHeader {
    color: $white;
    font-family: $font-family;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 60px;
    margin: 0;
    @media only screen and (max-width: 992px) {
      font-size: 30px;
    }
  }
}
