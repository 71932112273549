@import "../../Theme/CommonStyles/variables.scss";

.ui.modal.LoyaltyModalContainer{
    border-radius: 30px;
    >:first-child:not(.icon){
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }
    >:last-child {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    .header{
        border-bottom: 5px solid $loyaltyLightGreen;
        padding: 40px 10px 40px 60px;
        .closeIcon{
            cursor: pointer;
        }
        .ModalTitle{
            font-size: 36px;
        }
    }
    .content{
        padding: 30px 60px;
        .ModalContent{
            font-size: 18px;
            color: $gray;
            margin-bottom: 40px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .ui.modal.LoyaltyModalContainer{
        .header{
            padding: 25px 20px !important;
            .ModalTitle{
                font-size: 22px;
            }
        }
        .content{
            padding: 25px !important;
            .ModalContent{
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }
}