@import '../../Theme/CommonStyles/variables.scss';

.PageContent {
  padding-bottom: 200px;
  .step-guide-table>tbody>tr>td>img{
            max-width: 100%;
            margin: 10px 0 10px 0;
          }
  h2 {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 38px;
    font-weight: $font-bold;
    letter-spacing: 0;
    line-height: 40px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  h3, h3 span {
    color: $dark-blue !important;
    font-family: $font-family;
    font-size: 24px;
    font-weight: $font-bold;
    letter-spacing: 0;
    line-height: 40px;
    padding-top: 10px;
  }
  h4 {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 20px;
    font-weight: $font-bold;
    letter-spacing: 0;
    line-height: 24px;
    img {
      display: block;
      margin-bottom: 25px;
    }
  }
  p,
  .ui.list,
  .ui.list > .item {
    color: $gray;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .ui.list > li {
    margin-bottom: 10px;
  }
  .ui.list > li:before {
    color: $gray;
    font-family: $font-family;
    font-size: 14px;
  }
  .ui.list.two-columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  .ui.button.PrimaryButton {
    width: auto;
    padding: 0 30px !important;
  }
  p b {
    color: $dark-blue;
  }
  p.label {
    font-weight: $font-bold;
    margin-bottom: 0;
  }
  a {
    color: $dark-pink;
    text-decoration: underline;
    inline-size: 150px;
    overflow-wrap: break-word;
  }
  .Accordion {
    font-family: $font-family;
    margin-top: 20px;
    .ui.container {
      margin-left: 0 !important;
    }
    h2 {
      display: inline;
      font-size: 24px;
      padding: 0;
      padding-left: 10px;
    }
    p b {
      color: $gray;
    }
  }
  .AccordionContent {
    padding-left: 30px;
    padding-bottom: 30px;
    .ql-indent-1 {
      padding-left: 40px;
    }
  }
  .StepsPlasmaGrid {
    margin-top: 20px;
  }
  .StepsPlasmaColumn {
    a {
      color: $dark-blue;
    }
  }
  .TestimonialButton {
    margin-top: 30px;
  }
  .FindDonationButton {
    width: 200px;
    margin-top: 30px;
  }
  .ImmersiveDonorSection {
    .ClickableImageContainer{
      display:block;
      width:90%;
      border-radius:20px;
      margin-bottom:25px;
      .ClickableImage{
        padding:0px;
        margin:0px;
        margin-top:25px;
        border-radius:20px;
      }
    } 
    .ui.container {
      margin-left: 0 !important; 
      padding-right:15px !important;
      width:100%;
    }

    @media only screen and (max-width: 992px){
      .ProductionContent {
          padding: 30px 0px 54px 0px !important;
      }
      .ui.container {
        padding-right: 0 !important;
        margin-right:0em !important;
      }   
    }

    @media screen and (min-width: 1800px){
      .ClickableImageContainer{
            width:85%;
      }
    }

    @media screen and (min-width: 2500px){
      .ClickableImageContainer{
            width:80%;
      }
    }
  }
  .becomeDonorBottomSection {
    padding-top: 50px;
  }
  .TabMenuContainer a {
    inline-size: auto;
  }
}

.contactUsContainer {
  padding-bottom: 55px;
}
.contactUsPage {
  max-width: 1000px;
  margin-right: 15px;
}
.contactUsBottomText {
  max-width: 1000px;
  margin-top: 60px;
  h2, h3 {
    font-size: 24px;
  }
}
.contactUsBottomText:last-child {
  margin-top: 48px;
}
.contactUsFAQS {
  font-size: 24px;
  color: $dark-blue;
  font-weight: 700;
  font-family: "trade-gothic-next";
  margin-top: 58px
}

@media only screen and (max-width: 768px) {
 .PageContent{
  .ql-table-blob, .step-guide-table{
    tbody{
      tr{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        td{
         width: 100% !important;
          img{
            margin: 20px auto;
          }
        }
      }
    }
  }
  .contactUsPage {
    margin-left: 14px;
  }
  .contactUsBottomText {
    margin-left: 15px;
  }
 }
}


@media only screen and (max-width: 480px) {
  .PageContent {
    .ImmersiveDonorSection {
      .ClickableImageContainer {
        border-radius: 15px;
        margin-bottom:15px;
        width:95%;
        .ClickableImage{
          border-radius: 15px;
        }
      }
    }
    .contactUsPage {
      margin-left: 14px;
      h2 {
        font-size: 24px;
      }
      h3 {
        font-size: 20px;
      }
    }
    .contactUsBottomText {
      width: auto;
      padding: 40px 0 0 0; 
      margin: 0 15px;
      h2, h3 {
        font-size: 20px;
      }
    }
    .becomeDonorBottomButton {
      text-align: center;
    }
    .contactUsFAQS {
      margin-right:10px ;
      font-size: 20px;
    }
    .contactUsFAQSAccordion{
     .Accordion h2 {
      font-size: 20px;
    }
  }
  }
}