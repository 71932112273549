@import '../../../Theme/CommonStyles/variables.scss';

.UpdateEmailForm.AccountSettings {
  width: 470px;
  padding-bottom: 5%;

  h4.ui.header.SectionHeader {
    font-weight: bold;
    line-height: 30px;
    padding-bottom: 5px;
    font-size: 24px;
    color: $dark-blue;
  }
  .FormField {
    position: relative;
    padding-top: 18px;
    margin-bottom: 32px !important;
    small {
      position: absolute;
      top: 0;
      color: $dark-pink;
      font-family: $font-family;
      font-size: 12px;
    }
    small.TopLabel {
      position: absolute;
      color: $gray;
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top: 0%;
    }
    small.TopLabelError {
      position: absolute;
      color: $color-validation-red;
      font-family: $font-family;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 13px;
      top:96% !important;
    }
    .NonEditableInputField{
      background-color: $white !important;
      border: 1px solid rgba(34,36,38,.15);
      border-radius: 4px;
      input{
        border:none;
      }
    }
    .ui.disabled.input {
      opacity: 1 !important;
  }
  .ErrorMsgContainer .InfoIcon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }
.ErrorMsgContainer .ErrorMsg {
    padding-top: 2px;
}
  }
  .NonEditableTextContainer {
    display: flex;
    margin-top:-25px;
    margin-bottom:24px;
    .GrayInfoIcon {
      margin-right: 8px;
    }
    .NonEditableText{
      color:$gray;
      font-size:13px;
    }
  }
  .RequiredInfo {
    color: $gray !important;
  }
  .FailureStateContainer{
    margin-bottom: 15px;
  }
  .MessageButtonContainer {
    padding-top: 3px;
    padding-bottom: 24px;
  }
  .ButtonContainer {
    display: flex;
    justify-content: space-around;
    column-gap: 30px;
    button{
      margin: 5px;
    }
  }
  .cancelButton {
    text-decoration: underline;
    border: none !important;
  }
  .PasswordRequirements {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }
  .ui.list.RequirementsList {
    color: $gray;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0.08px;
    line-height: 13px;
    max-width: 380px;
    margin-bottom: 37px;
    .Requirement {
      color: $green;
    }
    .RequirementError {
      color: $dark-pink;
    }
  }
  .PrimaryInput .ui.input > input {
    border-radius: 4px;
  }
  .ErrorText {
    color: $dark-pink;
    font-family: $font-family;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 0;
    margin-top: 3px;
  }
  .ErrorsContainer {
    padding-bottom: 32px;
  }  
}

  .UpdateModal.ui.active.modal {
    width: max-content;
    text-align: center;
  }
  .UpdateModal .usernameUpdateMessage {
    padding: 15px;
  }
  .ui.page.dimmer {
    z-index: 9999;
  }
  .ui.loader {
    &:before {
      border: .2em solid rgba(0,0,0,.1) !important;
    }
    &:after{
      border-color: #767676 transparent transparent !important;
    }
  }


  @media only screen and (max-width: 764px) {
    .UpdateModal.ui.active.modal {
      width: 90%;
    }
  }

    //PD-4921-spacing for the modal confirm button for smaller screen
    @media only screen and (max-width: 380px) {
      .ui.button.PrimaryButton.confirmbtn {
       margin-bottom: 1rem;
      }
    }
    @media only screen and (max-width: 480px)  {
      .UpdateEmailForm.AccountSettings{
        small.TopLabelError{
          top:-10% !important;
        }
      }
      .ButtonContainer {
        display: flex !important;
        flex-direction: unset !important;
        column-gap: 0px !important;
        grid-column-gap:0px !important;
      }
      .UpdateEmailForm.AccountSettings{
        h4.ui.header.SectionHeader {
        font-size: 22px;
      }
    }
    }