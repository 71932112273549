@import '../../../Theme/CommonStyles/variables.scss';

.ui.form.UpdatePreferencesForm {
  font-family: $font-family;
  max-width: 470px;
  padding-top: 50px;
  padding-bottom: 600px;

  .Half {
    width: 50% !important;
  }

  .GridContainer {
    padding-bottom: 20px;
  }

  .FloatRight {
    float: right;
  }

  .PreferenceText {
    color: #009e8f;
    font-size: 18px;
  }

  .RowSpacing {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 24px;
  }

  .PreferencesRow {
    line-height: 24px;
    font-size: 14px;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #009e8f !important;
  }

  i.circular.icon {
    font-size: 10px;
  }
}
.ui.top.right.popup.transition.visible.NotificationPopup,
.ui.top.left.popup.transition.visible.NotificationPopup,
.ui.top.center.popup.transition.visible.NotificationPopup,
.ui.bottom.right.popup.transition.visible.NotificationPopup,
.ui.bottom.left.popup.transition.visible.NotificationPopup
 {
  background: #d8e9ec;
  min-width: 385px;
  border-color: #d8e9ec;
  box-shadow: none;
  left: -10px !important;
  a {
    color: $gray;
    text-decoration: underline;
  }
  .ui.two.column.grid {
    margin: 0px;
    .wide.column {
      padding: 0;
    }
  }
}
.ui.top.popup::before {
  background: #d8e9ec;
  box-shadow: 1px 1px 0 0  #d8e9ec;
}
.ui.bottom.left.popup:before,
.ui.bottom.right.popup:before {
  background: #d8e9ec;
  box-shadow: -1px -1px 0 0  #d8e9ec;
}
.NotificationPopup {
  .ui.checkbox .box,
  .ui.checkbox label {
    margin-top: 5px;
  }
  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    border-radius: 5;
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border: none;
    border: 1px solid $green;
    background-color: #f0f0f0;
  }
  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    background-color: $green;
    border: none;
  }
  .ui.checkbox .box:after,
  .ui.checkbox label:after {
    height: 24px;
    width: 24px;
    top: 2px;
    color: #f0f0f0 !important;
  }
  .ui.checkbox .box,
  .ui.checkbox label {
    color: $white;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 40px;
  }
}

@media only screen and (max-width: 412px) {
  .ui.top.right.popup.transition.visible.NotificationPopup,
  .ui.top.left.popup.transition.visible.NotificationPopup,
  .ui.bottom.right.popup.transition.visible.NotificationPopup,
  .ui.top.center.popup.transition.visible.NotificationPopup{
    min-width: 250px;
    max-width: 85vw;
    left: 0px !important;
  }
}