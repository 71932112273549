@import '../../../Theme/CommonStyles/variables.scss';

.CellPlayroomData {
  .PlayroomMember {
    color: $gray;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.2px;
    // margin-bottom: 16px;
    text-align: center;
  }

  .AllowedPlayroom {
    color: $gray;
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.09px;
    line-height: 21px;
    text-transform: uppercase;
    text-align: center;
  }
  .HidePlayroom{
    display: none;
  }
}
