@import "../../Theme/CommonStyles/variables.scss";

.Hero {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  background: $dark-blue;
  padding: 65px 70px 50px 70px;
  i.huge.icon {
    font-size: 2em;
    color: #E6C153;
    line-height: 2;
    margin-bottom: 10px;
  }
  .MessageButtonContainer{
    padding-left: 15px;
  }
  .HeroSearchForm {
    padding: 10px 20px;
  }
  .HeroImgContainer {
    img {
      aspect-ratio: auto;
      object-fit: contain;
      object-position: center;

      @media only screen and (max-width: 800px) {
        height: auto;
      }
      @media only screen and (max-width: 414px) {
        height: auto;
        width: auto;
      }
    }
  }
  .HeroContentContainer {
    min-height: 400px;
  }
  .HeroTextContainer {
    padding: 50px 0 0 20px;
    & > h2 {
      color: $light-blue-hero !important;
      font-size: 3.125em !important;
      text-transform: capitalize !important;
      font-weight: 500 !important;
      font-family: "trade-gothic-next" !important;
    }

    & > p {
      color: $white;
      font-weight: 400;
      font-family: $font-family;
      font-size: 1.25em;
      letter-spacing: 0;
      line-height: 30px;
      width: 80%;
    }
  }
  .signUpContainer {
    padding : 2rem 2rem 3rem 14rem;
    text-align : left;
    display: flex;
    column-gap: 16px;
    padding-left: 20px;
  }
  .signUpBtn {
    width: 200px !important;
    height: 48px !important;
  }
 .HeroGridColumn {
  row-gap: 20px;
 }
}

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  .Hero {
    padding: 15px 70px 60px 70px;
    height: auto;
    min-height:475px
    }
  .Hero{
    .signUpContainer {
      padding-left: 90px;
     }
   }
  .Hero .HeroTextContainer {
    min-width: 430px;
    padding: 70px 40px 0px 90px;
    & > h2 {
      font-size: 3.125em !important;
    }
    & > p:last-child {
      margin-top:1.5em;

    }
  }

}
@media only screen and (max-width: 1199px) and (min-width: 801px) {
  .Hero{
    .signUpContainer {
      padding-left: 20px;
    }
  }
} 
@media only screen and (max-width: 800px) {
  .Hero {
    .HeroTextContainer {
      padding-top: 45px;
      & > p:last-of-type {
        text-align: center;
      }
    }
    .signUpContainer {
      padding-left: 1.5rem;
      display: flex;
      flex-direction: column-reverse;
      row-gap: 30px;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 640px) {
  .Hero {
    padding: 0px 0 0 0;
    background: $dark-blue;
    .ContentContainer {
      padding-left: 1em;
    }
    .HeroTextContainer {
      padding: 20px 28px 0 16px;
      & > p {
        width: 100%;
      }
      & > h2 {
        color: $light-blue-hero !important;
        font-size: 40px !important;
        text-transform: capitalize !important;
        font-weight: 500 !important;
        font-family: $font-family;
      }
      & > p:last-of-type {
        text-align: left;
      }
    }
    .HeroSearchForm{
      margin-bottom: 15px;
    }
    .ui.form .fields{
      flex-wrap: wrap;
    }
  }
}
