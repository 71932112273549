@import '../../../Theme/CommonStyles/variables.scss';

.PrimaryButton,
.ui.PrimaryButton.button,
.ui.active.PrimaryButton,
.ui.active.button.PrimaryButton
 {
  height: 50px;
  width: 157px;
  border-radius: 25px !important;
  color: $white;
  font-family: 'trade-gothic-next' !important;
  font-size: 14px;
  font-weight: bold !important;
  line-height: 50px !important;
  padding: 0 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: none;
}

.PrimaryButton:hover,
.ui.PrimaryButton:hover
.ui.active.PrimaryButton:hover,
.ui.active.button.PrimaryButton:hover,
.PrimaryButton:active,
.ui.PrimaryButton:active
.ui.active.PrimaryButton:active,
.ui.active.button.PrimaryButton:active,
.PrimaryButton:focus,
.ui.PrimaryButton:focus
.ui.active.PrimaryButton:focus,
.ui.active.button.PrimaryButton:focus
 {
  border-color: $blue !important;
  background-color: $blue !important;
  color: $white !important;
}

.PrimaryWithPadding {
  background-color: $dark-pink !important;
  padding-top: 20px !important;
  padding-bottom: 35px !important;
}

.LightBlueWithPadding {
  background-color: $color-orange !important;
  padding-top: 20px !important;
  padding-bottom: 35px !important;
}

.PrimaryButton:hover {
  background-color: $blue !important;
}

.PrimaryButton.LightBlueWithPadding {
  &:hover, &:active, &:focus {
    background-color: $color-orange-hover !important;
  }
}


.Primary {
  background-color: $dark-pink !important;
}

.Secondary {
  background-color: #009e8f !important;
}
.Secondary1{
  background-color: $loyaltyDarkGreen !important;
}

.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  background-color: $disable-gray !important;
  opacity: 1 !important;
}
.Inactive {
  background-color: $disable-gray !important;
}

.Inverted,
.Inverted:hover {
  background-color: $white !important;
  color: $dark-pink !important;
  border: 1px solid $dark-pink !important;
}
.ui.button.Inverted:disabled, 
.ui.disabled.button.PrimaryButton.Inverted{
  background-color: $white !important;
  color: $disable-gray !important;
  border: 1px solid $disable-gray !important;
}
.Transparent{
  background-color: transparent !important;
}
