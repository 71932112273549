@import "../../../../../Theme/CommonStyles/variables.scss";

.TopEarningContainer {
  a {
    color: #ac0079;
    text-decoration: underline;
    font-family: $font-family;
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 30px;
    padding-left: 30px;
  }

  h2 {
    color: $loyaltyDarkGreen;
    border: 0px;
    font-family: $font-family;
  }

  .ui.feed {
    .event {
      border-top: 1px solid #dddddd;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-direction: inherit;
      justify-content: space-between;
      
      &:first-child {
        border-top: none;
      }

      .label {
        padding-right: 20px;
      }

      .content {
        display: contents;
        font-family: $font-family;
        font-size: 20px;
        color: $loyaltyDarkGreen;

        .summary {
          display: grid;
          font-family: $font-family;
          font-size: 20px;
          color: $loyaltyDarkGreen;
          width: 65%;
          line-height: normal;

          .date {
            color: $loyaltyBlack;
            font-size: 16px;
            line-height: 20px;
            padding-top: 10px;
            width: 100%;
          }
        }
        .date {
          padding-top: 15px;
          font-family: $font-family;
          font-size: 14px !important;
          width: 200px;
          margin: 0px;
        }
        .meta {
          color: $loyaltyDarkGreen;
          font-size: 20px;
          font-weight: bold;
          width: 35%;
          text-align: end;
        }
      }
      .label {
        img {
          width: 80px;
          height: 80px;
          text-align: center;
        }
      }
    }
  }
  .ui.card {
    border-radius: 20px;
    width: 100%;
    margin-bottom: 20px;

    .content {
      border-top: none;
      padding: 30px 30px 15px 30px;;
      h4:last-child {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
      }
      img {
        padding: 20px;
      }
      a {
        text-align: center;
      }
    }
  }
  .RewardsCard {
    display: flex;
    gap: 40px;
  }
  .subText {
    font-size: 18px;
    color: $gray;
    font-weight: normal;
    margin: 0px;
  }
}
@media only screen and (max-width: 480px) {
  .TopEarningContainer {
    display: block;
    grid-gap: 40px;
    gap: 40px;

    h2 {
      display: block;
      gap: 30px;
      text-align: center;
      padding: 10px;
    }
    a{
      padding-left: 0px;
      padding-top: 0px;
      padding-left: 0px;
    }
    .desc {
      padding-left: 60px;
    }

    .ui.feed {
      .event {
        .label {
          padding-right: 20px;
          text-align: center;
        }

        .content {
          display: grid;
          text-align: center;

          .summary {
            text-align: center;
            display: contents;
            padding-left: 10px;
            .date {
              padding-top: 10px;
            }
          }
          .meta {
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    .ui.card {
      .content {
        padding: 20px 20px 0px 20px;
        img {
          padding: 20px;
        }

        a {
          padding-left: 0px;
          text-align: center;
        }
      }
    }
  }
}
