@import "../../Theme/CommonStyles/variables.scss";
$background-image: url("../../../assets/media/images/locations-hero-bg.png");
%HeroLocations-shared {
  background-image: $background-image;
  .HeroTextContainer {
    padding: 50px 0 0 20px;
    max-width: 440px;
    text-align: left;
    h1 {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 2.5em;
      font-weight: normal;
      letter-spacing: -0.24px;
      line-height: 58px;
      margin-top: 10px;
    }
    p {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
    }
    &.NoSearchFound h1 {
      font-size: 45px;
      b {
        font-size: 50px;
        display: block;
      }
    }
  }
  .HeroTextFormContainer {
    color: $dark-blue;

   .ui.button,
    .ui.disabled.button{
     background-color: $white !important;
     color: $dark-pink !important;
     border: 1px solid $dark-pink;
     opacity: 1 !important;
    }
    p {
      color: $dark-blue;
      font-family: $font-family;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      margin-left: 0;
      margin-bottom: 5px;
    }
    p.bulleted,
    p.HeroRegularText {
      margin-top: 25px;
      margin-bottom: 25px;
      margin-left: 0 !important;
    }
    p.bulleted::before,
    p.HeroRegularText::before {
      content: "" !important;
    }
  }
  .NoSearchFound {
    max-width: 460px;
  }
  .NoFacility {
    padding-bottom: 200px;
  }
  .HeroTextFormContainer {
    padding-top: 110px;
    padding-bottom: 80px;
  }
  .markerIcon {
    color: $dark-pink;
  }
  h1.ui.header.BoldText {
    font-weight: 700;
  }
  p.HeroRegularText {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
  }
  @media (max-width: 800px) {
    .HeroTextContainer {
      &.NoSearchFound h1{
        font-size: 30px;
        b {
          font-size: 35px;
          display: block;
          line-height: 40px;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .HeroTextContainer {
      padding: 50px 0 0 0px;
      max-width: 330px;
      h1{
        padding-left: 10px;
      }
    }
    .HeroTextFormContainer {
      padding-top: 60px;
      padding-bottom: 50px;
    }
    h1.ui.header.HeroHeader {
      font-size: 34px;
      line-height: 40px;
    }
    .HeroRegularText {
      font-size: 14px;
      line-height: 18px;
    }
    .SearchData {
      padding-right: 0px;
      padding-left: 0px;
    }
    h2.ui.header.SelectedState {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.HeroLocations {
  padding-top: 105px;
  @extend %HeroLocations-shared;
  @media (max-width: 992px) and (min-width: 481px) {
    .HeroTextContainer{
      h1 {
        padding-left: 12px;
      }
    }
    .HeroTextFormContainer{
      padding-top: 50px;
      padding-left: 30px;
     .ui.form.HeroSearchForm {
       padding: 0 0 20px 0px;
      }
    }  
  }

  @media (max-width: 480px) {
    padding-top: 30px;
    .HeroTextContainer {
      padding: 50px 0 0 0px;
      max-width: 330px;
    }
    .HeroTextFormContainer {
      padding-top: 30px;
      padding-bottom: 50px;
    }
    h1.ui.header.HeroHeader {
      font-size: 34px;
      line-height: 40px;
    }
    .HeroRegularText {
      font-size: 14px;
      line-height: 18px;
    }
    .SearchData {
      padding-right: 0px;
      padding-left: 0px;
    }
    h2.ui.header.SelectedState {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
.HeroLocationsState {
  background-image: $background-image;
  padding-top: 35px;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 100px;
  @extend %HeroLocations-shared;
  .HeroTextFormContainer {
    padding-top: 138px;
    padding-bottom: 80px;
    max-width: 400px;
  }
  .SearchData {
    padding-right: 14px;
    padding-left: 40px;
    padding-bottom: 32px;
  }
  h2.ui.header.SelectedState {
    color: $dark-blue;
    font-family: $font-family;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }
  .NoPadding {
    padding: 0;
  }
  @media (max-width: 800px) {
  }
  @media (max-width: 480px) {
    .HeroTextContainer {
      padding: 50px 0 0 0px;
      max-width: 330px;
    }
    .HeroTextFormContainer {
      padding-top: 60px;
      padding-bottom: 50px;
    }
    h1.ui.header.HeroHeader {
      font-size: 34px;
      line-height: 40px;
    }
    .HeroRegularText {
      font-size: 14px;
      line-height: 18px;
    }
    .SearchData {
      padding-right: 0px;
      padding-left: 0px;
    }
    h2.ui.header.SelectedState {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
